import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { list } from 'postcss';
import { VerticalAlignBottom } from '@mui/icons-material';

export default function Actions({ list, data, onClick}) {
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if(onClick){
      onClick();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div 
    >
    <div className="right-4 cursor-pointer ">
      {<MoreVertIcon
        className=' cursor-pointer block'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}/>
      }
      <Menu
        className='absolute right-1/2 top-1/2 w-[600px]'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {list.map(item=>{
          return (item.renderCondition ? item.renderCondition(data) : item.condition !== undefined ? item.condition : true) && <div className= {`px-3 py-2 h-full  text-start items-start flex hover:bg-main hover:text-white cursor-pointer ${item.className}`} onClick={()=>{item.onClick(data); handleClose()}} key= {item.name}>{item.name}</div>
        })}
      </Menu>
    </div>
          
    </div>
  );
}
