import React, { useEffect, useState, useRef } from "react";
import Container from "../../common/Container";
import Collapsable from "../../common/Collapsable";
import { Form, FormikProvider, useFormik } from "formik";
import TyreDetails from "./view/TyreDetails/TyreDetails";
import UsageDetails from "./view/ProposedVehciles/ProposedVehicles";
import CallbackRequirements from "./view/CallbackRequirements/CallbackRequirement";
import DocumentUpload from "./view/DocumentUpload";
import TestRequirement from "./view/TestRequirement";
import Button from "../../common/Button";
import { createDocument, deleteDocumentById, fetchAll, getAllData, updateDocumentByID } from "../../apis/customApiHandler";
import PdfViewer from "../../common/PdfViewer"
import styles from './Download.module.css';
import jk_logo from '../../img/jk-logo.png';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from 'react-to-print';
import { convertToIndianTimezone, fetchFile } from "../../utils/helpers";
import axios from "axios";
import { useMemo } from "react";

function DownloadTestPlan({ data, planNo }) {

    console.log("download", data);
    const [pdfBytes, setPdfBytes] = useState(null)
    const componentPDF = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
    });

    const exportFile = async (fileName) => {
        const element = componentPDF.current;
        const canvas = await html2canvas(element, {
            scale: 1,
        });
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF("portrait", "px", [1080, 1080]);

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(data, "PNG", 0, 0, 1080, 1080);
        pdf.save(fileName + ".pdf");
    };

   

    const getPdf = async()=>{
        getAllData("/test_plan/", {planNo, download: 1}, ()=>{}, "blob").
        then(arrayBuffer => {
        //    OK
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            console.log("ARRAY", new Uint8Array(arrayBuffer))
            console.log({blob})
            const url = window.URL.createObjectURL(blob);
            console.log({url})
            setPdfBytes(url)

            // const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'application/pdf' });
            // const url = URL.createObjectURL(blob);
            // const newWindow = window.open(url, '_blank');
            // console.log(url)
            })
    }
    useMemo(()=>{
        if(planNo) getPdf();
    }, [planNo])


    return (
        <Container cname= "container_test_request">
            <div className= "flex flex-row gap-2 w-24 float-right mb-5 h-10">
            <button
                className={styles.download_button}
                onClick={()=>{
                    const url = pdfBytes
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'testPlan.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }}
            >
                <img src="./icons/download.svg"/>
            </button>
            <button
                className={styles.download_button}
                onClick={()=>{
                    const printWindow = window.open(pdfBytes);
                    if (!printWindow) {
                        alert('Please enable pop-ups for printing.');
                      } else {
                        printWindow.print();
                      }
                }}
            >
                <img src="./icons/print.svg"/>
            </button>
            </div>

            <div className="w-full flex flex-col md:flex-row justify-between mb-10">
                 {pdfBytes && <PdfViewer pdfBytes={pdfBytes}/>}
            </div>

        </Container>
    );
}

export default DownloadTestPlan;