import React, { useState, useMemo } from "react";
import Button from "../../../../common/Button";
import { Add, MoreVert } from "@mui/icons-material";
import { TD, TH, TR, Tbody, Thead } from "../../../../styled/table";
import AddTyreDetail from "./AddTyreDetail";

function TyreDetails({
  expanded,
  setExpanded,
  handleNextClick,
  data,
  formik,
  tyreSize,
  plant,
  ...props
}) {
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(!formik.values["tyreDetails"]?.length || formik.values["tyreDetails"]?.filter(detail => detail.tyre.material === null )?.length);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [hasErrors, setHasErrors] = useState(false)


  
  useMemo(() => {
    let allErrorsInfo = false;
    Object.keys(hasErrors).map(item=> allErrorsInfo |= item)
  setDisabled(allErrorsInfo || !formik.values.tyreDetails?.length || formik.values["tyreDetails"]?.filter(detail => detail.tyre.material === null )?.length || !formik.values["tyreDetails"]?.filter(detail => detail.tyre.testTyre === "JK" )?.length)
}, [hasErrors, formik.values.tyreDetails])

  return (
    <div className="flex flex-col relative w-full col-span-2">
      <div className="overflow-x-scroll">
          <table className="w-full">
            <Thead className="flex flex-row justify-between">
              <TH> Test Tyre</TH>
              <TH> No of Tyres</TH>
              <TH> Tyre Size Desc</TH>
              <TH> Group</TH>
              <TH> Action</TH>
            </Thead>
            <Tbody>
            {formik.values["tyreDetails"]?.length ? formik.values["tyreDetails"]?.map((entry, index) => {
              return (
                <TR className="">
                  <TD> {entry.tyre?.testTyre} </TD>
                  <TD> {entry.tyre?.noOfTyres} </TD>
                  <TD> {entry.tyre?.tyreSizedescription} </TD>
                  <TD> {entry.tyre?.group} </TD>
                  <TD className="text-center">
                    {" "}
                    <Button
                      onClick={() => {
                        setShowModal(!showModal);
                        setSelectedEntry(entry);
                        setSelectedIndex(index);
                      }}
                      className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                      text="View All (31 Fields)"
                    ></Button>
                  </TD>
                </TR>
              );
            }): <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Tyre Details Added </div>}
            </Tbody>
          </table>
      </div>
      {expanded===props.totalSteps ? <></>:<div className="w-full items-right mt-5 ">
        <Button
          className="bg-main text-white w-36 text-center justify-center gap-1 float-right"
          Icon={<Add />}
          text="Add New"
          onClick={() => {
            setShowModal(true);
          }}
        ></Button>
      </div>}
      {expanded===props.totalSteps ? <></>:<div className="flex flex-row gap-3 mt-6 text-md w-48">
        <Button
          type="button"
          disabled={disabled}
          className="bg-main text-white text-center items-center justify-center h-10 w-full"
          onClick={() => {
            handleNextClick(props.index);
            // console.log("tyre details", formik.values.tyreDetails)
            props.handleSubmit("tyreDetails",  {tyreDetails: [...formik.values.tyreDetails]}, props.index)
          }}
        >
          Next
      </Button>
        <Button
          type="button"
          disabled={!Object.keys(formik.touched)?.length}
          className="bg-main text-white text-center items-center justify-center h-10 w-full"
          onClick={() => {
            formik.setValues({ ...data });
          }}
        >
          Clear
        </Button>
      </div>}
      {showModal && (
        <AddTyreDetail
          title="Add Tyre Details"
          parentFormik={formik}
          data={selectedEntry?.tyre}
          tyreDetails= {formik.values["tyreDetails"]}
          index={selectedIndex ?? (formik.values["tyreDetails"]?.length + 1)}
          tyreSize = {tyreSize}
          plant = {plant}
          hasErrors = {hasErrors}
          setHasErrors = {setHasErrors}
          handleSubmit={(values) => {
            setDisabled(false);
            let currentVals = formik.values["tyreDetails"]
              ? [...formik.values["tyreDetails"]]
              : [];
            if (selectedIndex !== null) {
              currentVals[selectedIndex] = {tyre: values};
            }
            if (selectedIndex == null)
              formik.setFieldValue("tyreDetails", [...currentVals, {tyre: values}]);
            else formik.setFieldValue("tyreDetails", [...currentVals]);
            
            setSelectedEntry(null);
            setSelectedIndex(null);
    
          }}
          handleClick={() => {
            setShowModal(!showModal);
            setSelectedEntry(null);
            setSelectedIndex(null);
          }}
          totalSteps={props.totalSteps}
        />
      )}
    </div>
  );
}



export default TyreDetails;
