import React from "react";
import InputWithIcon from "../../common/InputWithIcon";
import Button from "../../common/Button";
import CustomAddPage from "../custom-pages/CustomAddModal";

function Create({ data, handleClick }) {
  const [initialValues, setInitialValues] = React.useState({
    name: data?.name || "",
    code: data?.code || ""
  });

  const inputFields = [
    {
      name: "name",
      label: "Permission Name",
      type: "text",
      required: true,
    },
    {
      name: "code",
      label: "Code Name",
      type: "text",
      required: true,
      disabled: data?.code
    },
  ];
  return (
  <CustomAddPage
      pageTitle={data ? "Update Permission" : "Add Permission"}
      url={data ? "/auth/updatePermission" : "/auth/createPermission"}
      action={!data ? "create" : "update"}
      updateId={data?._id}
      queryKey="permission"
      inputFields={inputFields}
      data={initialValues}
      setData={setInitialValues}
      handleClick={handleClick}
    />
  );
}

export default Create;
