import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
  return (
    <div className="inter flex w-full h-screen justify-center items-center  ">
      <div className="flex flex-col p-10 w-screen h-screen justify-center items-center gap-2 bg-[#000842]">
        <div className=" text-white font-bold">
          404 ERROR
        </div>
        <div className=" text-2xl lg:text-4xl text-gray-300 font-bold">
          Page Not Found
        </div>
        <div className=" text-sm lg:text-xl text-gray-300 font-semibold">
          Sorry, we couldn't find the page you're looking for
        </div>
        <Link to="/" className=" underline text-white font-medium">
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
