import React from 'react';
import { Pagination } from '@mui/material';
import InputWithIcon from './InputWithIcon';
import DropDown from './DropDown';

function PaginationDiv({ page, setPage, limit, setLimit, length }) {
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="flex flex-row justify-end items-center align-middle gap-4 mt-6">
      <Pagination
        count={Math.ceil(length / limit)}
        page={page}
        showFirstButton
        showLastButton
        onChange={handlePageChange}
        color="secondary"
      />
      <div className="text-grey">
        showing {length ? ((page - 1) * limit + 1) : 0} to{" "}
        {length ? Math.min(page * limit, length) : 0} of {length}
      </div>
      <div className="w-36 flex flex-row justify-between border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-semibold">
        Show {limit}
        <InputWithIcon
          placeholder="All Status"
          variant="outlined"
          className="w-9"
          Icon={
            <DropDown
              list={[
                { value: 10, name: "Show 10", onClick: () => handleLimitChange(10) },
                { value: 20, name: "Show 20", onClick: () => handleLimitChange(20) },
                { value: 30, name: "Show 30", onClick: () => handleLimitChange(30) },
                { value: 40, name: "Show 40", onClick: () => handleLimitChange(40) },
              ]}
            />
          }
          inputClass="hidden"
        />
      </div>
    </div>
  );
}

export default PaginationDiv;
