import React, { useEffect, useMemo, useState } from "react";
import UsageDetails from "../view/ProposedVehciles/ProposedVehicles";
import CustomAddPage from "../../custom-pages/CustomAddModal";
import { getAllData } from "../../../apis/customApiHandler";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from "yup";
import Button from "../../../common/Button";

const ProposedVehicles = ({ ...props }) => {
  const [make, setVehicleMake] = useState([]);
  const [model, setVehicleModel] = useState([]);
  const [configuration, setVehicleConfiguration] = useState([]);
  const [application, setApplication] = useState([]);
  const [fitmentAxle, setFitmentAxle] = useState([]);
  const [testingAxle, setTestingAxle] = useState([]);

  const [disabled, setDisabled] = useState(
    !props.formik.values?.proposedVehicles ||
      !Object.keys(props.formik.values?.proposedVehicles)?.length
  );
  const formSchema = Yup.object().shape({});
  // console.log("proposedVehicless",disabled)
  console.log("proposed", props.data, props.testRequest)
  return (
    <div>
      <UsageDetails {...props} />
      {props.expanded === props.totalSteps ? (
        <></>
      ) : (
        <div className="flex flex-row gap-3 mt-6 text-md w-24">
          <Button
            type="button"
            disabled={props.disabled}
            className="bg-main text-white text-center items-center justify-center h-10 w-full"
            onClick={() => {
              props.handleNextClick(props.index);
            }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProposedVehicles;
