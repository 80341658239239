import React, { useEffect, useState } from "react";
import Container from "../../common/Container";
import Action from "../../common/Actions"
import "../../index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputWithIcon from "../../common/InputWithIcon";
import { TH, TD, TR, Thead, Tbody } from "../../styled/table";
import Add from "@mui/icons-material/Add";
import Button from "../../common/Button";
import PaginationDiv from "../../common/PaginationDiv";
import CustomAddPage from "../../components/custom-pages/CustomAddModal"
import { createDocument, deleteDocumentById, getAllData } from "../../apis/customApiHandler";
import { convertToIndianTimezoneAndGetDate, handleDownClick, handleUpClick } from "../../utils/helpers";
import { LinearProgress } from "@mui/material";
import checkPermission from "../../utils/checkPermission";


function VehicleConfigurations() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("")
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [vehicleConfigurations, setVehicleConfigurations] = useState([])
  const [vehicleConfiguration, setVehicleConfiguration] = useState({})
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false)
  

  const getVehicleConfiguration = async () => {
    setLoading(true)
    const data = await createDocument("/master/vehicle/getVehicleConfiguration", {page, limit, search});
    setVehicleConfigurations(data?.data)
    setTotalData(data?.totalData || 0)
    console.log('Total data:', data);
    setLoading(false)
  }
  
  // useEffect(() => {
  //   getVehicleConfiguration();
  // }, [search, limit, page])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    getVehicleConfiguration();
  }, [debouncedSearch, limit, page]);

  const list = [
    {
      name: "Edit",
     condition: checkPermission([
    {
        permission: "masters",
        subPermission: ["UPDATE"]
    },
], currentUser),
      onClick: (Configuration) => {
        setVehicleConfiguration(Configuration);
        setShowEditModal(true);
      },
      className: "text-grey",
    },
    {
      name: "Delete",
     condition: checkPermission([
    {
        permission: "masters",
        subPermission: ["DELETE"]
    },
], currentUser),
      onClick: async (Configuration) => {
        setVehicleConfiguration(Configuration);
        await createDocument("/master/vehicle/updateVehicleConfiguration?_id=" +  Configuration?._id, {deleted: true});
                await getVehicleConfiguration()
      },
      className: "text-error",
    },
  ];
  return (
    <Container>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="grid grid-col-1 col-span-2 md:col-span-1 flex flex-col">
          <div className="font-semibold text-[24px]"> Vehicle Configurations</div>
        </div>

        <div className="flex flex-row text-secondary h-12 gap-3 text-xl">
          <div className="">
            <Button
              text="Add New Vehicle Configuration"
              disabled = {!checkPermission([
                {
                    permission: "masters",
                    subPermission: ["CREATE"]
                },
            ], currentUser)}
              className="w-full flex flex-row border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-medium text-white bg-dark-blue gap-3"
              Icon={<Add color="inherit" />}
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <InputWithIcon
          placeholder="Search for Vehicle Configuration..."
          variant="outlined"
          setData={setSearch}
          Icon={<SearchIcon className="text-dark-grey" />}
          className="w-80 border border-gray-300 items-center px-2 rounded-md h-12 text-[16px] shadow-sm"
          inputClass="text-[16px]"
        />
      </div>
      <table className="w-full table">
        <Thead className="flex flex-row justify-between w-full">
          <tr className=" flex flex-row justify-between w-full">
            
            {/* <TH className="" type="checkbox"> <input type="checkbox"></input></TH> */}
            <TH > Model </TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setVehicleConfigurations} field={"name"} > Name</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setVehicleConfigurations} field={"description"} > Description</TH>
            <TH > Body Type </TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setVehicleConfigurations} field={"numberOfWheels"} > No. of wheels</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setVehicleConfigurations} field={"createdAt"} > Created On</TH>
            <TH> Actions</TH>
          </tr>
        </Thead>
        {loading ? <LinearProgress color="secondary" /> :
          <Tbody className="flex flex-col justify-between w-full">
            {vehicleConfigurations?.length ? vehicleConfigurations?.map((entry, index) => {
              if (true)
                return (
                  <TR key={index}>
                    {/* <TD className="ml-2 text-center justify-center " type="checkbox"> <input type="checkbox"></input> </TD> */}
                    {/* <TD> {entry.image && <img src= {entry.image} className="rounded-full h-10 w-10 mx-auto"/>} </TD> */}
                    <TD> {entry.model?.name} </TD>
                    <TD> {entry.name} </TD>
                    <TD> {entry.description} </TD>
                    <TD> {entry.bodyType} </TD>
                    <TD> {entry.numberOfWheels} </TD>
                    <TD> {convertToIndianTimezoneAndGetDate(entry.createdAt)} </TD>
                    <TD className="text-center"> <Action list={list} data={entry} /> </TD>
                  </TR>
                );
            }): <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Data Found </div>}
          </Tbody>
        }
      </table>
      <PaginationDiv
        page={page}
        setPage={setPage}
        length={totalData}
        limit={limit}
        setLimit={setLimit}
      />
      {showAddModal && <Create handleClick={async () => { setShowAddModal(false); await getVehicleConfiguration() }} />}
      {showEditModal && <Create data={vehicleConfiguration} handleClick={async () => { setShowEditModal(false); await getVehicleConfiguration() }} />}
    </Container>
  )
}
function Create({ data, handleClick }) {
  const tyrePositions = ["L","R","LI","LO","RI","RO","0"]
  const axleNames = ["Head", "1","2","3","4","5","6","7","T1","T2","T3","T4","Tail"]
  const [initialValues, setInitialValues] = React.useState({
    name: data?.name || "",
    description: data?.description || "",
    // numberOfWheels: data?.numberOfWheels || "",
    // tyrePositions: data?.tyrePositions,
    bodyType: data?.bodyTypeId ?? null,
    image: data?.image,
    hoursBasedReading: data?.hoursBasedReading,
    axles: data?.axles ?? [],
    model: data?.model?._id,
    // permissions: data?.axles ?? []
  });
  const [tempData, setTempData] = useState({})
  const [bodyTypes, setBodyTypes] = useState([])
  const [vehicleModels, setVehicleModels] = useState([]);
  const [updatedData, setUpdatedData] = useState(null)

  console.log({updatedData})

  let object = {};
  useEffect(
    () => {
      console.log(data?.axles)
      data?.axles.map((axle) => {
        object[axle.name] =
          axle.tyrePositions;
      });
      console.log({object})
      setInitialValues({ ...initialValues, permissions: object });
      setUpdatedData({ ...initialValues, permissions: object });
    },
    [data]
  );

  const getVehicleMake = async () => {
    const data = await getAllData("/master/vehicle/getVehicleModel", {});
    setVehicleModels(data?.data)
  }
  useEffect(() => {
    getVehicleMake();
  }, [])


  // const formSchema = Yup.object().shape({
  //   tyrePositions: Yup.array().when("numberOfWheels", {
  //     is: (numberOfWheels, tyrePositions) =>
  //       numberOfWheels !== tyrePositions?.length,
  //     then: Yup.array().required("Should be equal to the number of wheels"),
  //   }),
  // });
   
  const getBodyTypes = async () => {
    const data = await getAllData("/master/vehicle/getBodyType", {});
    console.log(data?.data)
    setBodyTypes(data?.data?.map(val=> {return {name: val.name, _id: val._id}}))
  }

  useEffect(()=>{
    getBodyTypes();
  }, [])
  const tyrePositionsOptions = [
    "FL",
    "FR",
    "DLO",
    "DLI",
    "DRO",
    "DRI",
    "DLO1",
    "DLI1",
    "DRO1",
    "DRI1",
    "DLO2",
    "DLI2",
    "DRO2",
    "DRI2",
    "TLO1",
    "TLI1",
    "TRO1",
    "TRI1",
    "TLO2",
    "TLI2",
    "TRO2",
    "TRI2",
    "TLO3",
    "TLI3",
    "TRO3",
    "TRI3",
    "STP",
    "STP1",
    "STP2",
    "FL1",
    "FR1",
    "FL2",
    "FR2",
    "LLO",
    "LLI",
    "LRO",
    "LRI",
    "LL1",
    "LR1",
    "RLO",
    "RLI",
    "RRO",
    "RRI",
    "RL1",
    "RL2",
    "RR1",
    "RR2",
    "TL1",
    "TL2",
    "TR1",
    "TR2",
    "LL2",
    "LR2",
    "RL",
    "RR",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const inputFields = [
    {
      name: "model",
      label: "Vehicle Model",
      type: "select",
      className: "w-full col-span-2",
      options: vehicleModels?.map(type=> {return {label: (type.make?.name ? type.make?.name + "/" : "" )+ type.name , value: type._id}}),
      required: true,
    },
    {
      name: "name",
      label: "Configuration Name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      required: true,
    },
    {
      name: "bodyType",
      label: "Body Type",
      type: "select",
      className: "w-full col-span-3",
      options: bodyTypes?.map(type=> {return {label: type.name, value: type._id}}),
      required: true,
    },    
    // {
    //   name: "image",
    //   label: "Image Url",
    //   type: "text",
    //   required: true,
    // },
    // {
    //   name: "tyrePositions",
    //   label: "Tyre positions",
    //   type: "select",
    //   className: "w-full col-span-2",
    //   options: tyrePositionsOptions,
    //   onChange: (formik, value)=>{
    //     formik.setFieldValue("tyrePositions", [...value.map(item => item.label)]);
    //     formik.setFieldValue("numberOfWheels", value.length)
    //   },
    //   required: true,
    //   isMulti: true,
    // },
    // {
    //   name: "numberOfWheels",
    //   label: "No Of Wheels",
    //   type: "number",
    //   // required: true,
    //   disabled: true,
    //   value: tempData && tempData.tyrePositions?.length,
    // },
    ...axleNames.map(axle => {
      return {   
        name: axle,
        label: "Axle-" + axle,
        disabled: true,
        type: "parentCheckbox",
          className: "flex flex-row-reverse justify-end w-48 gap-2 p-1",
          child: [...tyrePositions.map(item => {
            return {
              name: item,
              label: item,
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2 px-2",
            }
          })],
    }})
  ];

  return (
    <CustomAddPage
      pageTitle={data ? "Update Vehicle Configuration" : "Add Vehicle Configuration"}
      url={data ? "/master/vehicle/updateVehicleConfiguration" : "/master/vehicle/addVehicleConfiguration"}
      action={!data ? "create" : "post"}
      updateId={data?._id}
      queryKey="vehicleConfiguration"
      inputFields={inputFields}
      data={initialValues}
      updatedData={updatedData}
      setData={setTempData}
      handleClick={handleClick}
    />
  );
}
export default VehicleConfigurations