import axios from '../../apis/axios';
import toast from 'react-hot-toast';
import { userActionType } from './user.action.type';
import store from '../store'

export const setCurrentUser = (user) => ({
  type: userActionType.SET_CURRENT_USER,
  payload: user,
});

export const setNotification = (notif) => ({
  type: userActionType.ADD_NOTIFICATION,
  payload: notif,
});

export const setNotifications = (notifs) => ({
  type: userActionType.ADD_NOTIFICATIONS,
  payload: notifs,
});
export const loginUser = (user, navigate) => async (dispatch) => {
  const myPromise = axios({
    method: 'post',
    data: {
      ...user,
    },
    url: `/auth/login`,
  });
  toast.promise(myPromise, {
    loading: 'Loading',
    success: (data) => {
      if(data?.data){
        dispatch(setCurrentUser({ token: data?.data.token, ...data?.data.user }));
        localStorage.setItem("user", JSON.stringify({token: data?.data.token, ...data?.data.user}))
        localStorage.setItem("token", (data?.data.token))
        window.dispatchEvent(new Event("storage"));
        window.location.href = ("/dashboard");
        return `Successfully Logged in`;
      }else{
        return `Something went wrong`
      }
    },
    error: (err) =>
      err.response.data.error ||
      err.response.data.message ||
      err.message ||
      'Something went wrong',
  });
};

export const logOutUser = (navigate) => async (dispatch) => {
  const user = store.getState().user?.currentUser
  const myPromise = axios({
    method: 'post',
    data: {
        isLoggedInFromAdminPortal: false,
      id: user?._id,
    },
    url: `auth/updateUser/logout`
  });
  toast.promise(myPromise, {
    loading: 'Loading',
    success: (data) => {
      dispatch(setCurrentUser(null));
      localStorage.clear();
      window.dispatchEvent(new Event("clearStorage"));
      navigate("/login")
      return 'Successfully Logged out';
    
    },
    error: (err) =>
      err.response.data.error ||
      err.response.data.message ||
      err.message ||
      'Something went wrong',
  });
};

export const addNotification = (notification) => async (dispatch) => {
  console.log("adding notification")
  const user = store.getState().user?.currentUser
  console.log({notification})
  dispatch(setNotification(notification));
};

export const addNotifications = (notifications) => async (dispatch) => {
  console.log("adding notifications")
  const user = store.getState().user?.currentUser
  console.log({notifications})
  dispatch(setNotifications(notifications));
};