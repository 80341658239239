import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { list } from 'postcss';
import { titleCase } from '../utils/helpers';
export default function DropDown({list, heading, Icon, cname}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
    <div className="right-4 cursor-pointer ">
      {Icon ? Icon : <img src= "./icons/dropDown.svg"
        className=' cursor-pointer block'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}/>
      }
      <Menu
        className='absolute right-1/2 top-1/2 w-full p-0'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* {list.map(item,index)=>{          
          return (item.condition!== undefined ? item.condition : true) && <div className= "hover:bg-main hover:text-white text-darkGrey w-[160px] px-[12px] py-[10px] block cursor-pointer" onClick={()=>{item.onClick(); handleClose();}} key= {list.name}>{titleCase(item.name)}</div>
        }) */}
        {list?.map((item,index)=>{
          return (item.condition!== undefined ? item.condition : true) && <div key={index} className= "hover:bg-main hover:text-white text-darkGrey w-[160px] px-[12px] py-[10px] block cursor-pointer" onClick={()=>{item.onClick(); handleClose();}}>{titleCase(item.name)}</div>
        })}
      </Menu>
    </div>
          
    </div>
  );
}
