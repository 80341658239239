import React, { useEffect, useState } from "react";
import Container from "../../common/Container";
import Collapsable from "../../common/Collapsable";
import { Form, FormikProvider, useFormik } from "formik";
import TyreDetails from "./view/TyreDetails/TyreDetails";
import UsageDetails from "./view/UsageDetails/UsageDetails";
import TyreAvailabilityDetails from "./view/TyreAvailabilityDetails.jsx";
import CallbackRequirements from "./view/CallbackRequirements/CallbackRequirement";
import DocumentUpload from "./view/DocumentUpload";
import TestRequirement from "./view/TestRequirement";
import Button from "../../common/Button";
import { deleteDocumentById, getAllData, updateDocumentByID } from "../../apis/customApiHandler";
import DownloadTestRequest from "./DownloadTestRequest";
import { convertToIndianTimezone, convertToIndianTimezoneAndGetDate, titleCase } from "../../utils/helpers";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { LoaderIcon } from "react-hot-toast";
import { LinearProgress } from "@mui/material";
import checkPermission from "../../utils/checkPermission";
import Modal from "../../common/Modal"
import { sendEmail } from "../../utils/helpers";

function ViewTestRequest({  }) {
  const [view, setView] = useState(false)
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [modifyDisabled, setModifyDisabled] = useState(false)
  const [expanded, setExpanded] = useState(6);
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode")
  const [requestNo, setRequestNo] = useState()
  const [allowEdit, setAllowEdit] = useState(false)
  const [action, setAction] = useState(null)
  const navigate = useNavigate();
  // console.log({currentUser})


  const [statuses, setStatuses] = useState({
    0: 2, 1: 2, 2: 2, 3: 2, 4: 2, 5: 2
  });

  const handleChangeWindow = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      if (panel !== 0) {
        if (statuses[panel - 1] !== 2) return;
      }
    }
    setExpanded(newExpanded ? panel : false);
    setStatuses({ ...statuses, [panel]: statuses[panel] === 2 ? 2 : 1 });
  
  };
  useEffect(()=>{
    setRequestNo(searchParams.get("requestNo"))
    setAllowEdit(["APPROVED", "ACCEPTED", "REJECTED", "ASKED FOR MODIFICATION", "ASK_FOR_MODIFICATION"].includes(searchParams.get("mode")))
  }, [searchParams])


  const getTestRequest = async()=>{
    console.log("FETCHING SINGLE REQUEST")
    const response = await getAllData("/test_request", {requestNo});
    if(response.data?.length){
      setData(response.data[0])
      formik.setValues(response.data[0])
    }
  }

  
  useEffect(()=>{
    console.log({requestNo})
    if(requestNo) getTestRequest();
  }, [requestNo])


  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      console.log({ values });
    },

    validate: (values) => {
      let errors = {};
      if (!values.testRequirements) errors.testRequirements = "Required";
      if (!values.password) errors.password = "Required";
      return errors;
    },
  });
  // console.log(data?.status === "APPROVED", ((currentUser.role.name == 'PDC Head' || currentUser.role.name === 'PTG Head') === false),  data.nextActionBy !== currentUser.role.name )

  // console.log(formik.values)

  console.log(formik.values.remarksTimeline)

  const sections = [
    {
      title: "Test Requirement",
      name: "testRequirement",
      content: (
        <TestRequirement
          index={0}
          name="testRequirement"
          totalSteps={6}
          formik={formik}
          disabled={disabled}
          setDisabled={setDisabled}
          expanded={expanded}
          setExpanded={setExpanded}
          allowEdit={allowEdit}
          remarksTimeline = {data?.remarksTimeline?.testRequirement}
          handleSubmit={(values) =>{
            console.log({values});
            formik.setFieldValue("remarksTimeline", { ...formik.values.remarksTimeline, "testRequirement": values })
            console.log(formik.values.remarksTimeline)
          }}
          data={data?.testRequirement}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Tyre Details",
      name: "tyreDetails",
      content: (
        <TyreDetails
          index={1}
          totalSteps={6}
          formik={formik}
          data={data?.tyreDetails}
          allowEdit={allowEdit}
          remarksTimeline = {data?.remarksTimeline?.tyreDetails}
          handleSubmit={(values) =>
            // formik.setFieldValue("tyreDetails", [...data.tyreDetails?.filter(detail => detail.tyre), { remarks: values }])
            formik.setFieldValue("remarksTimeline", { ...formik.values.remarksTimeline, "tyreDetails": values })

          }
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Usage Details",
      name: "usageDetails",
      content: (
        <UsageDetails
          index={2}
          name="usageDetails"
          totalSteps={6}
          formik={formik}
          data={data?.usageDetails}
          allowEdit={allowEdit}
          remarksTimeline = {data?.remarksTimeline?.usageDetails}
          handleSubmit={(values) =>
            // formik.setFieldValue("usageDetails", { ...data.usageDetails, remarks: values })
            formik.setFieldValue("remarksTimeline", { ...formik.values.remarksTimeline, "usageDetails": values })
          }
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Tyre Availability Details",
      name: "tyreAvailabilityDetails",
      content: (
        <TyreAvailabilityDetails
          index={3}
          name="tyreAvailabilityDetails"
          totalSteps={6}
          formik={formik}
          disabled={disabled}
          setDisabled={setDisabled}
          allowEdit={allowEdit}
          remarksTimeline = {data?.remarksTimeline?.tyreAvailabilityDetails}
          handleSubmit={(values) =>
            // formik.setFieldValue("tyreAvailabilityDetails", { ...data.tyreAvailabilityDetails, remarks: values })
            formik.setFieldValue("remarksTimeline", { ...formik.values.remarksTimeline, "tyreAvailabilityDetails": values })

          }
          data={data?.tyreAvailabilityDetails}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Call Back Requirement",
      name: "callBackRequirement",
      content: (
        <CallbackRequirements
          index={4}
          name="callBackRequirement"
          totalSteps={6}
          formik={formik}
          disabled={disabled}
          setDisabled={setDisabled}
          allowEdit={allowEdit}
          remarksTimeline = {data?.remarksTimeline?.callBackRequirement}
          handleSubmit={(values) =>
            // formik.setFieldValue("callBackRequirement", [...data.callBackRequirement.filter(detail => !detail.remarks), { remarks: values }])
            formik.setFieldValue("remarksTimeline", { ...formik.values.remarksTimeline, "callbackRequirement": values })
          }
          data={data?.callBackRequirement}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Documents",
      name: "documents",
      content: (
        <DocumentUpload
          index={5}
          name="documents"
          totalSteps={6}
          formik={formik}
          expanded={expanded}
          setExpanded={setExpanded}
          data={data?.documents}
          allowEdit={allowEdit}
          remarksTimeline = {data?.remarksTimeline?.documents}
          handleSubmit={(values) =>
            // formik.setFieldValue("documents", [...data.documents, { remarks: values }])
            formik.setFieldValue("remarksTimeline", { ...formik.values.remarksTimeline, "documents": values })
          }

        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
  ];

  // console.log("status", data.status)
  return (
    <Container cname="container_test_request">
      <div className="flex flex-col md:flex-row justify-between mb-10 gap-2">
        <div className=" flex flex-col">
          <div className="font-semibold text-[24px] flex flex-row ">
            {view && (
              <span
                className="justify-start mr-2 cursor-pointer"
                onClick={() => {
                  setView(false);
                }}
              >
                <img src="/icons/back.svg" className="m-1 h-4 w-4 mt-3 "></img>
              </span>
            )}
            <span>View Details</span>
          </div>
          <div className="text-grey mt-1 font-semibold">({data.requestNo})</div>
        </div>

        <div className="flex flex-row text-white gap-5 px-2 text-[14px] h-10">
          <div
            className={`${
              data.status?.toUpperCase() === "REJECTED"
                ? "text-red-500"
                : data.status?.toUpperCase() === "APPROVED" ||
                  data.status?.toUpperCase() === "ACCEPTED"
                ? "text-green-500"
                : data.status?.toUpperCase() === "ASKED FOR MODIFICATION"
                ? " text-orange-500"
                : "text-main"
            } text-md justify-center align-middle m-auto py-2 px-5 text-center font-medium rounded-md text-[16px] border border-2`}
          >
            {titleCase(data.status)}
          </div>
          {!view && (
            <button
              className={`  text-center text-white py-2 rounded w-36  ${data.status === "DRAFT" ? "bg-lightGrey" : "bg-blue-900 hover:shadow-lg hover:bg-mainLighter"}`}
              disabled={data.status === "DRAFT"}
              onClick={() => {
                setView(!view);
              }}
            >
              View as PDF{" "}
            </button>
          )}
        </div>
      </div>
      {view ? (
        <DownloadTestRequest data={data} />
      ) : formik.values?.testRequirement ? (
        <FormikProvider value={formik} className="">
          <Form>
            {sections.map((section, index) => {
              return (
                <Collapsable
                  expanded={expanded}
                  setExpanded={setExpanded}
                  statuses={statuses}
                  setStatuses={setStatuses}
                  section={section}
                  handleChangeWindow={handleChangeWindow}
                  formik={formik}
                  index={index}
                  totalSteps={6}
                />
              );
            })}
            {
              // (currentUser.role.name == "PDC Head" ||
              //   currentUser.role.name === "PTG Head") === false ||
              // data.status === "ASKED FOR MODIFICATION" ||
              data.status === "ACCEPTED" ||
              data.status === "REJECTED" ? (
                // data.nextActionBy !== currentUser.role.name
                // false
                <></>
              ) : (
                <div className="flex justify-between mt-6 text-md">
                  {mode !== "REVIEW" && data.status!== "DRAFT" && <div className="flex flex-row gap-3  w-98">
                    {checkPermission(
                      [
                        {
                          permission: "test_requests",
                          subPermission: ["REJECT"],
                        },
                      ],
                      currentUser
                    ) && (
                      <Button
                        type="button"
                        className="bg-danger text-white text-center items-center justify-center h-10 px-8"
                        onClick={async (e) => {
                          e.preventDefault();
                          const response = await updateDocumentByID(
                            "/test_request",
                            data?._id,
                            {
                              ...formik.values,
                              status: "REJECTED",
                              action: "REJECT",
                              actionBy: currentUser.role.name,
                              nextAction: "",
                              nextActionBy: "",
                              updatedBy: currentUser?._id,
                            }
                          );
                          window.location.href = "/test-requests";
                        }}
                      >
                        Reject
                      </Button>
                    )}
                    {checkPermission(
                      [
                        {
                          permission: "test_requests",
                          subPermission: ["ASK_FOR_MODIFICATION"],
                        },
                      ],
                      currentUser
                    ) && (
                      <Button
                        type="button"
                        // disabled={resetDisabled}
                        className="bg-white text-grey border border-2 border-grey text-center items-center justify-center h-10 px-6"
                        onClick={() => {
                          setAction({
                            type: "Ask for Modification",
                            status: "ASKED FOR MODIFICATION",
                          });
                        }}
                      >
                        Ask for Modification
                      </Button>
                    )}
                  </div>}
                <div className="flex flex-row gap-3  w-98">
                  {mode !== "REVIEW" && data.status!== "DRAFT" && checkPermission(
                    [
                      {
                        permission: "test_requests",
                        subPermission: [data.nextAction],
                      },
                    ],
                    currentUser
                  ) && (
                    // data.nextActionBy === currentUser.role.name &&
                    <Button
                      type="button"
                      // disabled={resetDisabled}
                      className="bg-main text-white text-center items-center justify-center h-10 px-6"
                      onClick={async () => {
                        const response = await updateDocumentByID(
                          "/test_request",
                          data?._id,
                          {
                            ...formik.values,
                            ...// currentUser.role.name === "PDC Head" &&
                            (!["ACCEPTED", "APPROVED"].includes(
                              data.status
                            ) && {
                              status: "APPROVED",
                              action: "APPROVE",
                              nextAction: "ACCEPT",
                              actionBy: currentUser.role.name,
                              nextActionBy: "PTG Head",
                            }),
                            ...(data.status ===
                              "APPROVED" && // currentUser.role.name === "PTG Head" &&
                            {
                              status: "ACCEPTED",
                              action: "ACCEPT",
                              actionBy: currentUser.role.name,
                              nextAction: "",
                              nextActionBy: "",
                            }),
                            updatedBy: currentUser?._id,
                          }
                        );
                        window.location.href = "/test-requests";
                      }}
                    >
                      {titleCase(data.nextAction)}
                    </Button>
                  )}

                  {((checkPermission(
                          [
                            {
                              permission: "test_requests",
                              subPermission: ["CREATE", "UPDATE"],
                            },
                          ],
                          currentUser
                        )) &&
                    mode === "REVIEW") && (
                    <div className="flex justify-between text-md">
                      <div className="justify-end">
                        <Button
                          type="button"
                          // disabled={resetDisabled}
                          className="bg-main text-white text-center items-center justify-center h-10 px-6"
                          onClick={async () => {
                            // formik.setValues({ ...formik.values });
                            console.log(formik.values);
                            const response = await updateDocumentByID(
                              "/test_request",
                              data?._id,
                              {
                                ...formik.values,
                                ...(formik.values.status === "DRAFT" && {
                                  generateRequestNumber: true,
                                }),
                                submissionDate: formik.values.status === "DRAFT" ? new Date(new Date().getTime() + 330*60*1000): formik.values.submissionDate,
                                updatedBy: currentUser?._id,
                                actionBy: currentUser.role.name,
                                status:
                                  formik.values.nextActionBy === "PTG Head"
                                    ? "PENDING_ACCEPTANCE"
                                    : formik.values.status === "DRAFT" &&
                                      mode === "REVIEW"
                                    ? "SENT FOR APPROVAL"
                                    : formik.values.status === "ASKED FOR MODIFICATION" &&
                                      (mode === "EDIT" || mode === "REVIEW")
                                    ? "PENDING_APPROVAL"
                                    : formik.values.status === "SENT FOR APPROVAL"
                                    ? "SENT FOR APPROVAL"
                                    : mode !== "REVIEW"
                                    ? mode
                                    : "PENDING_APPROVAL",
                                action:
                                  mode === "REVIEW"
                                    ? formik.values.status !== "DRAFT"
                                      ? "UPDATE"
                                      : "CREATE"
                                    : formik.values.action,
                              }
                            );
                            if (response?.data) {
                              // navigate("/test-requests")
                              window.location.href = "/test-requests";
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}

              </div>
                </div>
              )
            }
            {action && (
              <Modal
                handleClick={() => {
                  setAction(null);
                }}
                title={"Alert!"}
                showSubTitle={false}
                id="MODAL"
                className={` w-64 min-h-fit p-3 px-4`}
              >
                <p className="p-2 mb-2 flex mx-auto justify-center">
                  Please confirm it you want to {action.type} on this test
                  request
                </p>
                <div className= "w-24 mx-auto justify-center">
                <Button
                  className="m-2 flex mx-auto bg-main text-white text-center items-center justify-center h-10 px-6"
                  disabled={modifyDisabled}
                  onClick={async () => {
                    // formik.setValues({ ...formik.values });
                    setModifyDisabled(true);
                    try{
                    const response = await updateDocumentByID(
                      "/test_request",
                      data?._id,
                      {
                        ...formik.values,
                        status: action.status,
                        action: action.status,
                        updatedBy: currentUser?._id,
                        nextAction:
                        checkPermission(
                          [
                            {
                              permission: "test_requests",
                              subPermission: ["ACCEPT"],
                            },
                          ],
                          currentUser
                        )
                            ? "ACCEPT"
                            : "APPROVE",
                        actionBy: currentUser.role.name,
                        nextActionBy: currentUser.role.name,
                      }
                    );
                    window.location.href = "/test-requests";
                    }catch(err){

                    }finally{
                      setModifyDisabled(false)
                    }
                  }}
                >
                  Yes
                </Button>
                </div>
              </Modal>
            )}
          </Form>
        </FormikProvider>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </Container>
  );
}

export default ViewTestRequest;
