import React from "react";
import TyreDetails from "../view/TyreDetails/TyreDetails";
import Button from "../../../common/Button";

function AddTyreDetails(props) {
  return (
    <div>
      <TyreDetails {...props} />
      {props.expanded === props.totalSteps ? (
        <></>
      ) : (
        <div className="flex flex-row gap-3 mt-6 text-md w-24">
          <Button
            type="button"
            disabled={props.disabled}
            className="bg-main text-white text-center items-center justify-center h-10 w-full"
            onClick={() => {
              props.handleNextClick(props.index);
            }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}

export default AddTyreDetails;
