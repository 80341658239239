import React, { useEffect, useMemo, useState } from "react";
import UsageDetails from "../view/ProposedVehciles/ProposedVehicles";
import CustomAddPage from "../../custom-pages/CustomAddModal";
import {
  createDocument,
  getAllData,
  updateDocumentByID,
} from "../../../apis/customApiHandler";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from "yup";
import Button from "../../../common/Button";
import { TD, TH, TR, Tbody, Thead } from "../../../styled/table";
import InputField from "../../formik/InputField";
import { getFinalInputs } from "../../../utils/helpers";
import { Form, FormikProvider, useFormik } from "formik";
import SelectField from "../../formik/SelectField";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Add, Delete } from "@mui/icons-material";
import Input from "../../../styled/input";

const FitmentDetails = ({
  data,
  calculatedTyreDetails,
  handleSubmit,
  formik,
  handleNextClick,
  url,
  queryKey,
  requestNo,
  yupValidationSchema = {},
  updateId,
  ...props
}) => {
  const [resetDisabled, setResetDisabled] = useState([]);
  const [location, setLocation] = useState([]);
  const [configuration, setVehicleConfiguration] = useState([]);
  const [application, setApplication] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadSegment, setLoadSegment] = useState([]);
  const [testRequest, setTestRequest] = useState(null);


  // console.log({data})

  const inputRow = [
    {
      name: "category",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "location",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "testMethod",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "fitmentAxle",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "testTyre",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "testQuantity",
      type: "Number",
      tyreType: "testTyre",
      label: "",
      required: true,
    },
    {
      name: "bmTyre",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "bmQuantity",
      type: "number",
      label: "",
      tyreType: "bmTyre",
      required: true,
    },
    {
      name: "totalQuantity",
      type: "number",
      label: "",
      disabled: true,
      required: true,
    },
    { name: "vehicleQuantity", type: "number", label: "", required: true,initialValue: 0, },
    {
      name: "segment",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "application",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "configuration",
      type: "select",
      label: "",
      required: true,
    },
  ];
  const [inputs, setInputs] = useState([inputRow]);
  const queryClient = useQueryClient();
  const currentRemarksLength = props.remarksTimeline ? props.remarksTimeline?.length: 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))


  const formSchema = Yup.object().shape({
    fitmentPlan: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const { mutateAsync } = useMutation((data) =>
    props.action === "update"
      ? updateDocumentByID(url, updateId, data)
      : createDocument(url, data)
  );

  const [disabled, setDisabled] = useState(props.disabled);

  // const fitmentStruct = {
  //   category: "",
  //   location: "",
  //   testTyre: "",
  //   location: "",
  //   application: "",
  //   testQuantity: 0,
  //   ftQuantity: 0,
  //   ptQuantity: 0,
  //   ftPlanned: 0,
  //   ftPlanned: 0,
  // };

  // useEffect(()=>{
  //   if(data?.length) formik.setFieldValue("fitmentDetails", data)
  // }, [data])


  // console.log(formik.values, formik.values);

  // console.log("tr", formik.values);
  return (
    <div className="w-full flex flex-col relative">
          <div className="heading text-darkGrey">Vehicle Details</div>
          <div className="overflow-x-scroll mb-1 relative">
            <table className="w-full">
              <Thead className="flex flex-row justify-between">
                <TH> Category</TH>
                <TH> Location</TH>
                <TH> Test Method</TH>
                <TH> Axle</TH>
                <TH> Test Tyre</TH>
                <TH> Test Quantity</TH>
                <TH> BM Tyre</TH>
                <TH> BM Quantity</TH>
                <TH> Total Quantity</TH>
                <TH> VehicleQuantity</TH>
                <TH> Segment</TH>
                <TH> Application</TH>
                <TH> Configuration</TH>
              </Thead>
              <Tbody className="">
                {!loading ? (
                  data?.map(
                    (entry, entryIndex) => {
                      console.log({entry})
                      return (
                        <TR className="relative">
                          <TD>{entry.category}</TD>
                          <TD>{entry.location}</TD>
                          <TD>{entry.testMethod}</TD>
                          <TD>{entry.fitmentAxle}</TD>
                          <TD>{entry.testTyre}</TD>
                          <TD>{entry.testQuantity}</TD>
                          <TD>{entry.bmTyre ?? "---"}</TD>
                          <TD>{entry.bmQuantity ?? "---"}</TD>
                          <TD>{entry.totalQuantity ?? "---"}</TD>
                          <TD>{entry.vehicleQuantity ?? "---"}</TD>
                          <TD>{entry.segment ?? "---"}</TD>
                          <TD>{entry.application ?? "---"}</TD>
                          <TD>{entry.configuration ?? "---"}</TD>
                        </TR>
                      );
                    }
                  )
                ) : (
                  <div className="w-full text-semibold text-center text-lightGrey p-3">
                    {" "}
                    No Tyre Details Added{" "}
                  </div>
                )}
              </Tbody>
            </table>
          </div>


          <div className="heading text-darkGrey mt-5 ">Tyre Details</div>

          <div className="overflow-x-scroll w-full">
            <table className="w-full">
              <Thead className="flex flex-row justify-between">
                <TH> Test Type for</TH>
                <TH> Group</TH>
                <TH> Group Description</TH>
                <TH> Tyre Size Desc</TH>
                <TH> Stencil From</TH>
                <TH> Stencil To</TH>
                <TH> No of Tyres</TH>
                <TH> FT Quantity</TH>
                <TH> PT Quantity</TH>
                <TH> FT Planned</TH>
                <TH> PT Planned</TH>
              </Thead>
              <Tbody>
                {calculatedTyreDetails?.length ? (
                  calculatedTyreDetails.map((entry, index) => {
                    // console.log({entry})
                    if (!entry.remarks)
                      return (
                        <TR className="">
                          <TD> {entry.tyre?.testTyre} </TD>
                          <TD> {entry.tyre?.group} </TD>
                          <TD> {entry.tyre?.groupDescription} </TD>
                          <TD> {entry.tyre?.tyreSizedescription} </TD>
                          <TD> {entry.tyre?.stencilFrom} </TD>
                          <TD> {entry.tyre?.stencilTo} </TD>
                          <TD> {entry.tyre?.noOfTyres} </TD>
                          <TD>
                            {" "}
                            {entry.tyre?.ftQuantity}
                            <div className="text-red-400 text-sm">
                              {formik.errors?.calculatedTyreDetails &&
                                formik.errors?.calculatedTyreDetails[index]
                                  ?.ftQuantity}
                            </div>
                          </TD>
                          <TD>
                            {" "}
                            {entry.tyre?.ptQuantity}
                            <div className="text-red-400 text-sm">
                              {formik.errors?.calculatedTyreDetails &&
                                formik.errors?.calculatedTyreDetails[index]
                                  ?.ptQuantity}
                            </div>
                          </TD>
                          <TD> {entry.tyre?.ftPlanned} </TD>
                          <TD> {entry.tyre?.ptPlanned} </TD>
                        </TR>
                      );
                  })
                ) : (
                  <div className="w-full text-semibold text-center text-lightGrey p-3">
                    {" "}
                    No Tyre Details Added{" "}
                  </div>
                )}
              </Tbody>
            </table>
          </div>
          <div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}
    </div>
  );
};

export default FitmentDetails;
