import React, { useState } from "react";
import AddPage from "../../../custom-pages/AddPage";
import ViewCallbackRequirement from "./ViewCallbackRequirement"
import * as Yup from "yup";
import { TD, TH, TR, Thead } from "../../../../styled/table";
import { Add } from "@mui/icons-material";
import Button from "../../../../common/Button";
import Input from "../../../../styled/input";

function CallbackRequirements({
  handleSubmit,
  formik,
  data,
  ...props
}) {

  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(!formik.values[""]?.callBackRequirement?.length);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // console.log({index: props.index})
  return (
    <div className="flex flex-col relative w-full col-span-2">
    <div className="w-full overflow-x-scroll">
      <div className="overflow-x-scroll">
        <table className="w-full">
          <Thead className="flex flex-row justify-between">
            <TH> Identified Location</TH>
            <TH> Material</TH>
            <TH> No. of Tyres</TH>
            <TH> Analysis Destination</TH>
            <TH> Stage</TH>
            <TH> Analysis Reason</TH>
          </Thead>
          {data?.length ? data?.map((entry, index) => {
            if(!entry.remarks) return (
              <TR className="">
                <TD> {entry?.location} </TD>
                <TD> {entry?.material} </TD>
                <TD> {entry?.numberOfTyres} </TD>
                <TD> {entry?.analysisDestination} </TD>
                <TD> {entry?.stage} </TD>
                <TD className="text-center">
                  {" "}
                  <Button
                    onClick={() => {
                      setShowModal(!showModal);
                      setSelectedEntry(entry);
                      setSelectedIndex(index);
                    }}
                    className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                    text="View All (7 Fields)"
                  ></Button>
                </TD>
              </TR>
            );
          }): <></>}
        </table>
      </div>
    </div>

    {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Remarks
          
          <Input
            name={"remarks"}
            onChange={(e) => {
              handleSubmit(e.target.value)
            }}
            value={formik?.values["callBackRequirement"].filter(detail=> detail.remarks)?.length ? formik?.values["callBackRequirement"].filter(detail=> detail.remarks)[0]?.remarks : ""}
            formik={formik}
            className={` w-full font-normal`}
          />
        </label>
      </div>}

    {showModal && (
      <ViewCallbackRequirement
        title="View Callback Requirement"
        parentFormik={formik}
        data={selectedEntry}
        handleClick={() => {
          setShowModal(!showModal);
          setSelectedEntry(null);
          setSelectedIndex(null);
        }}
        totalSteps={props.totalSteps}
      />
    )}
  </div>
  );
}

export default CallbackRequirements;
