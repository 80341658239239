import React, { useState, useMemo } from "react";
import Button from "../../../../common/Button";
import AddPage from "../../../custom-pages/AddPage";
import AddUsageDetail from "./AddUsageDetail"
import { Add, MoreVert } from "@mui/icons-material";
import { TD, TH, TR, Tbody, Thead } from "../../../../styled/table";

import * as Yup from 'yup';
import SelectField from "../../../formik/SelectField";
import InputField from "../../../formik/InputField";
import InputWithIcon from "../../../../common/InputWithIcon"
import { Field } from "formik";
import Input from "../../../../styled/input";
import { getAllData } from "../../../../apis/customApiHandler";


const UsageDetails = ({formik, expanded, setExpanded, handleNextClick, data,productCategory, ...props}) => {
  
  const [loadSegment, setLoadSegment] = useState([])
  const [fitmentRims, setFitmentRims] = useState([])
  
  const getLoadSegment = async () => {
    const data = await getAllData("/master/other/getLoadSegment", {});
    setLoadSegment(data?.data)
  }

  const getFitmentRim = async () => {
    const data = await getAllData("/master/vehicle/getFitmentRim", {});
    setFitmentRims(data?.data)
  }

  useState(()=>{
    getLoadSegment();
    getFitmentRim();
  },[])

  console.log('data12',data);
  

  const inputsStep3 = [
    {
      label: "Load Segment",
      name: "usageDetails.loadSegment",
      required: true,
      type: "select",
      options: loadSegment.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Fitment Rim(Rec)",
      name: "usageDetails.fitmentRim",
      type: "select",
      options: fitmentRims.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "PSI Recommendation",
      name: "psiRec",
      type: "number",
      required: true,
    },
    {
      label: "Fitment Rim(Alt)",
      name: "fitmentRimAlt",
      type: "number",
    },
    
  ]

  const formSchema = Yup.object().shape({
    psiRec: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const [disabled, setDisabled] = useState(!(props.data && Object.keys(props.data)?.length))
  const [showModal, setShowModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [errors, setErrors] = useState({})

  useMemo(() => {
    let hasErrors = false;
    if(!formik.values.usageDetails?.loadSegment < 0){
      if(!formik.errors.usageDetails) formik.errors.usageDetails = {}
      formik.errors.usageDetails.loadSegment = "*Required"
      hasErrors= true
    }
    if(!formik.values.usageDetails?.fitmentRim){
      if(!formik.errors.usageDetails) formik.errors.usageDetails = {}
      formik.errors.usageDetails.fitmentRim = "*Required"
      hasErrors= true
    }
    if(!formik.values.usageDetails?.psiRec || formik.values.usageDetails?.psiRec < 0){
      if(!formik.errors.usageDetails) formik.errors.usageDetails = {}
      formik.errors.usageDetails.psiRec = "*Enter a value > 0"
      hasErrors= true
    }
    if(formik.values.usageDetails?.fitmentRimAlt < 0){
      if(!formik.errors.usageDetails) formik.errors.usageDetails = {}
      formik.errors.usageDetails.fitmentRimAlt = "Should be > 0"
      hasErrors= true
    }

    setErrors(formik.errors.usageDetails)
    console.log(formik.errors.usageDetails)

    setDisabled(hasErrors || !formik.values.usageDetails || !Object.keys(formik.values.usageDetails)?.length)
  }, [formik.values.usageDetails])

// console.log(formik.values["usageDetails"])

  return (
    <div>
      <div className="heading text-darkGrey">Usage Details</div>
      <div className="grid grid-cols-2 sm:w-[100%] md:w-[90%]">
        {inputsStep3?.map((formField) => {
          return (
            <div className="col-span-1 mb-2 sm:w-[100%] md:w-[80%] ">
              {formField.type == "select" ? (
                <SelectField
                  {...formField}
                  // disabled={expanded === props.totalSteps}
                  className={`text-sm ${formField.className}`}
                  formik={formik}
                  key={formField.name}
                  name={formField.name}
                  label={formField.label}
                  options={formField.options}
                  required={formField.required}
                  multiple={formField.multiple}
                  halfCol={formField.halfCol}
                />
              ) : (
                <div className="flex flex-col w-full">
                  <label className="py-1 text-sm text-grey">
                    {formField.label}
                    {formField.required && (
                      <span className="text-red-500 required-dot">*</span>
                    )}
                    <Input
                      name={formField.name}
                      {...formField}
                      onChange={(e) => {
                        console.log(e.target.value, e.target.name);
                        formik.setFieldValue(
                          `usageDetails.${formField.name}`,
                          e.target.value
                        );
                        //  console.log(formik.values)
                      }}
                      value={
                        formik.values["usageDetails"] &&
                        formik.values["usageDetails"][formField.name]
                      }
                      formik={formik}
                      className={` ${formField.className} w-full`}
                    />
                    {errors &&
                    errors[formField.name] ? (
                      <p className="text-red-500 font-normal">
                        {" "}
                        {errors[formField.name]}
                      </p>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="heading text-darkGrey">Vehicle Details</div>

      <div className="flex flex-col relative w-full col-span-2">
        <div className="overflow-x-scroll">
          <div className="overflow-x-scroll">
            <table className="w-full">
              <Thead className="flex flex-row justify-between">
                <TH> Vehicle Make</TH>
                <TH> Vehicle Model</TH>
                <TH> Vehicle Configuration</TH>
                <TH> Testing Axle</TH>
                <TH> Application</TH>
                <TH> Action</TH>
              </Thead>
              <Tbody>
              {formik.values["usageDetails"] && formik.values["usageDetails"]["vehicleDetails"]?.length ? (
                formik.values["usageDetails"]["vehicleDetails"]?.map((entry, index) => {
                  return (
                    <TR className="">
                      <TD> {entry.make} </TD>
                      <TD> {entry.model} </TD>
                      <TD> {entry.configuration} </TD>
                      <TD> {entry.testingAxle} </TD>
                      <TD> {entry.application} </TD>
                      <TD className="text-center">
                        {" "}
                        <Button
                          onClick={() => {  
                            setShowModal(!showModal);
                            setSelectedEntry(entry);
                            setSelectedIndex(index);
                          }}
                          className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                          text="View All (7 Fields)"
                        ></Button>
                      </TD>
                    </TR>
                  );
                })
              ) : (
                <div className= "w-full text-center justify-center mx-auto text-sm font-semibold text-grey inter my-4 "> No Vehicle Detail Added </div>
              )}
              </Tbody>
            </table>
          </div>
        </div>
        {expanded === props.totalSteps ? (
          <></>
        ) : (
          <div className="w-full items-right mt-5 ">
            <Button
              className="bg-main text-white w-36 text-center justify-center gap-1 float-right"
              Icon={<Add />}
              text="Add New"
              onClick={() => {
                setShowModal(true);
              }}
            ></Button>
          </div>
        )}
        {expanded === props.totalSteps ? (
          <></>
        ) : (
          <div className="flex flex-row gap-3 mt-6 text-md w-48">
            <Button
              type="button"
              disabled={disabled}
              className="bg-main text-white text-center items-center justify-center h-10 w-full"
              onClick={() => {
              handleNextClick(props.index);
                // console.log("tyre details", formik.values.usageDetails);
                props.handleSubmit(
                  "usageDetails",
                  { usageDetails: {...formik.values.usageDetails, vehicleDetails: formik.values.usageDetails.vehicleDetails ? [...formik.values.usageDetails.vehicleDetails]: []} },
                  props.index
                );
              }}
            >
              Next
            </Button>
            <Button
              type="button"
              disabled={!Object.keys(formik.touched)?.length}
              className="bg-main text-white text-center items-center justify-center h-10 w-full"
              onClick={() => {
                formik.setValues({ ...data });
              }}
            >
              Clear
            </Button>
          </div>
        )}
        {showModal && (
          <AddUsageDetail
          productCategory={productCategory}
            title="Add Tyre Details"
            parentFormik={formik}
            data={selectedEntry}
            handleSubmit={(values) => {
              setDisabled(false);
              let currentVals = formik.values["usageDetails"] &&  formik.values["usageDetails"]["vehicleDetails"]
                ? [...formik.values["usageDetails"]["vehicleDetails"]]
                : [];
              if (selectedIndex !== null) {
                currentVals[selectedIndex] = { ...values };
              }
              if (selectedIndex == null)
                formik.setFieldValue("usageDetails.vehicleDetails", [
                  ...currentVals,
                  { ...values },
                ]);
              else formik.setFieldValue("usageDetails.vehicleDetails", [...currentVals]);

              setSelectedEntry(null);
              setSelectedIndex(null);
            }}
            handleClick={() => {
              setShowModal(!showModal);
              setSelectedEntry(null);
              setSelectedIndex(null);
            }}
            totalSteps={props.totalSteps}
          />
        )}
      </div>
    </div>
  );
};

export default UsageDetails;
