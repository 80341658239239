import React, { useState, useRef } from "react";
import { useFormik, Form, Field, FormikProvider } from "formik";
// import { Button, Input } from 'styled';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import InputWithIcon from "../common/InputWithIcon";
import Button from "../common/Button";
import { useDispatch } from "react-redux";
import { createDocument } from "../apis/customApiHandler";
import Timer from "../common/Timer";

function OTPForm(props) {
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [response, setResponse] = useState(props.response);
  console.log({ response, res: props.response });

  const formik = useFormik({
    initialValues: {
      OTP: "",
      email: props.email,
    },
    onSubmit: async (values) => {
      setDisabled(true);
      try {
        console.log({ values }, otpDigits);
        const response = await createDocument("/auth/verifyOTP", {
          email: props.email,
          OTP: values.OTP,
        });
        if (response.status !== "fail") {
          props.setStep(3);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDisabled(false);
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) errors.email = "Required";
      if (!values.OTP) errors.OTP = "Required";
      if (!values.OTP?.length == 6) {
        errors.OTP = "Enter 6 digits OTP";
      }
      setDisabled(Object.keys(errors)?.length > 0);
      console.log(errors);
      return errors;
    },
  });

  const resendOtp = async () => {
    const responseData = await createDocument("/auth/forgotPassword", {
      email: props.email,
    });
    setResponse({ ...responseData, email: props.email });
  };

  const handleChange = (index, event) => {
    const { value } = event.target;
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);
    formik.setFieldValue("OTP", updatedOtpDigits.join(""));
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  console.log(formik.values, otpDigits);

  const handleKeyPress = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otpDigits[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  useEffect(() => {
    var blank = false;
    for (let i = 0; i < 6; i++) {
      if (otpDigits[i] === "") {
        blank = true;
      }
    }
    setDisabled(blank);
  }, [otpDigits]);

  return (
    <div className="grid justify-center items-center h-screen bg-white">
      <FormikProvider value={formik} className="col-span-2 lg:col-span-1">
        <Form className="w-full relative rounded-md flex flex-col justify-center mx-auto items-center">
          {formik.touched.email ||
            (formik.touched.email && formik.errors && (
              <p className="bg-yellow-400 p-3 absolute w-full rounded-md text-center top-0 text-white">
                please enter the user id and pin
              </p>
            ))}
          <div className="p-2 flex flex-col w-full space-y-3 justify-center text-gray-800 h-full gap-y-4">
            <div>
              <h1 className="text-darkGrey text-2xl font-semibold text-left">
                Enter OTP
              </h1>
              <h4 className="text-md text-grey mt-1 w-2/3">
                We have mailed you an OTP on your email address.
              </h4>
            </div>
            <div>
              {otpDigits.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(event) => handleChange(index, event)}
                  onKeyPress={(event) => handleKeyPress(index, event)}
                  className="otp-input"
                />
              ))}

              <div className="h-[40px] mt-5 flex flex-row gap-2 w-full text-start">
                <div className="text-primaryDark w-64">
                  Please do not refresh the page, your OTP Expires in{" "}
                  <Timer endTime={response.OTPexpiresAt} />
                </div>
                <button
                type = "button"
                  className={`${
                     
                    (new Date().getTime() - new Date(response.OTPexpiresAt).getTime() >
                      1000 * 60 * 3) ? "text-lightGrey" : "text-main"
                  } underline`}
                  // disabled={
                  //   !response.OTPexpiresAt > new Date().getTime() || 
                  //   (!response.OTPexpiresAt ||
                  //   new Date().getTime() - new Date(response.OTPexpiresAt).getTime() >
                  //     1000 * 60 * 1)
                  // }
                  onClick={resendOtp}
                >
                  {" "}
                  Resend OTP
                </button>
              </div>
            </div>
            <div className="h-[40px] mt-5">
              <button
                type="submit"
                className={`${
                  disabled ? "bg-lightGrey" : "bg-main"
                } w-full text-white h-[40px] rounded-[8px]`}
                disabled={disabled || new Date().getTime() > new Date(response.OTPexpiresAt).getTime()}
              >
                {" "}
                Submit OTP
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}

export default OTPForm;
