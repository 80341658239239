import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../index.css";

import { sideBarClose, sideBarOpen } from "../redux/sidebar/sidebar.reducer";
import checkPermission from "../utils/checkPermission";

// icons
// import checkPermission from "utils/checkPermission";
// import permissions from "utils/permissionConstants";

const Sidebar = () => {
  const sideBarState = useSelector((state) => state.sidebar.sideBarState);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  // console.log("permissions", currentUser.role)

  const dispatch = useDispatch();
  const location = useLocation();

  const menu = [
    {
      title: "Dashboard",
      link: "/dashboard",
      icon: "./icons/dashboard.svg",
      permissions: [{permission:"dashboard", subPermission: ["READ"]}],
    },
    {
      title: "Users",
      link: "/users", 
      icon: "./icons/users.svg",
      permissions: [{permission:"users", subPermission: ["READ"]}],
    },
    {
      title: "Roles",
      link: "/roles", 
      icon: "./icons/role.svg",
      permissions: [{permission:"roles", subPermission: ["READ"]}],
    },
    // {
    //   title: "Permissions",
    //   link: "/permissions",
    //   icon: "./icons/permission.svg",
    //   permissions: [{permission:"permissions", subPermission: ["READ"]}],
    // },
    {
      title: "Test Request",
      link: "/test-requests",
      icon: "./icons/test-requests.svg",
      permissions: [{permission:"test_requests", subPermission: ["READ"]}],
    },
    {
      title: "Test Plan",
      link: "/test-plans",
      icon: "./icons/test-plan.svg",
      permissions: [{permission:"test_plans", subPermission: ["READ"]}],
    },
  ];

  const masters = [
    {
      title: "Market",
      link: "/markets",
    },
    {
      title: "Vehicle Make",
      link: "/vehicle-makes",
    },
    {
      title: "Vehicle Model",
      link: "/vehicle-models",
    },
    {
      title: "Body Type",
      link: "/body-type",
    },
    {
      title: "Configuration",
      link: "/vehicle-configurations",
    },
    {
      title: "PWA",
      link: "/pwa",
    },
    {
      title: "Terrain",
      link: "/terrain",
    },
    {
      title: "Mechanical Condition",
      link: "/mechanical-condition",
    },
    {
      title: "Fitment Axle",
      link: "/fitment-axles",
    },
    {
      title: "Fitment Rim",
      link: "/fitment-rims",
    },
    {
      title: 'Benchmark Company',
      link: "/benchmark-company",
    },
    // {
    //   title: 'Benchmark Tyre Size',
    //   link: "/benchmark-tyresize",
    // },
    {
      title: 'Benchmark products',
      link: "/benchmark-products",
    },
    {
      title: "Product Category",
      link: "/product-categories",
    },
    {
      title: "Product Type",
      link: "/product-types",
    },
    // {
    //   title: "Tyre Make",
    //   link: "/tyre-makes",
    // },
    {
      title: "Test Requirement",
      link: "/test-requirements",
    },
    {
      title: "Methodology",
      link: "/methodologies",
    },
    {
      title: "Tyre Size",
      link: "/direct-tyre-size",
    },
    {
      title: "Material",
      link: "/material",
    },
    {
      title: "Test Category",
      link: "/test-category",
    },
    {
      title:"Observation",
      link:"/observation"
    },
    {
      title: "Plant",
      link: "/plant",
    },
    {
      title: "Test Objective",
      link: "/test-objective",
    },
    {
      title: "Testing Axle",
      link: "/testing-axle",
    },
    {
      title: "Test Method",
      link: "/test-method",
    },
    {
      title: "Test Location",
      link: "/test-location",
    },
    {
      title: "Test Type",
      link: "/test-type",
    },
    {
      title: "Modification",
      link: "/modification",
    },
    {
      title: "Load Index",
      link: "/load-index",
    },
    {
      title: "Speed Index",
      link: "/speed-index",
    },
    {
      title: "Groove",
      link: "/groove",
    },
    {
      title: "Department",
      link: "/department",
    },
    {
      title: "Team",
      link: "/team",
    },
    {
      title: "Application",
      link: "/application",
    },
    {
      title: "Load Segment",
      link: "/load-segment",
    },
    {
      title: "Analysis Location",
      link: "/analysis-location",
    },
    {
      title: "Project Status",
      link: "/project-status",
    },
    {
      title: "Road Condition",
      link: "/road-condition",
    },
    {
      title: "Re Call Stage",
      link: "/re-call-stage",
    },
    {
      title: "Ply Rating",
      link: "/ply-rating",
    },
    {
      title: "Customer Type",
      link: "/customer-type",
    },
  ]?.sort((a, b)=> a.title.localeCompare(b.title));

  const [expanded, setExpanded] = useState(masters?.filter(item => item.link === location.pathname)?.length > 0);


  const parseMenu = (menu, isSubMenu) => {
    return menu.map((menuItem, idx) => {
      // console.log(location.pathname, menuItem.link);
      return (
        checkPermission(menuItem.permissions, currentUser) && <li
          key={idx}
          className={`${!sideBarState
              ? "text-3xl p-4"
              : "text-[16px] p-2 leading-[28px]"
            } p-2  transition-colors hover:shadow-md rounded-md mt-1 ${location.pathname === menuItem.link
              ? (isSubMenu ? "bg-light3 text-secondary font-semibold transition-all" : "bg-secondary text-secondary font-semibold transition-all")
              : "text-dark-grey"
            }`}
        >
          <Link to={menuItem.link} className={`${isSubMenu? "text-sm font-semibold" : ""} flex items-center  gap-4`}>
            <div className="flex change-my-color text-left justify-start mx-2 color-inherit">
              <img src={menuItem.icon ?? "./icons/arrowRight.svg"}/>
            </div>
            <div className="text-left justify-start">
              {sideBarState && menuItem.title}
            </div>
          </Link>
        </li>
      );
    })
  }

  return (
    <div
      className={`${sideBarState ? "w-[14rem]" : "w-16"
        } fixed h-full overflow-y-auto text-gray font-semibold bg-white drop-shadow`}
    >
      <div className="mt-[100px] text-base text-left gap-4 mx-3">
        {checkPermission([{permission:"masters", subPermission: ["READ"]}], currentUser) && (
          <div className="">
            <p
              className="cursor-pointer"
              onClick={() => setExpanded((pre) => !pre)}
            >
              <div
                className={`${!sideBarState
                    ? "text-3xl p-4"
                    : "text-[16px] p-3 leading-[28px]"
                  } p-2  transition-colors hover:shadow-md rounded-md mt-1 ${expanded
                    ? "text-secondary bg-secondary font-semibold transition-all "
                    : "text-dark-grey"
                  }`}
              >
                <div className="flex items-center gap-4">
                  <div className="text-left justify-start mx-2 color-inherit">
                    <img src="./icons/settings.svg" />
                  </div>
                  <div className="text-left justify-start">
                    Masters
                  </div>
                </div>
              </div>
            </p>
            {expanded && (
              <ul className="text-base text-left gap-4 mx-0">
                {parseMenu(masters, true)}
              </ul>
            )}
          </div>
        )}
        <ul className="text-base text-left gap-4 ">
          {parseMenu(menu, false)}
        </ul>

      </div>
    </div>
  );
};

export default Sidebar;
