import React, { useEffect } from "react";
import { useField } from "formik";
import ReactSelect from "react-select";

const SelectField = ({
  name,
  label,
  required,
  options,
  formik,
  isMulti,
  disabled,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const [selectedValue, setSelectedValue] = React.useState(meta.value);
  useEffect(() => {
    formik.setFieldValue(name, selectedValue);
  }, [selectedValue, name]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      marginTop: "6px",
      backgroundColor: disabled
          ? "#C7C9D91F"
          : undefined,

      borderColor: disabled ? "rgba(214,214,214)" : undefined
        // color: disabled
        // ? "white"
          // : "#FF9193F",
      // position: "relative",
    }),
    menuPortal: (styles) => ({ ...styles, zIndex: 9999, fontWeight: "500",  color: "#6B7588", fontSize: "14px",
    lineHeight: "1.25rem",/* 20px */
  }),
    menu: (styles) => {
      return {
        ...styles,
        // positlion: "absolute",
          opacity: 1,
          // transform: "none",
          // minWidth: "120px",
          // transition: "opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          // top: "0",
          // left: "0",
          // zIndex: 10,
          // overflow: "visible"
          // transformOrigin: "60px 0px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        fontWeight: "400",
        zIndex: 1000, // You can adjust the zIndex value as needed
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#8DC153",
            fontWeight: "400"
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "#8DC153",
          fontWeight: "400"
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          fontWeight: "400",
          ":hover": {
            backgroundColor: "#8DC153",
            color: "white",
          },
        }),
        fontWeight: "400",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#123064"
          : isFocused
          ? "#75A0EC"
          : undefined,
        color: isDisabled
          ? undefined
          : isSelected
        ? "white"
          : isFocused
          ? "white"
          : undefined,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#75A0EC"
              : "#123064"
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles,fontWeight: "400" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  // console.log(name, props)

  // console.log(field.value,field.name)

  return (
    <div className={` py-1 text-darkGrey text-sm font-normal relative  ${props.className}`}>
      <label htmlFor="select" className="text-darkGrey gap-2 py-2"
      >
        {label}
        {label && required && <span className="text-red-500 required-dot">*</span>}
      </label>
      <ReactSelect
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        classNames={{
          control: (state) =>
            state.isFocused ? "border-red-600" : "border-grey-300",
        }}
        placeholder={`Select ${label}`}
        styles={colourStyles}
        {...field}
        {...props}
        isDisabled={disabled}
        options={options}
        // value={meta.value ?? null}
        value={isMulti? options.filter(option=>{
          return field.value?.includes(option.value)
        }): {
          value: field.value,
          label: options?.find(
            (option) => option?.value === field.value
          )?.label,
        }}
        onMenuClose={(e)=>{helpers.setTouched(true); }}
        onChange={(value) => {
          // console.log({value})
          // const currentOptions = field.value?.length ? [...field.value] : []
          helpers.setTouched(true)
          // console.log(props.onChange)
          // console.log({currentOptions})
          console.log({value})
          if(props.onChange){
            props.onChange(formik, value)
          }
          else{
            // console.log(field.name, currentOptions, value.map(item => item.label))
            if(isMulti) formik.setFieldValue(field.name, [...value.map(item => item.label)]);
            else formik.setFieldValue(field.name, value.value);
          }

        }}
        isMulti={isMulti}
      />
      {meta.touched && meta.error ? (
        <React.Fragment>
          {meta.error ? (
        <p className="text-red-500 font-normal">*{meta.error}</p>
      ) : null}
      </React.Fragment>
      ) : null}
    </div>
  );
};

export default SelectField;
