import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../../common/Button";
import InputField from "../formik/InputField";
import { createDocument, updateDocumentByID } from "../../apis/customApiHandler";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import SelectField from "../formik/SelectField";
import Modal from "../../common/Modal";
import AsyncSelect from "../formik/AsyncSelect";
import { objectsAreEqual } from "../../utils/helpers";

const CustomAddPage = ({  
  data,
  updatedData,
  setData,
  url,
  queryKey,
  handleClick,
  yupValidationSchema,
  onCancel,
  handleSubmit,
  inputFields,
  pageTitle,
  action,
  updateId,
  cname,
  helperCall,
}) => {

  // console.log(helperCall)
  const { mutateAsync } = useMutation((data) =>
    helperCall ? helperCall(data) :
    url?
    action === "update"
      ? updateDocumentByID(url, updateId, data)
      : action === "post"
      ? createDocument(url + "?_id=" + updateId, data)
      : createDocument(url, data): 
    async()=>{
      console.log("Creating")
    }
  );
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {...data},
    onSubmit: async (values) => {
      setDisabled(true)
      try{
        let filterSelectInputs = inputFields?.filter(
          (input) => input.type === "select"
        );

        let filterSelectInputsValues = filterSelectInputs?.map((input) => {
          return values[input.name];
        });
        let finalInputs = { ...values };
        filterSelectInputsValues?.forEach((input, index) => {
          finalInputs[filterSelectInputs[index].name] = filterSelectInputs[
            index
          ].isMulti
            ? input
            : input;
        });
        if (url || helperCall) {
          // console.log("calling helper")
          await mutateAsync(finalInputs, {
            onSuccess: (data) => {
              queryClient.invalidateQueries(queryKey);
              console.log({ data });
              // console.log("submitting");
              if (handleSubmit) handleSubmit(finalInputs);
              if (data) handleClick();
            },
            onError: (err) => {
              console.error("in error", err);
            },
          });
        } else {
          handleSubmit(finalInputs);
          handleClick();
        }
      }catch(err){
        toast.message(err?.message ?? "Some Error Occured","danger")
      }finally{
        setDisabled(false)
      }
    },

    validationSchema: yupValidationSchema || undefined,
    validate: (values) => {
      let errors = {};
      inputFields?.forEach((field) => {
        if (field.required && !values[field.name]) {
          errors[field.name] = "Required";
        }
      });
      // console.log("disabled", formik.touched, !Object.keys(formik.touched)?.length || (errors && Object.keys(errors)?.length))
      // setResetDisabled(!Object.keys(formik.touched)?.length)
      console.log({errors})
      return errors;
    },
  });

  useEffect(()=>{
    console.log("formik errors", formik.errors)
    setDisabled(!Object.keys(formik.touched)?.length || (formik.errors && Object.keys(formik.errors)?.length));
  },[formik.errors])





  const [disabled, setDisabled] = useState(true);
  const [resetDisabled, setResetDisabled] = useState(true)

  useMemo(() => {
    if (updatedData && Object.keys(updatedData).length) {
      formik.setValues({ ...updatedData });
    }
  }, [updatedData]);
  
  useMemo(() => {
    if (setData && formik.values) {
      setData(formik.values);
    }
  }, [formik.values, setData, data]);
  
  useEffect(()=>{
    if(formik.touched && Object.keys(formik.touched)?.length){
      setDisabled((formik.errors && Object.keys(formik.errors)?.length))
      if(resetDisabled){
        setResetDisabled(false)
      }
    }
  }, [formik.touched])

  // console.log(formik.values)
  console.log({disabled})


  return (
    <Modal
      handleClick={handleClick}
      title={pageTitle}
      id="MODAL"
      className={` w-full min-h-fit ${cname}  p-3 px-4`}
    >
      <FormikProvider value={formik}
            validateOnChange={true}
      >
        <Form >
          <div className="text-gray-700 font-medium text-base grid  lg:gap-x-4  gap-y-5 grid-flow-row grid-cols-4 lg:grid-cols-6 w-full">
          {inputFields?.map((field) => {
            switch (field.type) {
              case "select":
                return (
                  <SelectField
                    formik={formik}
                    className= {field.className}
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    options={field.options}
                    required={field.required}
                    isMulti={field.isMulti}
                    halfCol= {field.halfCol}
                    onChange= {field.onChange}
                    disabled={field.disabled}
                  />
                );
              case "file":
                return (
                  <div>
                    {field.url && (
                      <img src={field.url} className="w-16 h-16" alt="" />
                    )}
                    <InputField
                      formik={formik}
                      className= {field.className}
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      type={field.type}
                      required={field.required}
                      halfCol= {field.halfCol}
                      limit={field.limit}
                      multiple={field.multiple}
                    />
                  </div>
                );

                case "parentCheckbox":
                  return (
                    <div className="flex flex-col col-span-8 justify-start">
                      {!field.disabled ? <InputField
                        formik={formik}
                        className= {`${field.className} col-span-1 flex flex-row justify-start`}
                        inputClass = "font-semibold text-gray-700 underline"
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        type="checkbox"
                        required={field.required}
                        disabled={field.disabled}
                        checked = {formik.values["permissions"] && formik.values["permissions"][field.name] && formik.values["permissions"][field.name].length}
                        eChange={(e) => {
                          const permissions = {...formik.values.permissions};
                          if(!permissions){
                            permissions = {}
                          }
                          if(e.target.checked === true){
                            permissions[field.name]= field.child.map(entry => entry.name.toUpperCase())
                          }else{
                            delete permissions[field.name]
                          }
                          formik.setFieldValue("permissions", permissions);
                          formik.setFieldValue(field.name, e.target.checked)
                        }}
                      />: 
                      <div className= "py-1 text-grey text-sm">{field.label}</div>}
                      <div className="flex flex-row gap-2 grid grid-cols-8">
                        {field.child?.map((subField) => {
                          return (
                            <InputField
                              formik={formik}
                              className={`${subField.className}`}
                              halfCol= {true}
                              checked={formik.values["permissions"] && formik.values["permissions"][field.name]!==undefined && formik.values["permissions"][field.name]?.includes(
                                subField.name.toUpperCase()
                              )}
                              eChange={(e) => {
                                const permissions = {...formik.values.permissions}
                                if(!permissions){
                                  permissions = {}
                                }
                                if(!permissions[field.name]){
                                  delete permissions[field.name]
                                }
                                if(e.target.checked === true){
                                  if(!permissions[field.name]) permissions[field.name]=[];
                                  permissions[field.name].push(e.target.name.toUpperCase())
                                }else{
                                  const remainingPermissions = permissions[field.name]?.filter(subPermission=>subPermission!==e.target.name.toUpperCase())
                                  if(remainingPermissions?.length) permissions[field.name] = remainingPermissions;
                                  else delete permissions[field.name] 
                                }
                                formik.setFieldValue("permissions", permissions)
                              }}
                              key={subField.name}
                              name={subField.name}
                              label={subField.label}
                              type="checkbox"
                              placeholder={subField.placeholder}
                              required={subField.required}
                              disabled={subField.disabled}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                  case "async-select":
                   
                    return ( <AsyncSelect
                      // {...props}
                      {...field} 
                      formik={formik}
                      />
                    )

              default:
                return (
                <InputField
                    formik={formik}
                    key={field.name}
                    className= {field.className}
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    placeholder={field.placeholder}
                    required={field.required}
                    disabled={field.disabled}
                    halfCol= {field.halfCol}
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
            }
          })}
          </div>
          {inputFields?.length % 2 === 0 ? <div /> : null}
          <div className="flex items-start justify-start gap-4 mt-4 w-48">
            <Button 
              type="submit"
              onClick= {()=>{console.log("HERE")}}
              className="bg-main text-white text-center items-center justify-center h-10 w-full"
              disabled = {disabled}
            >
              <span>Submit</span>
            </Button>
            <Button
              type="button"
              disabled= {resetDisabled}
              className="bg-main text-white text-center items-center justify-center h-10  w-full"
              onClick={() => {
                formik.setValues({...data})
                setDisabled(true)
                setResetDisabled(true)
                formik.setTouched({})
              }}            >
              <span>Reset</span>
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default CustomAddPage;
