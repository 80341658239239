import { AsyncPaginate } from "react-select-async-paginate";
import React, { useEffect, useMemo, useState } from 'react'
import { useField } from "formik";
import { getById } from "../../apis/customApiHandler";
import InputField from "./InputField";
import Input from "../../styled/input";

function AsyncSelect({formik, ...props}) {
  const [field, meta, helpers] = useField(props.name);
  // const [selectedValue, setSelectedValue] = useState(meta.value || null); // Set initial value to meta.value
  // const [options, setOptions] = useState([])
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? '1px solid black': null,
        boxShadow: null,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#123064' :  state.isFocused ? '#75A0EC' : null,
        color: state.isSelected ? 'white' : state.isFocused ? 'white' : 'null',
    }),
}

  // useMemo(() => {
  //   console.log(meta.value)
  //   setSelectedValue(meta.value);
  // }, [meta.value]);

  // console.log({selectedValue})
  // React.useEffect(() => {
  //   const fetchOptions = async () => {
  //     // Fetch options data here
  //     const loadedOptions = await props.loadOptions('', [], {page: 1});
  //     setOptions(loadedOptions);
  //   };

  //   fetchOptions();
  // }, []);

  // console.log({options})


  // console.log(field.value, {disabled: props.disabled})
  return (
    <div className= {props.className}>
      <label className="py-1 text-grey text-sm" style={{ color: "#757575" }}>
        {props.label}
        {props.disabled || field.value ? 
        <Input value={field.value} disabled={true} className= "w-full"/> 
        // <></>
        : <AsyncPaginate
          id={props.name}
          name={props.name}
          disabled={props.disabled}
          loadOptions={props.loadOptions}
          // value = {field.value}
          // options = {options}
          getOptionValue={(option) => {console.log({option}); return props.optionField ? option[props.optionField]: option}}
          getOptionLabel={(option) => {return option[props.optionField]}}
          onChange={async (value) => {
            helpers.setTouched(true)
            // setSelectedValue(value); // Update selectedValue when value changes            
            if (props.onChange) {
              props.onChange(formik, value);
            }
          }}
          additional={{
            page: 1,
          }}
          styles={customStyles}
          isSearchable={true}
          placeholder={`Select ${props.label}`}
          debounceTimeout={500}
          isMulti={props.isMulti}
        />}
      </label>
    </div>
  );
}

export default AsyncSelect