import React, { useEffect, useState } from "react";
import Container from "../../common/Container";
import Collapsable from "../../common/Collapsable";
import { Form, FormikProvider, useFormik } from "formik";
import TyreDetails from "./steps/TyreDetails/TyreDetails";
import UsageDetails from "./steps/UsageDetails/UsageDetails";
import TyreAvailabilityDetails from "./steps/TyreAvailabilityDetails";
import CallbackRequirements from "./steps/CallbackRequirements/CallbackRequirement";
import DocumentUpload from "./steps/DocumentUpload";
import TestRequirement from "./steps/TestRequirement";
import Button from "../../common/Button";
import { createDocument, getAllData, updateDocumentByID } from "../../apis/customApiHandler";
import { useSearchParams } from "react-router-dom";

function CreateTestRequest(props) {
  const [initialValues, setInitialValues] = useState({});
  const [requestNo, setRequestNo] = useState()
  const [createMode, setCreateMode] = useState(false)
  const [valueChanged, setValueChanged] = useState(true);
  const [productCategory, setProductCategory] = useState('');
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [searchParams, setSearchParams] = useSearchParams();
  const data = {};
  
  const formik = useFormik({
    initialValues: initialValues!==null ? initialValues : {},
    onSubmit: async (values) => {
      console.log({ values });
    },

    validate: (values) => {
      let errors = {};
      if (!values?.testRequirements) errors.testRequirements = "Required";
      if (!values?.password) errors.password = "Required";
      return errors;
    },
  });

  useEffect(()=>{
    setCreateMode(searchParams.get("mode") === "EDIT" || searchParams.get("mode") === "CREATE")
    setRequestNo(searchParams.get("requestNo"))
  }, [searchParams])
  
  // console.log({testRequest: props.testRequest, values: formik.values})


  const getTestRequest = async()=>{
    console.log("FETCHING SINGLE REQUEST")
    // const response = await getAllData("/test_request/draft", {createdBy: currentUser?._id});
    const response = await getAllData("/test_request", {requestNo});
    if(response.data?.length) setInitialValues(response.data[0])
  }

  useEffect(()=>{
    if(requestNo) getTestRequest();
    else{
      setInitialValues(props.testRequest)
      formik.setValues(props.testRequest)
      if(!props.allowEdit) setExpanded(0)
    }
  }, [requestNo])


  useEffect(()=>{
    formik.setValues({...initialValues});
    console.log("in change initial values")
    setStatuses({
      0: getStatus(initialValues?.testRequirement) ? 2 : 1,
      1: getStatus(initialValues?.tyreDetails) ? 2 : statuses[1], 
      2: getStatus(initialValues?.usageDetails)  ? 2 : statuses[2],
      3: getStatus(initialValues?.tyreAvailabilityDetails) ? 2 : statuses[3],
      4: getStatus(initialValues?.callBackRequirement) ? 2 : initialValues?.tyreAvailabilityDetails ? 1: statuses[4],
      5: getStatus(initialValues?.documents) ? 2 : initialValues?.tyreAvailabilityDetails ? 1 :  statuses[5],
    })
  }, [initialValues])


  const [disabled, setDisabled] = useState(false);
  const [expanded, setExpanded] = useState(0);
  /**
   * 0 -> pending
   * 1 -> in progress
   * 2 -> completed
   */
  const getStatus = (data) =>{
    if(typeof data === "object"){
      return data && Object.keys(data)?.length
    }else return data
  }
  const [statuses, setStatuses] = useState({
    0: getStatus(initialValues?.testRequirement) ? 2 : 1,
    1: getStatus(initialValues?.tyreDetails) ? 2 : 0,     
    2: getStatus(initialValues?.usageDetails)  ? 2 : 0,
    3: getStatus(initialValues?.tyreAvailabilityDetails) ? 2 : 0,
    4: getStatus(initialValues?.callBackRequirement) ? 2 : 0,
    5: getStatus(initialValues?.documents) ? 2 : 0,
  });



  /// NOT NEEDED
  const handleNextClick = (panel) => {
    console.log({panel, panelPlus1: panel+1})
    console.log("in next click")
    setStatuses({...statuses, [panel+1]: 1});
    setExpanded(panel+1);
  };

  const setValues = async (module, values, index) => {
    formik.setFieldValue(module, values[module])
    console.log(module, values, index)
    setValueChanged(true)
    setStatuses({...statuses, [index]: 2, [index+1]: 1})
    setExpanded(index+1);
  }

  useEffect(()=>{console.log({statuses})}, [statuses])

  const saveAsDraft = async (values={}) => {
    // formik.setFieldValue(module, values)
    let response = {};
    if(initialValues?._id){
      response = await updateDocumentByID("/test_request", initialValues?._id, {...formik.values, ...values, createdBy: currentUser?._id, nextAction: "APPROVE", nextActionBy: "PDC Head"})
    }else{
      response = await createDocument("/test_request", {...formik.values, ...values, createdBy: currentUser?._id, nextAction: "APPROVE", nextActionBy: "PDC Head", status: "DRAFT"
    })
    }
    setInitialValues(response?.data)
    setValueChanged(true)
    setStatuses({...statuses, index: 2})
    return response?.data
  }

  const handleChangeWindow = (panel) => (event, newExpanded) => {
    console.log("in handle change window") // not to worry about
    if (newExpanded) {
      console.log( statuses[panel])
      if (panel !== 0) {
        if (!(statuses[panel] == 1 || statuses[panel] == 2) && statuses[panel - 1] !== 2) return;
      }
    }
    setExpanded(newExpanded ? panel : false);
    setStatuses({...statuses, [panel]: statuses[panel] === 2 ? 2 : 1});

  };

   const handleProductCategoryChange = (value) => {
    setProductCategory(value);
  };

  const sections = [
    {
      title: "Test Requirement",
      name: "testRequirement",
      content: (
        <TestRequirement
          index={0}
          totalSteps={6}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values.testRequirement}
          expanded={expanded}
          status={formik.values.status}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          onProductCategoryChange={handleProductCategoryChange}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Tyre Details",
      name: "tyreDetails",
      content: (
        <TyreDetails
          index={1}
          totalSteps={6}
          handleNextClick={handleNextClick}
          formik={formik}
          tyreSize={formik.values?.testRequirement?.tyreSize}
          plant={formik.values?.testRequirement?.plant}
          // disabled={disabled}
          // setDisabled={setDisabled}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={(module, values, index) => {
            console.log(module, values, index)
            if (values === null) return;
             setValues(module, values, index);
          }}
          data={formik.values?.tyreDetails}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },

    {
      title: "Usage Details",
      name: "usageDetails",
      content: (
        <UsageDetails
          index={2}
          totalSteps={6}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values?.usageDetails}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          productCategory={productCategory}
        />
      ),
    },
    {
      title: "Tyre Availability Details",
      name: "tyreAvailabilityDetails",
      content: (
        <TyreAvailabilityDetails
          index={3}
          totalSteps={6}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values?.tyreAvailabilityDetails}
          // disabled={disabled}
          // setDisabled={setDisabled}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Callback Requirement",
      name: "callBackRequirement",
      content: (
        <CallbackRequirements
          index={4}
          totalSteps={6}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values?.callBackRequirement}
          initialValues={initialValues?.callBackRequirement}
          materials={formik.values?.tyreDetails?.map((item) => {
            return { label: item.tyre.material, value: item.tyre.material };
          })}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          tyreSize={formik.values?.testRequirement?.tyreSize}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Documents",
      name: "documents",
      content: (
        <DocumentUpload
          index={5}
          totalSteps={6}
          handleNextClick={handleNextClick}
          formik={formik}
          // disabled={disabled}
          // setDisabled={setDisabled}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          data={{ documents: formik.values?.documents }}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
  ];

  return (
    <Container cname= "">
      {/* container_test_request */}
      <div className="flex flex-col md:flex-row justify-between mb-10">
        <div className=" flex flex-col">
          <div className="font-semibold text-[24px]"> {requestNo ? createMode ? "Edit" : "View" : "Create"} Test Request</div>
          <div className="text-grey mt-1 font-semibold">{(formik.values?.requestNo) ?? ""}</div>

        </div>

        <div className="flex flex-row text-darkGrey gap-5 px-2 text-[16px] font-semibold">
          <div className=" flex flex-row items-center gap-2">
            <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
            <div className="">In Progress</div>
          </div>
          <div className=" flex flex-row items-center gap-2">
            <div className="w-[20px] h-[20px] rounded-full bg-main"></div>
            <div>Pending </div>
          </div>
          <div className=" flex flex-row items-center gap-2">
            <div className="w-[20px] h-[20px] rounded-full bg-green-400 bg-opacity-100"></div>
            <div>Completed </div>
          </div>
        </div>
      </div>
      {createMode ? <FormikProvider value={formik}>
        {formik.values && <Form style={{ height: "max-content" }}>
          <div className="table">
            {sections.map((section, index) => {
              return (
                <Collapsable
                  expanded={expanded}
                  setExpanded={setExpanded}
                  statuses={statuses}
                  setStatuses={setStatuses}
                  section={section}
                  formik={formik}
                  index={index}
                  handleNextClick={handleNextClick}
                  handleChangeWindow={handleChangeWindow}
                  totalSteps={6}
                />
              );
            })}
          </div>
          {expanded !== 6 && formik.values.status!== "ASKED FOR MODIFICATION" ? 
            <div className= "w-36 float-right mt-6 ">
            <Button
                type="button"
                disabled={disabled}
                className="bg-main text-white text-center items-center justify-center h-10 w-full hoverDiv"
                onClick={(e) => {
                  e.preventDefault();
                  saveAsDraft()
                }}  
              >
                {!formik.values.status ? "Save as Draft": "Save"}
              </Button>
              <div className="hide text-main">Save all completed steps</div>
            </div>
           :
            expanded === 6 && <div className="flex justify-between mt-6 text-md ">
              <div className="flex flex-row gap-3  w-64 ">
                {
                  <div className= "flex flex-col"><Button
                  type="button"
                  disabled={disabled}
                  className="bg-main text-white text-center items-center justify-center h-10 w-36 max-w-[8rem] hoverDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    saveAsDraft()
                  }}
                >
                  {!formik.values.status ? "Save as Draft": "Save"}
                </Button>
                <div className="hide text-main">Save all completed steps</div>
                </div>}
                <Button
                  type="button"
                  className="bg-main text-white text-center items-center justify-center h-10 w-full max-w-[8rem]"
                  onClick={() => {
                    console.log({data})
                    setExpanded(5)
                  }}
                >
                  Back
                </Button>
              </div>
              <Button
                type="button"
                // disabled={resetDisabled}
                className="bg-main text-white text-center items-center justify-center h-10 px-6"
                onClick={async () => {
                  setDisabled(true);
                  const req = await saveAsDraft()
                  // await saveAsDraft({ status: "PENDING_APPROVAL", ...initialValues.status === "DRAFT" && { submissionDate: new Date() } })
                  console.log("requestNo", req?.requestNo)
                  props.setViewMode(req?.requestNo, "REVIEW")
                  
                }}
              >
                Review
              </Button>
            </div>
          }
        </Form>
      }
      </FormikProvider>
      : <></>}
    </Container>
  );
}

export default CreateTestRequest;
