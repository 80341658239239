import React, { useEffect, useState } from 'react'
import CustomAddPage from '../custom-pages/CustomAddModal';
import { getAllData } from '../../apis/customApiHandler';
import * as Yup from 'yup';

function Create({ data, handleClick }) {
    const [initialValues, setInitialValues] = useState({
      ...data,
      role: data?.role?._id,
    });
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [teams, setTeams] = useState([])
    const [locations, setLocations] = useState([])

    const getRoles  = async()=>{
        const data = await getAllData("/auth/getRoles", {});
        setRoles(data?.data)
    }
    const getDepartments  = async()=>{
      const data = await getAllData("/master/other/getDepartment", {});
      setDepartments(data?.data)
    }
    const getLocations  = async()=>{
      const data = await getAllData("/master/test/getTestLocation", {});
      setLocations(data?.data)
    }
    const getTeams  = async()=>{
      const data = await getAllData("/master/other/getTeam", {});
      setTeams(data?.data)
    }
    useEffect(()=>{
        getRoles();
        getDepartments();
        getTeams();
        getLocations();
    }, [])

    const yupValidationSchema =  Yup.object().shape({
        email: Yup.string()
        .email()
        .required('Required'),

        password: Yup.string()
        .required()
        .min(8, 'Password should be minimum 8 characters'),
        
        phone: Yup.number().required().min(1111111111, "Invallid Phone Number")
        .max(9999999999, "Invalid Phone Number"),

      });
  
    const inputFields = [
      {
        name: "username",
        label: "User Name",
        type: "text",
        required: true,
      },
      {
        name: "email",
        label: "Email",
        type: "email",
        required: true,
      },
      {
        name: "phone",
        label: "Phone",
        type: "text",
        required: true,
      },
      {
        name: "password",
        label: "Password",
        type: "password",
        required: true,
      },
      {
        name: "role",
        label: "User Role",
        type: "select",
        className: "w-full col-span-2",
        options: roles?.map(role=> {return {label: role.name, value: role._id}}),
        required: true,
      },
      {
        name: "location",
        label: "Location",
        type: "select",
        className: "w-full col-span-2",
        options: locations?.map(role=> {return {label: role.name, value: role.name}}),
        required: true,
        isMulti: true,
      },
      {
        name: "department",
        label: "Department",
        type: "select",
        className: "w-full col-span-2",
        options: departments?.map(role=> {return {label: role.name, value: role.name}}),
        required: true,
      },
      {
        name: "team",
        label: "Team",
        type: "select",
        className: "w-full col-span-2",
        options: teams?.map(role=> {return {label: role.name, value: role.name}}),
        // required: true,
      },
      {
        name: "designation",
        label: "Designation",
        type: "text",
        // required: true,
      },
     
    ];
    return (
      <CustomAddPage
        pageTitle={data ? "Update User" : "Add User"}
        url={data ? "/auth/updateUser" : "/auth/createUser"}
        action={!data ? "create" : "post"}
        updateId={data?._id}
        queryKey="market"
        yupValidationSchema={yupValidationSchema}
        inputFields={inputFields}
        data={initialValues}
        setData={setInitialValues}
        handleClick={handleClick}
      />
    );
  }

export default Create