import { useDispatch } from "react-redux";
import { loginUser, setCurrentUser } from "../redux/user/user.action";
import { useFormik, Form, Field, FormikProvider } from "formik";
// import { Button, Input } from 'styled';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import InputWithIcon from "../common/InputWithIcon";
import Button from "../common/Button";
import { createDocument } from "../apis/customApiHandler";
// import store from './redux/store';
const UpdatePassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const formik = useFormik({
    initialValues: {
      email: props.email,
      password: "",
      confirmPassword: "",
      fromDashboard: true,
    },
    onSubmit: async (values) => {
      // console.log({values})
      setDisabled(true)
      try{
        const response = await createDocument("/auth/updatePassword", {email: props.email, password: values.password})
        if(response){
          props.setStep(step => step + 1)
        }
      }catch(err){
        console.log(err)
      }finally{
        setDisabled(false)
      }
    },

    validate: (values) => {
      let errors = {};
      if (!values.email) errors.email = "Required";
      if(values.confirmPassword!== values.password) errors.confirmPassword = "Confirm Password does not match"
      if (!values.password) errors.password = "Required";
      else if(values.password.length < 8) errors.password = "Password should be minimum 8 characters"
      if (!values.confirmPassword) errors.password = "Required";
      setDisabled(Object.keys(errors)?.length > 0)
      console.log({errors})
      return errors;
    },
  });

  // console.log("errors", formik.touched, formik.errors)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    if (user && Object.keys(user).length !== 0) {
      dispatch(setCurrentUser({ ...user, token: token }, navigate));
      navigate("/dashboard")
    }
  }, [isLoggedIn]);
  return (
    <div className="grid justify-center items-center h-screen bg-white">
      <FormikProvider value={formik} className="col-span-2 lg:col-span-1">
        <Form className="w-full relative rounded-md flex flex-col justify-center mx-auto items-center">
          {formik.touched.email ||
            (formik.touched.email && formik.errors && (
              <p className="bg-yellow-400 p-3 absolute w-full rounded-md text-center top-0 text-white">
                please enter the user id and pin
              </p>
            ))}
          <div className="p-2 flex flex-col w-full space-y-3 justify-center text-gray-800 h-full gap-y-4">
            <div>
              <h1 className="text-darkGrey text-2xl font-semibold text-left">
                Account Update Password
              </h1>
              <h4 className="text-md text-grey mt-1 w-2/3">
                Please Create a new Password for your account
              </h4>
            </div>
            <div className="flex flex-col justify-between text-xs items-center p-1 w-full h-full gap-y-1">
              <InputWithIcon
                formik={formik}
                key="password"
                type="password"
                label="Password"
                name="password"
                placeholder="Enter new password"
                variant="standard"
                setData={(e) => {
                  formik.setFieldValue("password", e);
                }}
                // Icon={<img src="./icons/lock.svg" />}
                className="w-80  border-b-2 border-main items-left text-[16px] w-full bg-white flex flex-col text-left"
                inputClass="text-[16px] rounded-[0px] h-12"
              />
            </div>
            <div className="flex flex-col justify-between text-xs items-center p-1 w-full h-full gap-y-1">
              <InputWithIcon
                formik={formik}
                key="confirmPassword"
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                placeholder="Confirm new password"
                variant="standard"
                setData={(e) => {
                  formik.setFieldValue("confirmPassword", e);
                }}
                // Icon={<img src="./icons/lock.svg" />}
                className="w-80  border-b-2 border-main items-left text-[16px] w-full bg-white flex flex-col text-left"
                inputClass="text-[16px] rounded-[0px] h-12"
              />
            </div>
            {/* <Button
              type="submit"
              onClick= {formik.onSubmit}
              className="bg-main text-white w-full items-center px-[40%] text-center rounded-[8px] mt-6"
            >UpdatePassword</Button> */}
            <div className="h-[40px]">
              <button
                type="submit"
                className={`${
                  disabled ? "bg-lightGrey" : "bg-main"
                } w-full text-white h-[40px] rounded-[8px]`}
                disabled={disabled}
              >
                {" "}
                Reset Password
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default UpdatePassword;
