import React, { useEffect, useMemo, useState } from 'react'
import CustomAddPage from '../../../custom-pages/CustomAddModal';
import { createDocument, getAllData } from '../../../../apis/customApiHandler';
import * as Yup from 'yup';
import { Category } from '@mui/icons-material';

const AddUsageDetail = ({ data, handleClick, parentFormik, handleSubmit,productCategory,totalSteps }) => {    
    const [initialValues, setInitialValues] = React.useState({
      ...data,
    });

    const [make, setVehicleMake] = useState([])
    const [model, setVehicleModel] = useState([])
    const [configuration, setVehicleConfiguration] = useState([])
    const [application, setApplication] = useState([])
    const [fitmentAxle, setFitmentAxle] = useState([])
    const [testingAxle, setTestingAxle] = useState([])
    const [tempData, setTempData]= useState({})
  
    const getFitmentAxle = async () => {
      const data = await getAllData("/master/vehicle/getFitmentAxle", {});
      setFitmentAxle(data?.data)
    }
    const getVehicleMake = async () => {
      // const data = await getAllData("/master/vehicle/getVehicleMake", {});
      const data = await getAllData("/master/vehicle/getVehicleMake", { category: productCategory });
      setVehicleMake(data?.data)
    }
    const getVehicleModel = async () => {
      const data = await getAllData("/master/vehicle/getVehicleModel", {makeName: tempData?.make});
      setVehicleModel(data?.data)
    }
    const getVehicleConfiguration = async () => {
      const data = await createDocument("/master/vehicle/getVehicleConfiguration", {modelName: tempData?.model, makeName: tempData?.make});
      setVehicleConfiguration(data?.data)
    }
    const getTestingAxle = async () => {
      const data = await getAllData("/master/test/getTestingAxle", {});
      setTestingAxle(data?.data)
    }
    const getApplication = async () => {
      const data = await getAllData("/master/other/getApplication", {});
      setApplication(data?.data)
    }
  
    useEffect(()=>{
      getFitmentAxle();
      getTestingAxle();
      // getVehicleMake();
      getApplication();

    },[])

    useEffect(() => {
    if (productCategory){
       getVehicleMake(productCategory);
    }
  }, [ productCategory]);

    useEffect(()=>{
      if(tempData?.make) getVehicleModel();
    }, [tempData?.make])

    useEffect(()=>{
      if(tempData?.model) getVehicleConfiguration();
    }, [tempData?.model])

    useMemo(()=>{
      setInitialValues({...data})
    },[data])

    const formSchema = Yup.object().shape({

      dualSpacing: Yup.number().min(0, "dualSpacing cannot be <0"),
    
    });
  
    const inputFields = [
      {
        name: "make",
        label: "Vehicle Make",
        type: "select",
        className: "w-[90%] col-span-3",
        options: make.map(option => { return { label: option.name, value: option.name } }),
      },
      {
        name: "model",
        label: "Vehicle Model",
        type: "select",
        className: "w-[90%] col-span-3",
        options: model?.map(option => { return { label: option.name , value: option.name } }),
      },
      {
        name: "configuration",
        label: "Vehicle Configuration",
        type: "select",
        className: "w-[90%] col-span-3",
        options: configuration?.map(option => { return { label: option.name, value: option.name } }),
      },
      {
        name: "application",
        label: "Application",
        type: "select",
        className: "w-[90%] col-span-3",
        options: application.map(option => { return { label: option.name, value: option.name } }),
      },
      {
        name: "fitmentAxle",
        label: "Fitment Axle",
        type: "select",
        className: "w-[90%] col-span-3",
        options: fitmentAxle.map(option => { return { label: option.name, value: option.name } }),
      },
      {
        name: "dualSpacing",
        label: "Dual Spacing",
        type: "Number",
        className: "w-[89%] col-span-3",
      },
      {
        name: "testingAxle",
        label: "Testing Axle",
        type: "select",
        className: "w-[90%] col-span-3",
        options: testingAxle.map(option => { return { label: option.name, value: option.name } }),
      },
    ]
    return (
      <CustomAddPage
        pageTitle={data ? "Update Vehicle Detail" : "Add Vehicle Detail"}
        action={!data ? "create" : "update"}
        updateId={data?._id}
        queryKey="permission"
        inputFields={inputFields}
        data={initialValues}
        setData={setTempData}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        totalSteps={totalSteps}
        yupValidationSchema={formSchema}
      />
    );
  };

export default AddUsageDetail