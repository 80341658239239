// import tw from 'twin.macro';

// const Input = tw.input`flex flex-row bg-white rounded-md py-2 px-3 text-gray-500 border border-[#D6D6D6] focus:outline-none focus:border-main mt-[6px]`;

// export default Input;

import tw from 'twin.macro';
import styled from 'styled-components';

const Input = styled.input(({ className }) => [
  tw`flex flex-row bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] focus:outline-none focus:border-none mt-[6px] justify-start mx-0`,
  className && tw`${className.toString()}`,
]);
export default Input;
