import React, { useState } from 'react'
import AddPage from "../../custom-pages/AddPage"
import * as Yup from 'yup';
const DocumentUpload = (props) => {
  // console.log("DOCUMENT", props.data)
  const formSchema = Yup.object().shape({
    documents: Yup.array()
      .required('Required'),
  });
  const [disabled, setDisabled] = useState(false)
  return (
    <div>
        <h1 className= "font-bold text-[#555555] mb-2 text-[20px]">DOCUMENT UPLOAD</h1>
        <div className= "p-4">
        <h1 className= "text-darkGrey font-semibold uppercase text-[16px] "> Drop Files to Upload</h1>
        <h4 className= "text-grey text-[14px]"> for multiple files upload</h4>
        <AddPage 
        inputs={[
          {
            label: "",
            name: "documents",
            type: "file",
            className: "w-[100vh]",
            multiple: true,
            accept: "image/png, image/jpeg, image/jpg, application/pdf"
          },
        ]}
        yupValidationSchema={formSchema}
        disabled={false}
        setDisabled={setDisabled} 
        {...props}
        handleSubmit={(values, index)=>{
          props.handleSubmit("documents", {...values}, props.index )
        }} />
        </div>
    </div>
  )
}

export default DocumentUpload