import React, { useEffect, useMemo, useState } from "react";
import UsageDetails from "../view/ProposedVehciles/ProposedVehicles";
import CustomAddPage from "../../custom-pages/CustomAddModal";
import {
  createDocument,
  getAllData,
  updateDocumentByID,
} from "../../../apis/customApiHandler";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from "yup";
import Button from "../../../common/Button";
import { TD, TH, TR, Tbody, Thead } from "../../../styled/table";
import InputField from "../../formik/InputField";
import { getFinalInputs } from "../../../utils/helpers";
import { Form, FormikProvider, useFormik } from "formik";
import SelectField from "../../formik/SelectField";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Add, Delete } from "@mui/icons-material";
import Input from "../../../styled/input";
import Entry from "./AddFitmentEntry";

const FitmentDetails = ({
  data,
  dataValue,
  handleSubmit,
  formik,
  handleNextClick,
  url,
  queryKey,
  requestNo,
  yupValidationSchema = {},
  updateId,
  ...props
}) => {
  const [resetDisabled, setResetDisabled] = useState([]);
  const [location, setLocation] = useState([]);
  const [configuration, setVehicleConfiguration] = useState([]);
  const [application, setApplication] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadSegment, setLoadSegment] = useState([]);
  const [testRequest, setTestRequest] = useState(null);
  const [fitmentAxle, setFitmentAxle] = useState([]);
  const[tyreDetailsValue,setTyreDetailsValue]=useState([]);

  const getTestRequest = async () => {
    // console.log("FETCHING SINGLE REQUEST");
    // const response = await getAllData("/test_request/draft", {createdBy: currentUser?._id});
    const response = await getAllData("/test_request", { requestNo });
    if (response.data?.length) {
      setTestRequest(response.data[0]);
      console.log('response11',formik.values.calculatedTyreDetails);
      fitmentFormik.setFieldValue("calculatedTyreDetails", [
        ...(formik.values.calculatedTyreDetails?.map((item) => {
          return { ...item };
        }) ?? []),
      ]);
    }
  };

  useEffect(() => {
    getTestRequest();
  }, [requestNo]);



  const onChange = (entryIndex, fieldName, value, tyreType) => {
    value = value === "" ? "0" : value; // Ensure value is a string, or use 0 if it's an empty string
    // console.log({value})

    // Create a shallow copy of fitmentDetails array
    const fitmentDetailsCopy = [...fitmentFormik.values.fitmentDetails];
    const fitmentDetails = { ...fitmentDetailsCopy[entryIndex] };

    // Create a shallow copy of tyreDetails array
    const tyreDetailsCopy = [...fitmentFormik.values.calculatedTyreDetails];
    console.log('tyreDetailsCopy11',tyreDetailsCopy[0]?.tyre);


    

    // Filter tyreDetails based on testTyre
    const matchingTyreDetail = tyreDetailsCopy.find(
      (entity) => entity.tyre.group === fitmentDetails[tyreType]
    );

    // Create a shallow copy of the matching tyreDetail
    const tyreDetail = matchingTyreDetail ? { ...matchingTyreDetail.tyre } : {};
    setTyreDetailsValue(tyreDetail);
    

    const deltaInInput =
      -parseInt(
        fitmentFormik.values.fitmentDetails[entryIndex][fieldName] ?? 0
      ) + parseInt(value) || 0;

    if (tyreDetail && fitmentDetails.category === "FT") {
      // tyreDetail.ftQuantity =
      //   (tyreDetail.ftQuantity ?? 0) - parseInt(deltaInInput);
      tyreDetail.ftPlanned =
        (tyreDetail.ftPlanned ?? 0) + parseInt(deltaInInput);
    } else if (tyreDetail && fitmentDetails.category === "PT") {
      // tyreDetail.ptQuantity =
      //   (tyreDetail.ptQuantity ?? 0) - parseInt(deltaInInput);
      tyreDetail.ptPlanned =
        (tyreDetail.ptPlanned ?? 0) + parseInt(deltaInInput);
    }
    // tyreDetail.noOfTyres = (tyreDetail.noOfTyres ?? 0) - parseInt(deltaInInput);

    // Update the tyreDetailsCopy with the modified tyreDetail
    if (matchingTyreDetail) {
      matchingTyreDetail.tyre = tyreDetail;
    }

    // Update the fitmentDetailsCopy with the new value
    fitmentDetailsCopy[entryIndex] = {
      ...fitmentDetailsCopy[entryIndex],
      [fieldName]: parseInt(value),
      tyreDetail
    };
    const totalQuantity =
      (fitmentDetailsCopy[entryIndex].testQuantity ?? 0) +
      (fitmentDetailsCopy[entryIndex].bmQuantity ?? 0);
    fitmentDetailsCopy[entryIndex] = {
      ...fitmentDetailsCopy[entryIndex],
      totalQuantity,
      vehicleQuantity:
      fitmentDetails.category === "PT"?0:
        fitmentDetailsCopy[entryIndex].fitmentAxle === "Single"
          ? totalQuantity / 2
          : fitmentDetailsCopy[entryIndex].fitmentAxle === "Dual" ?
          totalQuantity / 4:
          1,
    };

    fitmentFormik.setFieldValue("calculatedTyreDetails", tyreDetailsCopy);
    fitmentFormik.setFieldValue("fitmentDetails", fitmentDetailsCopy);
  };

  const onSelectChange = (entryIndex, fieldName) => (formik, value) => {

    if(formik.values.fitmentDetails && formik.values.fitmentDetails[entryIndex] && (fieldName === "bmTyre")){
      const oldTq = formik.values.fitmentDetails[entryIndex].testQuantity ?? 0, oldBq = formik.values.fitmentDetails[entryIndex].bmQuantity ?? 0, oldTotal= formik.values.fitmentDetails[entryIndex].totalQuantity ?? 0
      onChange(entryIndex, "bmQuantity", 0, "bmTyre" )
      formik.setFieldValue(`fitmentDetails[${entryIndex}].bmQuantity`, 0)
      formik.setFieldValue(`fitmentDetails[${entryIndex}].totalQuantity`, oldTotal  - oldBq)
      formik.setFieldValue(`fitmentDetails[${entryIndex}].vehicleQuantity`, 0)
    }
    else if(formik.values.fitmentDetails && formik.values.fitmentDetails[entryIndex] && (fieldName === "category" || fieldName === "testTyre")){
      const oldTq = formik.values.fitmentDetails[entryIndex].testQuantity ?? 0, oldBq = formik.values.fitmentDetails[entryIndex].bmQuantity ?? 0, oldTotal= formik.values.fitmentDetails[entryIndex].totalQuantity ?? 0
      onChange(entryIndex, "testQuantity", 0, "testTyre" )
      onChange(entryIndex, "bmQuantity", 0, "bmTyre" )
      formik.setFieldValue(`fitmentDetails[${entryIndex}].testQuantity`, 0)
      formik.setFieldValue(`fitmentDetails[${entryIndex}].bmQuantity`, 0)
      formik.setFieldValue(`fitmentDetails[${entryIndex}].totalQuantity`, oldTotal - oldTq - oldBq)
      formik.setFieldValue(`fitmentDetails[${entryIndex}].vehicleQuantity`, 0)
    }
    formik.setFieldValue(`fitmentDetails[${entryIndex}][${fieldName}]`, value.value)
    // if(formik.values.fitmentDetails[entryIndex]?.tyreType) onChange(entryIndex, fieldName, value, formik.valuesfitmentDetails[entryIndex]?.tyreType )
  }  
  
  const queryClient = useQueryClient();

  const formSchema = Yup.object().shape({
    fitmentPlan: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const { mutateAsync } = useMutation((data) =>
    props.action === "update"
      ? updateDocumentByID(url, updateId, data)
      : createDocument(url, data)
  );

  const [disabled, setDisabled] = useState(props.disabled);
  console.log('disabled33',disabled);
  

  const fitmentStruct = {
    category: "",
    location: "",
    testTyre: "",
    bmTyre: "",
    location: "",
    application: "",
    testQuantity: 0,
    ftQuantity: 0,
    ptQuantity: 0,
    ftPlanned: 0,
    ftPlanned: 0,
    segment: dataValue?.loadSegment,
  };

  useEffect(() => {
    // console.log({ data });
    if (data?.length) fitmentFormik.setFieldValue("fitmentDetails", data);
  }, [data]);  

  const inputRowStruct = [
    {
      name: "category",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "location",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "testMethod",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "fitmentAxle",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "testTyre",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "testQuantity",
      type: "number",
      tyreType: "testTyre",
      label: "",
      required: true,
      min: 0,
    },
    {
      name: "bmTyre",
      type: "select",
      label: "",
    },
    {
      name: "bmQuantity",
      type: "number",
      label: "",
      tyreType: "bmTyre",
    },
    {
      name: "totalQuantity",
      type: "number",
      label: "",
      disabled: true,
      required: true,
    },
    { name: "vehicleQuantity", type: "number", label: "", required: true , disabled: false},
    {
      name: "segment",
      type: "text",
      label: "",
      required: true,
    },
    {
      name: "application",
      type: "select",
      label: "",
      required: true,
    },
    {
      name: "configuration",
      type: "select",
      label: "",
      required: true,
    },
  ];

  console.log({dataValue})

  const fitmentFormik = useFormik({
    initialValues: {

      calculatedTyreDetails: [
        ...(testRequest?.tyreDetails?.map((item) => {
          return { ...item };
        }) ?? []),
      ],
      fitmentDetails: data ? data : [{ ...fitmentStruct,segment: dataValue.loadSegment,vehicleQuantity:0  }]
    },
    onSubmit: async (values) => {
      const finalInputs = getFinalInputs(inputRowStruct, values);
      if (url)
        await mutateAsync(finalInputs, {
          onSuccess: (data) => {
            queryClient.invalidateQueries(queryKey);
            if (data && data.message) {
              toast.success(data.message);
            }
          },
          onError: (err) => {
            toast.error(
              err.response.data.error ||
                err.response.data.message ||
                err.message ||
                "Something went wrong"
            );
          },
        });
      else {
        handleSubmit(finalInputs);
        handleNextClick();
      }
    },
    validationSchema: formSchema || undefined,

    validate: (values) => {
      let errors = {
        calculatedTyreDetails: values.calculatedTyreDetails.map((entry) => {
          return {};
        }),
        fitmentDetails: values.fitmentDetails.map((entry) => {
          return {};
        }),
      };
      let hasErrors = false;
      let hasFtQuantityLess=false;
      let hasPtQuantityLess=false;
      
      values.calculatedTyreDetails.map((detail, index) => {     
           
        if (detail.tyre?.ftQuantity < 0) {
          errors["calculatedTyreDetails"][index].ftQuantity =
            "Cannot be Negative!";
          hasErrors = true;
        }
        
        if (detail.tyre?.ptQuantity < 0) {
          errors["calculatedTyreDetails"][index].ptQuantity =
            "Cannot be Negative!";
          hasErrors = true;
        }

        if (detail.tyre?.ptQuantity && !detail.tyre?.ptPlanned) {
          errors.prompt = "Please complete all Groups!";
          hasErrors = true;
        }

        if (detail.tyre?.ftQuantity &&  !detail.tyre?.ftPlanned) {
          errors.prompt = "Please complete all Groups!";
          hasErrors = true;
        }
        
      });


      values.fitmentDetails.map((detail, index) => {

        errors["fitmentDetails"][index] = errors["fitmentDetails"][index] || {};

        console.log('runningOne--',detail?.tyreDetail?.ftQuantity,'planned',detail?.tyreDetail?.ftPlanned,'tyre detail',detail?.tyreDetail);

        if (detail?.tyreDetail?.ftQuantity && detail?.tyreDetail?.ftQuantity > detail?.tyreDetail?.ftPlanned) {   
            hasFtQuantityLess = true;
        } else if(detail?.tyreDetail?.ftQuantity && detail?.tyreDetail?.ftQuantity === detail?.tyreDetail?.ftPlanned) {   
            hasFtQuantityLess = false;
        }

        if (detail?.tyreDetail?.ptQuantity && detail?.tyreDetail?.ptQuantity > detail?.tyreDetail?.ptPlanned) {   
            hasPtQuantityLess = true;
        } else if(detail?.tyreDetail?.ptQuantity && detail?.tyreDetail?.ptQuantity === detail?.tyreDetail?.ptPlanned) {   
            hasPtQuantityLess = false;
        }

        if (detail?.tyreDetail?.ftQuantity < detail?.tyreDetail?.ftPlanned && detail?.tyreDetail?.testTyre!=="Benchmark") {          
            errors["fitmentDetails"][index].testQuantity = "FT limit exceeded";
            hasErrors = true;
        }

        if (detail?.tyreDetail?.ftQuantity < detail?.tyreDetail?.ftPlanned && detail?.tyreDetail?.testTyre==="Benchmark") {          
            errors["fitmentDetails"][index].bmQuantity = "FT limit exceeded";
            hasErrors = true;
        }


        if (detail?.tyreDetail?.ptQuantity < detail?.tyreDetail?.ptPlanned && detail?.tyreDetail?.testTyre!=="Benchmark") {
            errors["fitmentDetails"][index].testQuantity = "PT limit exceeded";
            hasErrors = true;
        }

        if (detail?.tyreDetail?.ptQuantity < detail?.tyreDetail?.ptPlanned && detail?.tyreDetail?.testTyre==="Benchmark") {
            errors["fitmentDetails"][index].bmQuantity = "PT limit exceeded";
            hasErrors = true;
        }

        if (detail.category === "FT" && detail.vehicleQuantity <= 0) {
          errors.fitmentDetails[index].vehicleQuantity = "Vehicle Quantity for FT must be more than 0!";
          hasErrors = true;
        }
        
        if(detail?.testQuantity < 0){
          errors["fitmentDetails"][index].testQuantity =
            "Should be > 0";
          hasErrors = true;
        }
        if(detail?.bmQuantity <0){
          errors["fitmentDetails"][index].bmQuantity =
            "Should be > 0";
          hasErrors = true;
        }
        if (detail?.testMethod === "Head On") {
          if(!detail?.testQuantity){
            errors["fitmentDetails"][index].testQuantity =
              "Please select test Quantity";
            hasErrors = true;
          }

          if(!detail?.testTyre){
            errors["fitmentDetails"][index].testTyre =
              "Please select test Tyre";
            hasErrors = true;
          }


          if (!detail?.bmTyre) {
            errors["fitmentDetails"][index].bmTyre =
              "Please select benchmark tyre";
            hasErrors = true;
          }
          if(!detail?.bmQuantity){
              errors["fitmentDetails"][index].bmQuantity =
                "Please add benchmark quantity";
              hasErrors = true;
          }
        }

        if(!detail?.configuration){
          errors["fitmentDetails"][index].configuration =
            "Please select configuration";
          hasErrors = true;
        }
        if(!detail?.application){
          errors["fitmentDetails"][index].application =
            "Please select application";
          hasErrors = true;
        }
        if(!detail?.segment){
          errors["fitmentDetails"][index].segment =
            "Please select segment";
          hasErrors = true;
        }
        if(!detail?.location){
          errors["fitmentDetails"][index].location =
            "Please select location";
          hasErrors = true;
        }
        if(!detail?.category){
          errors["fitmentDetails"][index].category =
            "Please select category";
          hasErrors = true;
        }
        if ((detail?.bmTyre && !detail.bmQuantity)) {
          errors["fitmentDetails"][index].bmQuantity =
            "Please add benchmark quantity";
          hasErrors = true;
        }else if(!detail?.bmTyre && detail.bmQuantity){
          errors["fitmentDetails"][index].bmTyre =
          "Please add benchmark tyre";
          hasErrors = true;
        }

        if ((detail?.testTyre && !detail.testQuantity)) {
          errors["fitmentDetails"][index].testQuantity =
            "Please add test quantity";
          hasErrors = true;
        }
        else if(!detail?.testTyre && detail.testQuantity){
          errors["fitmentDetails"][index].testTyre =
          "Please add test tyre";
          hasErrors = true;
        }

        if (detail?.vehicleQuantity?.toString().split(".")[1]) {
          errors["fitmentDetails"][index].vehicleQuantity =
            "Should be an integer";
            hasErrors = true;
        }

      })

      setDisabled(props.expanded === props.totalSteps || hasErrors || hasFtQuantityLess || hasPtQuantityLess);
      setResetDisabled(
        !fitmentFormik.values.length &&
          !Object.keys(fitmentFormik.touched)?.length
      );

      return errors;
    },
  });
  
  return (
    <div className="w-full flex flex-col relative">
      <FormikProvider value={fitmentFormik}>
        <Form>
          {/* <div className="heading text-darkGrey">Vehicle Details</div> */}
          <div className="overflow-x-scroll mb-1 relative">
            <table className="w-full">
              <Thead className="flex flex-row justify-between">
                <TH> Category <span className= "p-0 text-red-500">*</span></TH>
                <TH> Location <span className= "p-0 text-red-500">*</span></TH>
                <TH> Test Method <span className= "p-0 text-red-500">*</span></TH>
                <TH> Axle <span className= "p-0 text-red-500">*</span></TH>
                <TH> Test Tyre <span className= "p-0 text-red-500">*</span></TH>
                <TH> Test Quantity <span className= "p-0 text-red-500">*</span></TH>
                <TH> BM Tyre </TH>
                <TH> BM Quantity </TH>
                <TH> Total Quantity <span className= "p-0 text-red-500">*</span></TH>
                <TH> Vehicle Quantity <span className= "p-0 text-red-500">*</span></TH>
                <TH> Segment <span className= "p-0 text-red-500">*</span></TH>
                <TH> Application <span className= "p-0 text-red-500">*</span></TH>
                <TH> Configuration <span className= "p-0 text-red-500">*</span></TH>
                <TH> Action</TH>
              </Thead>
              <Tbody className="">
                {loading ? (
                  fitmentFormik?.values?.fitmentDetails?.map(
                    (entry, entryIndex) => {
                      return (
                        <Entry
                          entry={entry}
                          entryIndex={entryIndex}
                          onChange={onChange}
                          onSelectChange={onSelectChange}
                          fitmentFormik={fitmentFormik}
                          tyreDetails={testRequest?.tyreDetails}
                          dataValue={dataValue}
                        />
                      );
                    }
                  )
                ) : (
                  <div className="w-full text-semibold text-center text-lightGrey p-3">
                    {" "}
                    No Tyre Details Added{" "}
                  </div>
                )}
              </Tbody>
            </table>
          </div>
          <div className="w-full">
            <Button
              className="bg-main text-white w-36 text-center justify-center gap-1 float-right mb-5"
              Icon={<Add />}
              text="Add New"
              onClick={(e) => {
                e.preventDefault();
                fitmentFormik.setFieldValue("fitmentDetails", [
                  ...(fitmentFormik.values.fitmentDetails ?? []),
                  { ...fitmentStruct },
                ]);
              }}
            ></Button>
          </div>
          <div className="heading text-darkGrey mt-5 ">Tyre Details</div>

          <div className="overflow-x-scroll w-full">
            <table className="w-full">
              <Thead className="flex flex-row justify-between">
                <TH> Test Type for</TH>
                <TH> Group</TH>
                <TH> Group Description</TH>
                <TH> Tyre Size Desc</TH>
                <TH> Stencil From</TH>
                <TH> Stencil To</TH>
                <TH> No of Tyres</TH>
                <TH> FT Quantity</TH>
                <TH> PT Quantity</TH>
                <TH> FT Planned</TH>
                <TH> PT Planned</TH>
              </Thead>
              <Tbody>
                {fitmentFormik?.values?.calculatedTyreDetails?.length ? (
                  fitmentFormik.values.calculatedTyreDetails.map(
                    (entry, index) => {                      
                      if (!entry.remarks)
                        return (
                          <TR key={entry?._id}>
                            <TD> {entry.tyre?.testTyre} </TD>
                            <TD> {entry.tyre?.group} </TD>
                            <TD> {entry.tyre?.groupDescription} </TD>
                            <TD> {entry.tyre?.tyreSizedescription} </TD>
                            <TD> {entry.tyre?.stencilFrom} </TD>
                            <TD> {entry.tyre?.stencilTo} </TD>
                            <TD> {entry.tyre?.noOfTyres} </TD>
                            <TD>
                              {" "}
                              {entry.tyre?.ftQuantity}
                              <div className="text-red-400 text-sm">
                                {fitmentFormik.errors?.calculatedTyreDetails &&
                                  fitmentFormik.errors?.calculatedTyreDetails[
                                    index
                                  ]?.ftQuantity}
                              </div>
                            </TD>
                            <TD>
                              {" "}
                              {entry.tyre?.ptQuantity}
                              <div className="text-red-400 text-sm">
                                {fitmentFormik.errors?.calculatedTyreDetails &&
                                  fitmentFormik.errors?.calculatedTyreDetails[
                                    index
                                  ]?.ptQuantity}
                              </div>
                            </TD>
                            <TD> {entry.tyre?.ftPlanned} </TD>
                            <TD> {entry.tyre?.ptPlanned} </TD>
                          </TR>
                        );
                    }
                  )
                ) : (
                  <div className="w-full text-semibold text-center text-lightGrey p-3">
                    {" "}
                    No Tyre Details Added{" "}
                  </div>
                )}
              </Tbody>
            </table>
          </div>
          {props.expanded === props.totalSteps ? (
            <></>
          ) : (
            <div className="flex flex-col gap-3 mt-6 text-md w-24">
              <div className="text-red-400 text-sm">
                {fitmentFormik.errors?.prompt}
              </div>
              <Button
                type="button"
                disabled={disabled}
                className="bg-main text-white text-center items-center justify-center h-10 w-full"
                onClick={() => {
                  let dst = [];
                  let locations = {},
                    groups = new Set();

                  fitmentFormik.values.fitmentDetails?.map((detail, ind1) => {
                    if (!locations[detail.location])
                      locations[detail.location] = new Set();
                    if (detail.testTyre)
                      locations[detail.location].add(detail.testTyre); // add groups to that location
                    if (detail.bmTyre)
                      locations[detail.location].add(detail.bmTyre);
                  });

                  Object.keys(locations).forEach((location) => {
                    let findObj = dst.find((detail) => {
                      return detail.location === location;
                    });
                    if (!findObj) {
                      findObj = {};
                      findObj["location"] = location;
                      findObj["values"] = [];
                    }
                    locations[location].forEach((group) => {
                      const matchingTyreDetail = testRequest?.tyreDetails?.find(
                        ({ tyre }) => tyre.group === group
                      );
                      const object = {
                        group: group,
                        tyreSizedescription:
                          matchingTyreDetail?.tyre?.tyreSizedescription,
                        groupDescription:
                          matchingTyreDetail?.tyre?.groupDescription,
                        // ftDiscount: formik.values.discountDetails && formik.values.discountDetails.find(),
                        // ptDiscount: formik.values.discountDetails && formik.values.discountDetails[ind1+ind2]?.ptDiscount,
                      };
                      findObj["values"].push(object);
                    });

                    dst = [
                      ...dst.filter((detail) => {
                        return detail.location !== location;
                      }),
                      {
                        location: findObj["location"],
                        values: [...findObj["values"]],
                      },
                    ];
                  });
                  // console.log({ locations, groups });
                  // console.log({ dst });
                  handleNextClick(props.index);
                  formik.setFieldValue(
                    "fitmentDetails",
                    fitmentFormik.values.fitmentDetails?.map((item, index)=>{
                      return {
                        methodNo: String(index + 1),
                        ...item
                      }
                    })
                  );
                  formik.setFieldValue(
                    "calculatedTyreDetails",
                    fitmentFormik.values.calculatedTyreDetails
                  );
                  formik.setFieldValue("discountDetails", [...dst]);
                }}
              >
                Next
              </Button>
            </div>
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};


export default FitmentDetails;

