import { logOutUser } from '../redux/user/user.action';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputWithIcon from '../common/InputWithIcon';
import DropDown from '../common/DropDown';
import { KeyboardArrowDown } from '@mui/icons-material';

const AppBar = ({ currentUser, ...otherProps }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.user?.notifications);

  const list = [
    { name: "LogOut",
      onClick: async () => {
        localStorage.clear();
        window.location.href= "/"
      },
      className: "text-danger w-24"}
  ]
  return (
    <div
      className="w-full  h-12  bg-white  flex flex-row items-center justify-end border-b-1 border-gray-100 bottom-shadow gap-4"
      {...otherProps}
    >
      <a
         href="/notifications"
          className="inter text-red-500 rounded-lg px-2  hover:shadow-sm underline font-semibold text-sm relative"
        >
          <img src="/icons/bell.svg"/>
          <span className= "text-white bg-red-500 absolute -top-1 right-0 text-xs rounded-full px-1 h-2">
            {/* {notifications?.filter(n=> n.status!== "Read")?.length} */}
          </span>
        </a>
      <div className="mr-7 flex gap-2">
        <span>
          <img className="rounded-full h-10 w-10" src= "/icons/5270.webp"></img>
        </span>
        <span className='text-dark-gray text-center items-center p-1'>
          {currentUser?.username}
        </span>         
        <div className= "text-center items-center">
        <InputWithIcon
                variant="outlined"
                className="w-9"
                Icon={<DropDown  list={list} />}
                inputClass="hidden"
              />
      </div>
      </div>
    </div>
  );
};

export default AppBar;