import React, { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import FileInput from "./FileInput";
import Input from "../../styled/input.js";
import TextArea from "../../styled/textarea";
import axios from "../../apis/axios.js";
import toast from "react-hot-toast";

const InputField = ({ label, disabled, required, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [files, setFiles] = useState(props.type === "file" ? field.value ?? []: []);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(()=>{
    const uploadCopy = {}
    if(files?.length){
      files.map((file, index)=>{
        uploadCopy[index] = {percentage: 100, name: file}
      })
      setUploadProgress(uploadCopy)
    }
  }, [files])

  const handleFileChange = async (e) => {
    try {
      console.log(e.target, e.dataTransfer);
      let filesData = e.target.files
        ? e.target.files
        : e.dataTransfer.files
        ? e.dataTransfer.files
        : null;
      helpers.setTouched(true);
      console.log({ filesData });
      let tempFiles = files;
      for await (let file of filesData) {
        if (file) {
          let formData = new FormData();
          const currentLength = tempFiles?.length;
          formData.append("file", file);
          const _id = `file_${currentLength}`;
          let bar = document.getElementById(_id);
          let config = {
            onUploadProgress: (progressEvent) => {
              const percentage = (progressEvent.loaded / progressEvent.total) * 100;
              bar?.setAttribute("value", percentage);
              setUploadProgress({
                ...uploadProgress,
                [currentLength]: { percentage, name: file.name },
              });
            },
          };
          let response = await axios.post(
            "/utility/uploadFile",
            formData,
            config
          );
          if (response?.data) {
            let url = response.data.url;
            console.log(url);
            tempFiles.push(url);
            console.log("IMAGE UPLOADED", props.formik.values[field.name]);
            setFiles([...tempFiles]);
            const data = { ...uploadProgress };
            if (data) delete data[currentLength];
            setUploadProgress(data);
          }
        }
        props.formik.setFieldValue(field.name, [...tempFiles]);
      }
      // console.log({uploadProgress})
      // console.log({tempFiles})

    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const deleteFile = (index) => {
    const data = { ...uploadProgress };
    if (data) delete data[index];
    setUploadProgress(data);
    setFiles(files.filter((_, i) => i !== index));
  };

  // console.log(field.name, field.disabled, ` ${
  //   props.type !== "checkbox" && props.className
  // } ${props.type === "checkbox" ? "w-6" : "w-full"} ${disabled ? "bg-slate-200 border border-2 border-red-200" : ""}`)
  return (
    <div
      className={`flex flex-col ${
        props.halfCol ? "col-span-1" : "col-span-2"
      } justify-start items-start gap-0 text-gray-600 font-normal w-full ${
        props.className
      } `}
    >
      {props.type !== "file" ? (
        <label
          className={`py-1 text-grey text-sm ${props.inputClass} ${props.halfCol ? "col-span-1" : "col-span-2"} w-full`}
          style={{ color: "#757575" }}
        >
          {label}
          {label && required && (
            <span className="text-red-500 required-dot p-0 m-0">*</span>
          )}
          {props.type === "textarea" ? (
            <textarea
              {...field}
              {...props}
              placeholder={`Enter ${label ?? "value"}`}
              value={props.formik?.values[field.name] || ""}
              className={`${disabled ? "bg-gray-500" : ""} ${
                props.type !== "checkbox" && props.className
              } flex flex-row bg-white rounded-md py-2 px-3 text-gray-500 text-gray-500 border border-[#D6D6D6] w-full  mt-[6px] justify-start mx-0 text-grey`}
              disabled={disabled}
            />
          ) : (
            <Input
              {...field}
              {...props}
              value={props.value ? props.value : field.value ? field.value : (props.formik?.values[field.name] || "")}
              onChange  = {(e)=>{
                // props.formik.setTouched(field.name, true)
                helpers.setTouched(true)
                console.log("onchange", props.eChange);
                if(props.eChange) props.eChange(e);
                if(props.onChange) props.onChange(props.formik, e.target.value);
                if(props.type === "checkbox"){
                  console.log("here")
                  helpers.setValue(e.target.checked)
                }
                else props.formik.handleChange(e)
              }}
              placeholder={`Enter ${label ?? "value"}`}
              className={` ${
                props.type !== "checkbox" && props.className
              } ${props.type === "checkbox" ? "w-6" : "w-full"} ${disabled ? "bg-red-200 border border-2 border-red-200" : ""}`}
              style={{
                ...disabled && {background: "#C7C9D91F"},
                ...disabled && {color: "#8F90A6"}
              }}
              disabled={disabled}
            />
          )}
        </label>
      ) : (
        <label className={`py-1 text-sm `}>
          {label}
          {required && <span className="text-red-500 required-dot">*</span>}
          <FileInput
            placeholder="Select file"
            onChange={handleFileChange}
            {...props}
            deleteFile={deleteFile}
            className={`${disabled ? "" : ""} ${props.className}`}
            disabled={disabled}
            files={files}
            uploadProgress={uploadProgress}
            setUploadProgress={setUploadProgress}
          />
        </label>
      )}

      {meta.touched && meta.error ? (
        <p className="text-red-500 font-normal">*{meta.error}</p>
      ) : null}
    </div>
  );
};

export default InputField;
