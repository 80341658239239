import React, { useState, useEffect } from "react";
import InputWithIcon from "../../common/InputWithIcon";
import Button from "../../common/Button";
import CustomAddPage from "../custom-pages/CustomAddModal";
import { getAllData } from "../../apis/customApiHandler";
import { Formik } from "formik";
import * as Yup from 'yup';

function Create({ data, handleClick }) {
  const [initialValues, setInitialValues] = useState({
    name: data?.name || "",
    allowedPermissions: data?.allowedPermissions || "",
  });
  const [updatedData, setUpdatedData] = useState({
    name: data?.name || "",
    allowedPermissions: data?.allowedPermissions || "",
  })
  const [permissions, setPermissions] = useState([]);
  const [inputFields, setInputFields] = useState();
  const getPermissions = async () => {
    const data = await getAllData("/auth/getPermissions");
    setPermissions(data?.data);
  };

  let object = {};
  useEffect(
    () => {
      data?.allowed_permissions.map((allowed_permission) => {
        if(allowed_permission?.permission?._id) object[allowed_permission.permission._id] =
          allowed_permission.subPermissions;
      });
      console.log({object})
      setUpdatedData({ ...initialValues, permissions: object });
    },
    [data]
  );

  useEffect(() => {
    getPermissions();
  }, []);
  

  useEffect(() => {
    const inputs = [
      {
        name: "name",
        label: "Role Name",
        type: "text",
        required: true,
      },
    ];
    if (permissions?.length) {
      permissions.map((permission) => {
        inputs.push({
          name: permission._id,
          label: permission.name,
          parentFieldName: "permissions",
          type: "parentCheckbox",
          className: "flex flex-row-reverse justify-end w-48 gap-2",
          child: [
            {
              name: "module_admin",
              label: "Module Admin",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "create",
              label: "Create",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "read",
              label: "Read",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "update",
              label: "Update",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "delete",
              label: "Delete",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "approve",
              label: "Approve",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "reject",
              label: "Reject",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "accept",
              label: "Accept",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
            {
              name: "ask_for_modification",
              label: "Ask For Modification",
              type: "checkbox",
              className:
                "flex flex-row-reverse col-span-1 justify-end w-full gap-2",
            },
          ],
        });
      });
      setInputFields(inputs);
    }
  }, [permissions]);


  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    permissions: Yup.object().shape({}).noUnknown()
  });


  return (
    <CustomAddPage
      pageTitle={data ? "Update Role" : "Add Role"}
      url={data ? "/auth/updateRole" : "/auth/createRole"}
      action={!data ? "create" : "update"}
      updateId={data?._id}
      queryKey="permission"
      inputFields={inputFields}
      data={initialValues}
      updatedData={updatedData}
      setData={setInitialValues}
      handleClick={handleClick}
      yupValidationSchema={formSchema}
    />
  );
}

export default Create;
