import React, { useState, useMemo } from "react";
import Button from "../../../../common/Button";
import { Add, MoreVert } from "@mui/icons-material";
import { TD, TH, TR, Tbody, Thead } from "../../../../styled/table";
import AddTyreDetail from "./ViewTyreDetail";
import ViewTyreDetail from "./ViewTyreDetail";
import Input from "../../../../styled/input";

function TyreDetails({
  data,
  formik,
  handleSubmit,
  ...props
}) {
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(!data?.length);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const currentRemarksLength = props.remarksTimeline?.length ? props.remarksTimeline : 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))



  const inputsStep2 = [
    {
      label: "Load Segment",
      name: "loadSegment",
      type: "select",
      required: true,
    },
    {
      label: "Fitment Rim(Rec)",
      name: "fitmentRim",
      type: "number",
      required: true,
    },
    {
      label: "PSI Recommendation",
      name: "psiRec",
      type: "number",
      required: true,
    },
    {
      label: "Fitment Rim(Alt)",
      name: "fitmentRimAlt",
      type: "number",
    },
  ];


  return (
    <div className="flex flex-col relative w-full col-span-2 mb-4">
      <div className="grid grid-cols-2 sm:w-[100%] md:w-[90%]">
        {inputsStep2.map((row) => {
          return (
            <div className="col-span-1 my-3">
              <div className="flex flex-col">
                <div className="text-[#757575] inter text-[12px] font-semibold">
                  {row.label}
                  {row.required && (
                    <span className="text-red-500 required-dot">*</span>
                  )}
                </div>
                <div className="text-main inter font-semibold text-[16px]">
                  {data && data[row.name]}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="overflow-x-scroll">
          <table className="w-full">
            <Thead className="flex flex-row justify-between">
              <TH> Test Tyre</TH>
              <TH> No of Tyres</TH>
              <TH> Tyre Size Desc</TH>
              <TH> Group</TH>
              <TH> Action</TH>
            </Thead>
            <Tbody>
            {data?.tyres?.length ? data.tyres.map((entry, index) => {
              // console.log({entry})
              if(!entry.remarks) return (
                <TR className="">
                  <TD> {entry.tyre?.testTyre} </TD>
                  <TD> {entry.tyre?.noOfTyres} </TD>
                  <TD> {entry.tyre?.tyreSizedescription} </TD>
                  <TD> {entry.tyre?.group} </TD>
                  <TD className="text-center">
                    {" "}
                    <Button
                      onClick={() => {
                        setShowModal(!showModal);
                        setSelectedEntry(entry);
                        setSelectedIndex(index);
                      }}
                      className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                      text="View All (31 Fields)"
                    ></Button>
                  </TD>
                </TR>
              );
            }): <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Tyre Details Added </div>}
            </Tbody>
          </table>
      </div>
      <div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}
      {showModal && (
        <ViewTyreDetail
          title="View Tyre Details"
          parentFormik={formik}
          data={selectedEntry?.tyre}
          handleSubmit={(values) => {
            setDisabled(false);
            let currentVals = data
              ? [...data]
              : [];
            if (selectedIndex !== null) {
              currentVals[selectedIndex] = {tyre: values};
            }
            if (selectedIndex == null)
              formik.setFieldValue("tyreDetails", [...currentVals, {tyre: values}]);
            else formik.setFieldValue("tyreDetails", [...currentVals]);
            
            setSelectedEntry(null);
            setSelectedIndex(null);
    
          }}
          handleClick={() => {
            setShowModal(!showModal);
            setSelectedEntry(null);
            setSelectedIndex(null);
          }}
          totalSteps={props.totalSteps}
        />
      )}
    </div>
  );
}



export default TyreDetails;
