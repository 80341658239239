import React, { useState } from 'react'
import AddPage from "../../custom-pages/AddPage"
import ViewFileArea from "../../custom-pages/ViewFileArea"
import * as Yup from 'yup';
import Input from '../../../styled/input';
const DocumentUpload = ({data, formik, handleSubmit, ...props}) => {
  // console.log("DOCUMENT", data)
  const formSchema = Yup.object().shape({
    documents: Yup.array()
      .required('Required'),
  });
  const [disabled, setDisabled] = useState(!data?.length)
  const currentRemarksLength = props.remarksTimeline?.length ? props.remarksTimeline : 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))

  return (
    <div>
        <h1 className= "font-bold text-[#555555] mb-2 text-[20px]">DOCUMENTS</h1>
        <div className= "p-4">
        <ViewFileArea 
        files={data}
        {...props}
        handleSubmit={(values, index)=>{
          console.log({values, index})
          props.handleSubmit("documents", {...values}, props.index )
          // props.handleSubmit("documents",  {documents: props.formik.values.documents}, props.index)
        }} />
        </div>
       <div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks1"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}
    </div>
  )
}

export default DocumentUpload