// import { Modal } from "@mui/base";
import React, { useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal");

function BasicModal({ title, subtitle, showSubTitle=true, handleClick, ...props }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [on, seton] = React.useState(true);
  const handleon = () => seton(true);
  const handleoff = () => { seton(false); handleClick(); }
  const open = Boolean(anchorEl);
  const handleEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, id = "#") => {
    if (id !== undefined && id !== 'backdropClick') window.location.href = id;
    setAnchorEl(null);
    handleoff();
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    borderRadius: "10px",
    background: "white",
    maxHeight: "90vh",
    height: "max-content",
    overflowY: "auto",
    maxWidth: "75vw",
    width: "100%",
  }

  return (
    <div>
      <Modal
        open={on}
        onClose={handleoff}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm:w-full md:w-3/5 px-5 py-2">
          <p
            className={`p-0 w-full flex relative mb-4`}
          >
            <div className="flex flex-col mt-2 rounded-t-lg text-center text-gray-700 justify-center mx-auto ">
              <div className=" uppercase text-lg font-medium ">
                {title}
              </div>
              {showSubTitle && <div className="text-gray-500">
                {subtitle ?? "Press Tab to go to the next field & Shift Tab for previous field"}
              </div>}
              {showSubTitle && <div className="text-gray-500 text-sm italic">
                <span className="text-red-500">*</span>{"Marks All the Required Fields"}
              </div>}
            </div>
            <span className="justify-end items-end absolute right-2 top-2">
              <button
                className=" font-bold hover:shadow-lg rounded-circle text-black"
                onClick={handleClick}
              >
                <img src="./icons/circleCross.svg" />
              </button>
            </span>
          </p>
          {props.children}
        </Box>
      </Modal></div>
  )
}

export default BasicModal
