import { CropSquareSharp } from "@mui/icons-material";
import {fetchFile} from "../../utils/helpers"
import React, { useState } from "react";

function FileInput(props) {
  console.log({props})
  const [isDragActive, setIsDragActive] = useState(false);
  const [files, setFiles] = useState([])

  const handleDragEnter = (e) => {
    e.preventDefault();
    // setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    // setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);

    props.onChange(e)
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className={`${props.className}`}
      >
      <div class={`flex items-center justify-center w-full`}
      >
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer hover:bg-light3"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
            >
          {!props.files?.length ? (
            <>
              <div class="flex flex-row items-center justify-center gap-2 text-lg">
                <img src="./icons/download.svg" />
                <div class="mb-2 text-[#999999] font-medium h-5">
                  Drop files here to upload
                </div>
              </div>
              <p class="text-md font-meidum text-lightGrey">
            {/* Upload (PDF, PNG and JPG) */}
          </p>
            </>
          ) : (
            <></>
          )}
          <div className="flex flex-row w-full px-5 justify-start gap-4 mt-2">
            {Object.keys(props.uploadProgress)?.map((key, index) => {
              console.log("val", props.name, props.files)
              const id= `file_${index}`
              // console.log(id)
              console.log(props.uploadProgress[index]?.percentage)
              const isImage = 
              !props.files[index]?.remarks && props.files[index]?.split(".")?.length && typeof props.files[index]!== "object" && ["jpeg","png", "svg", "jpg"].includes(props.files[index]?.split(".")[props.files[index]?.split(".").length - 1])
              return !props.files[index]?.remarks && (
                <div className= "h-[130px] w-[130px] relative  bg-light3">
                  <div className= "relative h-[130px] w-[130px]">
                    <div className= "absolute right-1 top-1">
                      <button
                      onClick={(e)=>{
                        e.preventDefault()
                        // console.log(props?.files)
                      props.deleteFile(index)
                      }}>
                        <img src="./icons/circleCross.svg" className="h-[16px] w-[16px]"
                      />
                      </button>
                    </div>
                    <img
                      src={isImage ? props.files[index] : "./icons/file.svg"}
                      className="bg-light3 justify-center items-center h-[130px] w-[130px]  object-cover px-auto rounded-md shadow-xs"
                    ></img>
                    <div className="absolute mx-5 mt-[-90px] font-medium text-center justify-center max-w-[100px] break-words">
                    {props.uploadProgress[index]?.percentage !== 100 ?<progress
                    id={id}
                    value={props.uploadProgress[index]?.percentage}
                    max= "100"
                    className="rounded-lg bg-darkGrey w-[60px] h-[10px] "
                    style={{ borderRadius: "40px" }}
                  >                    
                  </progress>: 
                  <div className= "flex text-center justify-center rounded-full bg-gray-200 border border-2 w-10 h-10 mx-auto">
                    <button
                      onClick={(e)=> {
                        e.preventDefault();
                        fetchFile(props.files[index])
                      }}
                    >
                  <img src= "/icons/download_arrow.svg"  alt="Check free icon" title="Check free icon" className= "text-center justify-center mx-auto   "></img>
                  </button></div>}
                  <div className= "text-darkGrey font-medium">
                    { props.uploadProgress && (props.uploadProgress[index]?.name?.split("/")?.slice(-1)[0])}
                    </div>
                  </div>
                  </div>
                  
                </div>
              );
            })}
                      {props.files?.length && (props.limit ? props.files?.length < props.limit: true) ? <img src="/icons/add.svg"></img> : <></>}
          </div>

          <input id="dropzone-file" type="file"  {...props} disabled={!(props.limit ? props.files?.length < props.limit: true)} className="hidden" />
        </label>
      </div>
    </div>

    // <div
    //   className={`dropzone ${isDragActive ? 'active' : ''}`}
    // >
    //   <input type="file" id="fileInput" multiple onChange={()=>{}} />
    //   <p>Drag & drop files here, or click to select files</p>
    // </div>
  );
}

export default FileInput;
