import Login from "./Login";
import Dashboard from "./Dashboard";
import TestRequest from "./TestRequests"
import TestPlan from "./TestPlan"
import Permissions from "./Permissions";
import Roles from "./Roles";
import NotFoundPage from "./NotFountPage"
import Markets from "./masters/Market";
import ProductCategory from "./masters/ProductCategory";
import ProductType from "./masters/productType";
import Methodology from "./masters/Methodology";
import TestRequirement from "./masters/TestRequirement";
import DirectTyreSize from "./masters/DirectTyreSize";
import TestCategory from "./masters/TestCategory";
import TestLocation from "./masters/TestLocation";
import Plant from "./masters/Plant";
import TestRequests from "./TestRequests";
import CreateTestPlan from "../components/testPlan/CreateTestPlan"
import TestObjective from "./masters/TestObjective";
import TestType from "./masters/TestType";
import Modification from "./masters/Modification";
import Groove from "./masters/Groove";
import MechanicalCondition from "./masters/MechanicalCondition";
import Terrain from "./masters/Terrain";
import LoadSegment from "./masters/LoadSegment";
import ProjectStatus from "./masters/ProjectStatus";
import PlyRating from "./masters/PlyRating";
import VehicleMake from "./masters/VehicleMake"
import VehicleConfigurations from "./masters/VehicleConfiguration";
import FitmentAxle from "./masters/FitmentAxle"
import TyreMake from "./masters/TyreMake";
import TestMethod from "./masters/TestMethod";
import TestingAxle from "./masters/TestingAxle";
import LoadIndexs from "./masters/LoadIndex";
import SpeedIndex from "./masters/SpeedIndex";
import Application from "./masters/Application";
import RoadCondition from "./masters/RoadCondition";
import ReCallStage from "./masters/ReCallStage";
import Material from "./masters/Material";
import Users from "./Users";
import AnalysisLocation from "./masters/AnalysisLocation";
import Department from "./masters/Department";
import Team from "./masters/Team";
import VehicleModels from "./masters/VehicleModel";
import BodyTypes from "./masters/BodyType";
import CustomerTypes from "./masters/CustomerType";
import OTPForm from "./OTP";
import ForgotPassword from "./ForgotPassword";
import UpdatePassword from "./UpdatePassword";
import BenchMarkProduct from "./masters/BenchmarkProducts";
import BenchMarkCompany from "./masters/BenchmarkCompany";
import BenchMarkTyreSize from "./masters/BenchmarkTyreSize";
import Notification from "./Notification";
import PWAs from "./masters/PWA";
import FitmentRim from "./masters/FitmentRim"
import Oberservation from "./masters/Observation";
const pages = [
    {
        auth: true,
        path: "/dashboard",
        main: Dashboard,
    },
    {
        auth: true,
        path: "/application",
        main: Application,
    },
    {
        auth: false,
        path: "/login",
        main: Login,
    },
    {
        auth: false,
        path: "/login/forgot-password",
        main: ForgotPassword,
    },
    {
        auth: false,
        path: "/login/otp",
        main: OTPForm,
    },
    {
        auth: false,
        path: "/login/update-password",
        main: UpdatePassword,
    },
    {
        auth: true,
        path: "/roles",
        main: Roles,
    },
    {
        auth: true,
        path: "/permissions",
        main: Permissions,
    },
    {
        auth: true,
        path: "/test-requests",
        main: TestRequest,
    },

    /** TEST PLAN ROUTE */
    {
        auth: true,
        path: "/test-plans",
        main: TestPlan,
    },
    // {
    //     auth: true,
    //     path: "/test-plan",
    //     main: CreateTestPlan,
    // },
    {
        auth: true,
        path: "/users",
        main: Users,
    },

    /** MASTERS ROUTE */
    {
        auth: true,
        path: "/markets",
        main: Markets,
    },
    {
        auth: true,
        path: "/methodologies",
        main: Methodology
    },
    {
        auth: true,
        path: "/test-requirements",
        main: TestRequirement,
    },
    {
        auth: true,
        path: "/benchmark-products",
        main: BenchMarkProduct,
    },
    {
        auth: true,
        path: "/benchmark-company",
        main: BenchMarkCompany,
    },
    {
        auth: true,
        path: "/benchmark-tyresize",
        main: BenchMarkTyreSize,
    },
    {
        auth: true,
        path: "/product-categories",
        main: ProductCategory,
    },
    {
        auth: true,
        path: "/product-types",
        main: ProductType,
    },
    {
        auth: true,
        path: "/direct-tyre-size",
        main: DirectTyreSize,
    },
    {
        auth: true,
        path: "/material",
        main: Material,
    },
    {
        auth: true,
        path: "/test-category",
        main: TestCategory,
    },
    {
        auth: true,
        path: "/test-method",
        main: TestMethod,
    },
    {
        auth: true,
        path: "/test-location",
        main: TestLocation,
    },
    {
        auth: true,
        path: "/analysis-location",
        main: AnalysisLocation,
    },
    {
        auth: true,
        path: "/testing-axle",
        main: TestingAxle,
    },
    {
        auth: true,
        path: "/plant",
        main: Plant,
    },
    {
        auth: true,
        path: "/test-objective",
        main: TestObjective,
    },
    {
        auth: true,
        path: "/test-type",
        main: TestType,
    },
    {
        auth: true,
        path: "/road-condition",
        main: RoadCondition,
    },
    {
        auth: true,
        path: "/re-call-stage",
        main: ReCallStage,
    },
    {
        auth: true,
        path: "/modification",
        main: Modification,
    },
    {
        auth: true,
        path: "/observation",
        main: Oberservation,
    },
    {
        auth: true,
        path: "/groove",
        main: Groove,
    },
    {
        auth: true,
        path: "/department",
        main: Department,
    },
    {
        auth: true,
        path: "/team",
        main: Team,
    },
    {
        auth: true,
        path: "/load-segment",
        main: LoadSegment,
    },
    {
        auth: true,
        path: "/load-index",
        main: LoadIndexs,
    },
    {
        auth: true,
        path: "/speed-index",
        main: SpeedIndex,
    },
    {
        auth: true,
        path: "/project-status",
        main: ProjectStatus,
    },
    {
        auth: true,
        path: "/ply-rating",
        main: PlyRating,
    },
    {
        auth: true,
        path: "/vehicle-makes",
        main: VehicleMake,
    },
    {
        auth: true,
        path: "/vehicle-models",
        main: VehicleModels,
    },
    {
        auth: true,
        path: "/body-type",
        main: BodyTypes,
    },
    {
        auth: true,
        path: "/vehicle-configurations",
        main: VehicleConfigurations,
    },
    {
        auth: true,
        path: "/terrain",
        main: Terrain,
    },
    {
        auth: true,
        path: "/mechanical-condition",
        main: MechanicalCondition,
    },
    {
        auth: true,
        path: "/fitment-axles",
        main: FitmentAxle,
    },
    {
        auth: true,
        path: "/fitment-rims",
        main: FitmentRim,
    },
    {
        auth: true,
        path: "/tyre-makes",
        main: TyreMake,
    },
    {
        auth: true,
        path: "/customer-type",
        main: CustomerTypes,
    },
    {
        auth: true,
        path: "/notifications",
        main: Notification,
    },
    {
        auth: true,
        path: "/pwa",
        main: PWAs,
    },
    {
        auth: false,
        path: "*",
        main: NotFoundPage,
    },
]

export default pages;
