export default function checkPermission(permissions = [], currentUser) {
  if (!currentUser || !currentUser.role || !currentUser.role.allowed_permissions) {
    // If there's no current user or the user's role doesn't have allowed_permissions, return false
    return false;
  }

  if (!permissions?.length) {
    // If no specific permissions are provided, return true
    return true;
  }

  const userPermissions = currentUser.role.allowed_permissions;

  // Filter out the permissions that are not in the user's role
  const hasPermission = permissions.some(({ permission, subPermission }) => {
    return userPermissions.some((userPermission) => {
      return (
        userPermission.permission.code === permission &&
        (!subPermission || // If there's no subPermission specified, consider it a match
          userPermission.subPermissions?.some((value) => subPermission.includes(value)))
      );
    });
  });

  return hasPermission;
}
