import axios from '../apis/axios';
import toast from 'react-hot-toast';
const logout = ()=>{
  setTimeout(() => {
    localStorage.clear();
    window.location.href= "/"
  }, 1500);
}
export const getAllData = async (url, query = {}, fn = ()=>{}, responseType=null) => {
  try{
  const limit = query.limit 
  const page = query.page;
  // console.log({limit});
  const response = await axios({
    method: 'GET',
    url,
    params: {
      ...query,
      ...(limit &&{ limit : limit}),
      ...(page && { page : page}),
    },
    ...responseType && {responseType} 
    // responseType: "blob"
  });
  return response.data;
 }catch (error) {
  console.log("RESPONSE ERROR", error?.response.status);

  if(error?.response?.status === 403){
    logout();
  }

  toast.error(
    error?.response?.data?.message ||
      error?.response?.data?.error ||
      JSON.stringify(error?.response?.data) ||
      error?.message ||
      'Some Error Occured'
  );
  return null
  // new Error(error);
}
};

export const fetchAll = async (url, query = {}, fn = ()=>{}, responseType=null) => {
  try{
  const limit = query.limit 
  const page = query.page;
  // console.log({limit});
  const response = await axios({
    method: 'GET',
    url,
    params: {
      ...query,
      ...(limit &&{ limit : limit}),
      ...(page && { page : page}),
    },
    ...responseType && {responseType} 
  });
  console.log("RESPONSE STATUS---", response?.request?.status)
  return response;
 }catch (error) {
  console.log("RESPONSE ERROR", {error});
  if(error?.response?.status === 403){
    logout();
  }

  toast.error(
    error?.response?.data?.message ||
      error?.response?.data?.error ||
      JSON.stringify(error?.response?.data) ||
      error?.message ||
      'Some Error Occured'
  );
  return null
  // new Error(error);
}
};

export const getById = async (url, id) => {
  const response = await axios({
    method: 'GET',
    url: `${url}/${id}`,
  });
  return response.data;
};

export const createDocument = async (url, data, fn=async()=>{}) => {
  try {
    const response = await axios({
      method: 'POST',
      url,
      data,
    });
    toast.success(
      response?.data?.message ?? "Successfully created"
     );
    return response?.data;
  } catch (error) {
    console.log(error);
    if(error?.response?.status === 403){
      logout();
    }

    if(error?.response?.data?.errors?.length){
      toast.error(
        error.response.data.errors[0]?.msg + " at " + (error.response.data.errors[0]?.path?.split(".")?.join(" "))
      )
    }else
    toast.error(
      error?.response?.data?.message ||
        error?.response?.data?.error ||
        JSON.stringify(error?.response?.data) ||
        error?.message ||
        'something went wrong'
    );
    throw new Error(error);
  }
};

export const updateDocumentByID = async (url, id, data) => {
  try {
    const response = await axios({
      method: 'PUT',
      url: `${url}/${id}`,
      data,
    });
    toast.success(
      response.data.message ?? "Successfully Updated"
     );
    return response.data;
  } catch (error) {
    console.log(error);
    if(error?.response?.status === 403){
      logout();
    }

    toast.error(
      error?.response?.data?.message ||
        error?.response?.data?.error ||
        JSON.stringify(error?.response?.data) ||
        error?.message ||
        'something went wrong'
    );
    new Error(error);
  }
};

export const deleteDocumentById = async (url, id) => {
  try {
    const response = await axios({
      method: 'DELETE',
      url: `${url}/${id}`,
    });
    toast.success(
     response.message ?? "Successfully deleted"
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if(error?.response?.status === 403){
      logout();
    }

    toast.error(
      error?.response?.data?.message ||
        error?.response?.data?.error ||
        JSON.stringify(error?.response?.data) ||
        error?.message ||
        'something went wrong'
    );
    new Error(error);
  }
};
