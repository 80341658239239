import React, { useEffect, useState } from 'react'
import CustomAddPage from '../../../custom-pages/CustomAddModal';
import { getAllData } from '../../../../apis/customApiHandler';
import CustomViewPage from '../../../custom-pages/CustomViewPage';

const ViewTyreDetail = ({ data, handleClick, parentFormik, handleSubmit, totalSteps }) => {

    const [initialValues, setInitialValues] = React.useState({
      ...data,
    });
  
    const rows =[
      {
        name: "testTyre",
        label: "Test Type",
        className: "w-full col-span-2",
      },
      {
        name: "tyreSizedescription",
        label: "Tyre size Description",
      },
      {
        name: "group",
        label: "Group",
        required: true,
      },
      {
        name: "groupDescription",
        label: "Group Description",
        required: true,
      },
      {
        name: "material",
        label: "Material",
        required: true,
        required: true,
        optionField: "materialNo",
        className: "w-full col-span-2 font-normal",
        optionField: "MatDesc",
      },
      {
        name: "plyRating",
        label: "Ply Rating",
        className: "w-full col-span-2",
        required: true,
      },
      {
        name: "loadIndex",
        label: "Load Index",
        className: "w-full col-span-2",
        required: true,
      }, 
      {
        name: "speedRating",
        label: "Speed Rating",
        className: "w-full col-span-2",
        required: true,
      },
      {
        name: "ftQuantity",
        label: "FT Quantity",
      },
      {
        name: "ptQuantity",
        label: "PT Quantity",
      },
      {
        name: "noOfTyres",
        label: "No of Tyres (FT / PT)",
        required: true,
      },
      {
        name: "discount",
        label: "Discount % (FT)",
      },
      {
        name: "plant",
        label: "Plant",
        className: "w-full col-span-2",
      },
      {
        name: "inflatedInfo",
        label: "Inflated Info",
        type: "checkbox",
        className: "pt-2",
      },
      {
        name: "overallDiameter",
        label: "Overall Diameter",
        required: true,
      },
      {
        name: "sectionWidth",
        label: "Section Width(mm)",
        required: true,
      },

      {
        name: "NSD",
        label: "NSD",
        required: true,
      },
      {
        name: "groove",
        label: "No of Grooves",
        className: "w-full col-span-2",
      },
      {
        name: "G1",
        label: "G1",
        className: "",
        halfCol: true,
      },
      {
        name: "G2",
        label: "G2",
        className: "",
        halfCol: true,
      },
      {
        name: "G3",
        label: "G3",
        className: "",
        halfCol: true,
      },
      {
        name: "G4",
        label: "G4",
        className: "",
        halfCol: true,
      },
      {
        name: "G5",
        label: "G5",
        className: "w-full",
        halfCol: true,
      },
      {
        name: "G6",
        label: "G6",
        className: "",
        halfCol: true,
      },
     
      {
        name: "treadArc",
        label: "Tread Arc Width(mm)",
        required: true,
      },
      {
        name: "weight",
        label: "Tyre Weight",
      },
      {
        name: "treadWidth",
        label: "Tread Width(mm)",
        required: true,
      },
      {
        name: "stencilFrom",
        label: "Stencil From",
      },
      {
        name: "stencilTo",
        label: "Stencil To",
      },     
      {
        name: "comments",
        label: "Additional Comments",
      },
    ];
    return (
      <CustomViewPage
        pageTitle={"View Tyre Detail"}
        updateId={data?._id}
        queryKey="tyreDetail"
        rows={rows}
        data={initialValues}
        setData={setInitialValues}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        totalSteps={totalSteps}
        cname= "min-w-4xl"
      />
    );
  };

export default ViewTyreDetail