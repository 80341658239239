import { Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { userActionType as action } from './redux/user/user.action.type';
import jwt_decode from "jwt-decode";

import pages from './pages/index';
import WrapperComponent from './components/Wrapper';
import Login from './pages/Login';
import { addNotification, addNotifications, logOutUser } from './redux/user/user.action';
import io from 'socket.io-client';
import { getAllData } from './apis/customApiHandler';

const Routing = (props) => {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [reduxUser, setReduxUser] = useState(props?.user)
  const navigate = useNavigate();
  const onStorageUpdate = ()=>{
    const token = currentUser?.token
  }

  const parseJwt = () => { 
    const token = currentUser?.token 
    if(token){     
    const decode = jwt_decode(token)
    if (decode?.exp * 1000 <= new Date().getTime()) {
        props.logout(navigate)
        console.log('Time Expired');
    }
  }
}

  useEffect(()=>{
    parseJwt()
  }, [currentUser])

  const user = JSON.parse(localStorage.getItem("user"))
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [receivedMessage, setReceivedMessage] = useState('');
  const [firstLoad, setFirstLoad] = useState(false)
  const dispatch = useDispatch();


  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL || "https://field-testing-dev-api.jktyre.co.in");
    setSocket(socket);
    setMessage("Hello from Client!");
    return () => {
        socket.disconnect();
    };
  }, []);

  useEffect(()=>{
    if(message) sendMessage();
  }, [message])

  const sendMessage = () => {
    if (socket && message.trim() !== '') {
      console.log("Sending messag (Notification)")
      socket.emit('notification', message);
      setMessage('');
    }
  };

  useEffect(() => {
    if (user && socket && !firstLoad) {
      console.log({user, socket})
      socket.on('message', (data) => {
        console.log('Received message:', data);
      });
      console.log("emiting join room")
      socket.emit('join-room', user._id)
      setFirstLoad(true)
    }
  }, [socket, user]);

  useEffect(()=>{
    if(socket) {
      socket.on('notification', (data) => {
      console.log('Received notif:', data);
      dispatch(addNotification(data))
    });
  }
  }, [socket])

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
    window.removeEventListener("storage", onStorageUpdate);
    }
  }, []);

  const clearStorage = ()=>{
   setCurrentUser(null);
  }
  useEffect(() => {
    window.addEventListener("clearStorage", clearStorage);
    return () => {
    window.removeEventListener("clearStorage", clearStorage);
    }
  }, []);

  useEffect(() => {
    if(!reduxUser){
      props.loginUser(currentUser)
      setReduxUser(currentUser)
    }
  }, [props?.user]);
  return (
    <Routes>
       <Route path="/" element={<Login />} />
      {pages?.map((page) => {
        if (page.auth && (!currentUser || !Object.keys(currentUser).length)) {
          return <Route path="/login" element={<Login />} />;
        } else {
          return (
            <Route
              key={page.path}
              path={page.path}
              element={
                !page.auth ? (
                  <page.main />

                ) : (
                  <WrapperComponent>
                    <page.main />
                  </WrapperComponent>
                )
              }
            />
          );
        }
      })}
    </Routes>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.currentUser,
    notifications: state.user?.notifications
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (user) => {
      console.log({user})
      dispatch({ type: action.SET_CURRENT_USER, payload: user });
    },
    logout: (navigate)=>{
      dispatch(logOutUser(navigate));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
