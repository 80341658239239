import React, { useState } from "react";
import AddPage from "../../../custom-pages/AddPage";
import ViewCallbackRequirement from "./ViewCallbackRequirement"
import * as Yup from "yup";
import { TD, TH, TR, Tbody, Thead } from "../../../../styled/table";
import { Add } from "@mui/icons-material";
import Button from "../../../../common/Button";
import Input from "../../../../styled/input";

function CallbackRequirements({
  handleSubmit,
  formik,
  data,
  ...props
}) {

  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(!formik.values[""]?.callBackRequirement?.length);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const currentRemarksLength = props.remarksTimeline?.length ? props.remarksTimeline : 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))



  // console.log({index: props.index})
  return (
    <div className="flex flex-col relative w-full col-span-2">
    <div className="overflow-x-scroll">
      <div className="overflow-x-scroll">
      <table className="w-full">
          <Thead className="flex flex-row justify-between">
            <TH> Identified Location</TH>
            <TH> Material</TH>
            <TH> No. of Tyres</TH>
            <TH> Analysis Destination</TH>
            <TH> Stage</TH>
            <TH> Analysis Reason</TH>
          </Thead>
          <Tbody>
          {data?.length ? data?.map((entry, index) => {
            if(!entry.remarks) return (
              <TR className="">
              <TD> {entry?.identifiedLocation} </TD>
                <TD> {entry?.material} </TD>
                <TD> {entry?.numberOfTyres} </TD>
                <TD> {entry?.analysisDestination} </TD>
                <TD> {entry?.stage} </TD>
                <TD className="text-center">
                  {" "}
                  <Button
                    onClick={() => {
                      setShowModal(!showModal);
                      setSelectedEntry(entry);
                      setSelectedIndex(index);
                    }}
                    className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                    text="View All (7 Fields)"
                  ></Button>
                </TD>
              </TR>
            );
          }): <></>}
          </Tbody>
        </table>
      </div>
    </div>

    <div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks1"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}

    {showModal && (
      <ViewCallbackRequirement
        title="View Callback Requirement"
        parentFormik={formik}
        data={selectedEntry}
        handleClick={() => {
          setShowModal(!showModal);
          setSelectedEntry(null);
          setSelectedIndex(null);
        }}
        totalSteps={props.totalSteps}
      />
    )}
  </div>
  );
}

export default CallbackRequirements;
