import { createDocument, getAllData } from "../apis/customApiHandler";

export const getFormattedDate = (date, seperator = "-") => {
  // console.log({date, seperator})
  if(!date) if(seperator === "/" ) return "---"; else date = new Date()
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return (seperator === "/") ? dd + "/" + mm + "/" + yyyy : yyyy + "-" + mm + "-" + dd;
};

export const getFinalInputs = (inputs, values) => {
  let filterSelectInputs = inputs?.filter((input) => input.type === "select");

  let filterSelectInputsValues = filterSelectInputs?.map((input) => {
    return values[input.name];
  });
  let finalInputs = { ...values };
  filterSelectInputsValues?.forEach((input, index) => {
    finalInputs[filterSelectInputs[index].name] = filterSelectInputs[index]
      .isMulti
      ? input.map((val) => val.value)
      : input;
  });

  return finalInputs;
};

export function titleCase(str) {
  if(!str) return "";
  return str.toLowerCase().split(' ').map((word) => {
    return word[0].toUpperCase() + word.slice(1)
  }).join(' ')
}

// export const convertToIndianTimezone = (utcDate) => {
//   const indianTimeZone = 'Asia/Kolkata'; // Indian Standard Time (IST)
//   const indianDate = new Date(utcDate.toLocaleString('en-US', { timeZone: indianTimeZone }));
//   return indianDate.toLocaleString();
// };

export const convertToIndianTimezone = (utcDate) => {
  const indianTimeZone = 'Asia/Kolkata'; // Indian Standard Time (IST)
  const options = {
    timeZone: indianTimeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Add AM/PM

  };
  const indianDate = new Date(utcDate).toLocaleDateString('en-IN', options);
  return indianDate;
};

export const convertToIndianTimezoneAndGetDate = (utcDate) => {
  const resultantDate = convertToIndianTimezone(utcDate);
  if(resultantDate==="Invalid Date") return resultantDate
  return resultantDate.substring(0, 10)
};

export const fetchFile = async (url) => {
  const originalImage = url;
  const image = await fetch(originalImage);

  //Split image name
  const nameSplit = originalImage.split("/");
  const duplicateName = nameSplit.pop();

  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "" + duplicateName + "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleUpClick= (field, setData)=>{
  console.log("in up sort", field, setData)
  setData((data)=>{
    console.log(data)
    let dt = [...data];
    dt.sort((a, b)=> a[field]?.toString()?.localeCompare(b[field]?.toString()));
    return dt;  
  })
}
export const handleDownClick= (field, setData)=>{
  console.log("in sort")
  setData((data)=>{
    console.log(data)
    let dt = [...data];
    dt.sort((a, b)=> b[field]?.toString()?.localeCompare(a[field]?.toString()));
    return dt;  
  })
}

export const sendEmail = async(url, params, action)=>{
  try{
  const response = await getAllData(url, {...params, action});
  return response.data;
  }catch(err){
    return err?.message || "Error Occured"
  }
}

export const objectsAreEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export default getFormattedDate;
