import Axios from 'axios';

const token = localStorage.getItem('token');
// console.log({token});
let headers = {};
if(token!==undefined && token!== null){
    headers={
        Authorization: 'Bearer '+ token,
        // Content-Type: "application/json"
    } 
}
//axios instance create
export const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: "http://localhost:5001/api",
  headers: headers
});

export default axios;
