import React, { useEffect, useState } from "react";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from 'yup';
import { getAllData } from "../../../apis/customApiHandler";
import Input from "../../../styled/input";

const TyreAvailabilityDetails = ({ data, formik, handleSubmit, ...props }) => {

  const rows = [
    {
      label: "Project Status",
      name: "projectStatus",
      required: true,
      type: "select",
      required: true,
    },
    {
      label: "Expected Tyre Production Date",
      name: "expectedTestTyreProduction",
      type: "date",
      required: true,
    },
    {
      label: "Expected Dispatch Date",
      name: "expectedDispatch",
      type: "date",
      required: true,
    },
    {
      label: "Expected Availability Date",
      name: "expectedAvailability",
      type: "date",
      disabled: true,
    },
    {
      label: "Project Date",
      name: "projectDate",
      type: "date",

    },
    {
      label: "Expected Test Commencement Date",
      name: "expectedTestCommencement",
      type: "date",

      disabled: true,
    },
    {
      label: "Expected availability at Plant Date",
      name: "expectedAvailabilityPlant",
      type: "date",
      required: true,
    },
    {
      label: "Special Comments",
      name: "remarks",
      type: "textarea",
      className: "h-[120px]",
    },
  ];

  const currentRemarksLength = props.remarksTimeline?.length ? props.remarksTimeline : 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))


  // console.log(data)

  // console.log({ formSchema })
  // console.log(props.formik.values["testRequirement"])
  return (
    <div className="grid grid-cols-2">
      {data && rows.map(row => {
        return <div className="col-span-1 my-3">
          <div className="flex flex-col">
            <div className="text-[#757575] inter text-[12px] font-semibold">{row.label}
              {row.required && (
                <span className="text-red-500 required-dot">*</span>
              )}</div>
            <div className="text-main inter font-semibold text-[16px]">{data[row.name]}</div>
          </div>
        </div>
      })}

<div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks1"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}
    </div>
  );
};

export default TyreAvailabilityDetails;