import React, { useEffect, useMemo, useState } from "react";
import CustomAddPage from "../../custom-pages/CustomAddModal";
import {
  createDocument,
  getAllData,
  updateDocumentByID,
} from "../../../apis/customApiHandler";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from "yup";
import Button from "../../../common/Button";
import { TD, TH, TR, Tbody, Thead } from "../../../styled/table";
import InputField from "../../formik/InputField";
import { getFinalInputs } from "../../../utils/helpers";
import { Form, FormikProvider, useFormik } from "formik";
import SelectField from "../../formik/SelectField";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Add, Delete } from "@mui/icons-material";
import Input from "../../../styled/input";

const DiscountDetails = ({
  data,
  handleSubmit,
  formik,
  handleNextClick,
  url,
  queryKey,
  requestNo,
  yupValidationSchema = {},
  updateId,
  ...props
}) => {
  const queryClient = useQueryClient();

  const formSchema = Yup.object().shape({
    fitmentPlan: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const { mutateAsync } = useMutation((data) =>
    props.action === "update"
      ? updateDocumentByID(url, updateId, data)
      : createDocument(url, data)
  );
  const inputRow = [];

  const [disabled, setDisabled] = useState(props.disabled);
  const currentRemarksLength = props.remarksTimeline
    ? props.remarksTimeline?.length
    : 0;
  const currentUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (data?.length) discountFormik.setFieldValue("discountDetails", data);
  }, [data]);

  const discountFormik = useFormik({
    initialValues: { ...data },
    onSubmit: async (values) => {
      const finalInputs = getFinalInputs(inputRow, values);
      console.log("in formik submit");
      if (url)
        await mutateAsync(finalInputs, {
          onSuccess: (data) => {
            queryClient.invalidateQueries(queryKey);
            if (data && data.message) {
              toast.success(data.message);
            }
          },
          onError: (err) => {
            toast.error(
              err.response.data.error ||
                err.response.data.message ||
                err.message ||
                "Something went wrong"
            );
          },
        });
      else {
        handleSubmit(finalInputs);
        handleNextClick();
      }
    },
    validationSchema: formSchema || undefined,
    validate: (values) => {
      let errors = {
        discountDetails: values.discountDetails?.map((entry) => {
          return {};
        }),
      };
      let hasErrors = false;
      values.discountDetails?.map((detail, index) => {
        if (detail.discount === "") {
          errors["discountDetails"][index].discount = "Required!";
          hasErrors = true;
        }
      });
      setDisabled(props.expanded === props.totalSteps || hasErrors);
      return errors;
    },
  });

  console.log(formik.values, discountFormik.values);

  // console.log("tr", discountFormik.values);
  return (
    <div className="w-full flex flex-col relative">
      <FormikProvider value={discountFormik}>
        <Form>
          {discountFormik.values.discountDetails?.length ? (
            discountFormik.values.discountDetails.map((discountDetail,index) => {
              return (
                <div className="overflow-x-scroll w-full" key={index}>
                  <p className="heading m-2 text-darkGrey mt-5 text-semibold">
                    {discountDetail.location}
                  </p>
                  <table className="w-full">
                    <Thead className="flex flex-row justify-between">
                      <TH> Group</TH>
                      <TH> Group Description</TH>
                      <TH> Material</TH>
                      <TH> Discount % (FT)</TH>
                      <TH> Discount % (PT)</TH>
                      <TH> Remarks</TH>
                    </Thead>
                    <Tbody>
                      {discountDetail.values ? (
                        discountDetail.values?.map((entry, index) => {
                          // console.log({entry})
                          return (
                            <div>
                              <p className="text-gary-500 font-semibold text-md m-2">
                                {entry.location}
                              </p>
                              <TR className="">
                                <TD> {entry.group} </TD>
                                <TD>{entry.groupDescription}</TD>
                                <TD> {entry.tyreSizedescription} </TD>
                                <TD> {entry.ftDiscount} </TD>
                                <TD> {entry.ptDiscount} </TD>
                                <TD> {entry.remarks} </TD>
                              </TR>
                            </div>
                          );
                        })
                      ) : (
                        <div className="w-full text-semibold text-center text-lightGrey p-3">
                          {" "}
                          No Tyre Details Added{" "}
                        </div>
                      )}
                    </Tbody>
                  </table>
                </div>
              );
            })
          ) : (
            <></>
          )}
                            <div className="flex flex-col w-full col-span-2 mt-4">
                    <label className="text-[#757575] inter text-[12px] font-semibold">
                      Remarks
                    </label>
                    {props.remarksTimeline?.length ? (
                      props.remarksTimeline?.map((item) => {
                        return (
                          <div className="w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
                            {item.remark}
                          </div>
                        );
                      })
                    ) : (
                      <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]">
                        {" "}
                        No Remarks Added
                      </div>
                    )}
                  </div>
                  {props.allowEdit && (
                    <div className="flex flex-col w-full col-span-2 mt-4">
                      <label className="text-[#757575] inter text-[12px] font-semibold">
                        Add a Remark
                        <Input
                          name={"remarks"}
                          onChange={(e) => {
                            let remarks = formik?.values.remarksTimeline?.length
                              ? formik.values.remarksTimeline
                              : [];
                            if (remarks?.length === currentRemarksLength) {
                              remarks.push({
                                remark: e.target.value,
                                addedBy: currentUser._id,
                                addedAt: new Date(),
                              });
                            } else {
                              remarks.pop();
                              remarks.push({
                                remark: e.target.value,
                                addedBy: currentUser._id,
                                addedAt: new Date(),
                              });
                            }
                            handleSubmit(remarks);
                          }}
                          // value={formik?.values[props.name]?.remarks}
                          formik={formik}
                          className={` w-full`}
                        />
                      </label>
                    </div>
                  )}

        </Form>
      </FormikProvider>
    </div>
  );
};

export default DiscountDetails;
