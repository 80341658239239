import React, { useEffect, useState } from "react";
import Container from "../../common/Container";
import Collapsable from "../../common/Collapsable";
import { Form, FormikProvider, useFormik } from "formik";
import TyreDetails from "./steps/TyreDetails";
import ProposedVehicles from "./steps/ProposedVehicles";
import DiscountDetails from "./steps/DiscountDetails"
import FitmentDetails from "./steps/FitmentDetails"
import DocumentUpload from "./steps/DocumentUpload";
import TestRequirement from "./steps/TestRequirement";
import Button from "../../common/Button";
import { createDocument, getAllData, updateDocumentByID } from "../../apis/customApiHandler";
import { useSearchParams } from "react-router-dom";

function CreateTestPlan(props) {
  const [initialValues, setInitialValues] = useState({});
  const [requestNo, setRequestNo] = useState()
  const [planNo, setPlanNo] = useState()
  const [testRequest, setTestRequest]  = useState({})
  const [createMode, setCreateMode] = useState(true)
  const [valueChanged, setValueChanged] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [searchParams, setSearchParams] = useSearchParams();
  const totalSteps = 6;
  const data = {};
  
  const formik = useFormik({
    initialValues: initialValues!==null ? initialValues : {},
    onSubmit: async (values) => {
      console.log({ values });
    },

    validate: (values) => {
      let errors = {};
      if (!values?.testRequirements) errors.testRequirements = "Required";
      if (!values?.password) errors.password = "Required";
      return errors;
    },
  });

  useEffect(()=>{
    setCreateMode(searchParams.get("mode") === "EDIT" || searchParams.get("mode") === "CREATE")
    setPlanNo(searchParams.get("planNo"))
  }, [searchParams])
  

  const getTestPlan = async()=>{
    const response = await getAllData("/test_plan", {planNo});
    if(response.data?.length) setInitialValues(response.data[0])
  }

  useEffect(()=>{
    if(planNo) getTestPlan();
    else{
      setInitialValues(props.testPlan)
      formik.setValues(props.testPlan)
      if(!props.allowEdit) setExpanded(0)
    }
  }, [planNo])

  useEffect(() => {

    if (Object.keys(testRequest)?.length) {
      formik.setFieldValue("tyreDetails", {
        loadSegment: testRequest.usageDetails?.loadSegment,
        fitmentRim: testRequest.usageDetails?.fitmentRim,
        psiRec: testRequest.usageDetails?.psiRec,
        fitmentRimAlt: testRequest.usageDetails?.fitmentRimAlt,
        tyres: testRequest?.tyreDetails,
      });
      formik.setFieldValue("calculatedTyreDetails", testRequest?.tyreDetails,
      );
      // formik.setFieldValue("discountDetails", testRequest?.tyreDetails?.map(item => {
      //   return {group: item.tyre.group,
      //   groupDescription: item.tyre.groupDescription,
      //   material: item.tyre.material,
      //   discount: item.tyre.discount,
      //   remarks: item.tyre.remarks,}
      // }),
      // );
      formik.setFieldValue(
        "proposedVehicles",
        testRequest?.usageDetails?.vehicleDetails
      );
      formik.setFieldValue(
        "fitmentDetailsValue",
        testRequest?.usageDetails
      )
      formik.setFieldValue(
        "documents",
        testRequest?.documents
      );
    }
  }, [testRequest]);

  console.log(testRequest.usageDetails?.vehicleDetails)

  const getTestRequest = async () => {
    const data = await getAllData("/test_request", {
      requestNo: formik.values?.testRequirement?.requestNo,
      status: "ACCEPTED",
    });
    if(data?.data) setTestRequest(data?.data[0])
  }

  useEffect(()=>{
    if (!Object.keys(testRequest)?.length) {
      if(formik.values?.testRequirement?.requestNo){
        getTestRequest();
      }
    }
  }, [formik.values?.testRequirement])

  console.log({testRequest})


  useEffect(()=>{
    formik.setValues({...initialValues});
    setStatuses({
      0: getStatus(initialValues?.testRequirement) ? 2 : 1,
      1: getStatus(initialValues?.tyreDetails) ? 2 : statuses[1], 
      2: getStatus(initialValues?.proposedVehicles)  ? 2 : statuses[2],
      3: getStatus(initialValues?.fitmentDetails) ? 2 : statuses[3],
      4: getStatus(initialValues?.discountDetails) ? 2 : statuses[4],
      5: getStatus(initialValues?.documents) ? 2 : statuses[5],
    })
  }, [initialValues])


  const [disabled, setDisabled] = useState(false);
  const [expanded, setExpanded] = useState(0);
  /**
   * 0 -> pending
   * 1 -> in progress
   * 2 -> completed
   */
  const getStatus = (data) =>{
    if(typeof data === "object"){
      return data && Object.keys(data)?.length
    }else return data
  }
  const [statuses, setStatuses] = useState({
    0: getStatus(initialValues?.testRequirement) ? 2 : 1,
    1: getStatus(initialValues?.tyreDetails) ? 2 : 0,     
    2: getStatus(initialValues?.proposedVehicles) ? 2 : 0,
    3: getStatus(initialValues?.fitmentDetails) ? 2 : 0,
    4: getStatus(initialValues?.discountDetails) ? 2 : 0,
    5: getStatus(initialValues?.documents) ? 2 : 0,
  });



  /// NEEDED ONLY FOR CLICKING NEXT WITHOUT CHANGING FORM VALUES
  const handleNextClick = (panel) => {
    console.log({panel, panelPlus1: panel+1})
    setStatuses({...statuses, [panel+1]: 1, [panel]: 2});
    setExpanded(panel+1);
  };

  const setValues = async (module, values, index) => {
    formik.setFieldValue(module, values[module])
    // console.log(module, values, index)
    setValueChanged(true)
    setStatuses({...statuses, [index]: 2, [index+1]: 1})
    setExpanded(index+1);
  }

  useEffect(()=>{
    // console.log({statuses})
  }, [statuses])

  const saveAsDraft = async (values={}) => {
    // formik.setFieldValue(module, values)
    let response = {};
    // console.log(formik.values);
    
    if(formik.values?._id){
      response = await updateDocumentByID("/test_plan", formik.values?._id, {...formik.values, ...values, createdBy: currentUser?._id, nextAction: "APPROVE", nextActionBy: "PTG Head"})
    }else{
      response = await createDocument("/test_plan", {...formik.values, ...values, createdBy: currentUser?._id, nextAction: "APPROVE", nextActionBy: "PTG Head", status: "DRAFT"
    })
    }
    setInitialValues(response?.data)
    setValueChanged(true)
    setStatuses({...statuses, index: 2})
    formik.setValues({...response?.data})
    return response?.data
  }

  const handleChangeWindow = (panel) => (event, newExpanded) => {
    console.log("in handle change window") // not to worry about
    if(newExpanded){
      if (panel !== 0) {
        if (!(statuses[panel] == 1 || statuses[panel] == 2) && statuses[panel - 1] !== 2) return;
      }
    }
    setExpanded(newExpanded ? panel : false);
    setStatuses({...statuses, [panel]: statuses[panel] === 2 ? 2 : 1});

  };

  console.log("FRMIK", formik.values)
  

  const sections = [
    {
      title: "Test Requirement",
      name: "testRequirement",
      content: (
        <TestRequirement
          index={0}
          totalSteps={totalSteps}
          testRequest = {testRequest}
          setTestRequest={setTestRequest}
          data={initialValues?.testRequirement}
          handleNextClick={handleNextClick}
          formik={formik}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          status = {initialValues?.status}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
    {
      title: "Tyre Details",
      name: "tyreDetails",
      content: (
        <TyreDetails
          index={1}
          totalSteps={totalSteps}
          testRequest = {testRequest}
          handleNextClick={handleNextClick}
          formik={formik}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          data={formik.values?.tyreDetails}
        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },

    {
      title: "Proposed Vehicles",
      name: "proposedVehicles",
      content: (
        <ProposedVehicles
          index={2}
          totalSteps={totalSteps}
          testRequest = {testRequest}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values?.proposedVehicles}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
        />
      ),
    },

    {
      title: "Fitment Details",
      name: "fitmentDetails",
      content: (
        <FitmentDetails
          index={3}
          totalSteps={totalSteps}
          requestNo = {formik.values.testRequirement?.requestNo}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values?.fitmentDetails}
          dataValue={formik.values?.fitmentDetailsValue}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
        />
      ),
    },

    {
      title: "Discount Details",
      name: "discountDetails",
      content: (
        <DiscountDetails
          index={4}
          totalSteps={totalSteps}
          requestNo = {formik.values.testRequirement?.requestNo}
          handleNextClick={handleNextClick}
          formik={formik}
          data={formik.values?.discountDetails}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
        />
      ),
    },

    
   {
      title: "Documents",
      name: "documents",
      content: (
        <DocumentUpload
          index={5}
          totalSteps={totalSteps}
          testRequest = {testRequest}
          handleNextClick={handleNextClick}
          formik={formik}
          // disabled={disabled}
          // setDisabled={setDisabled}
          expanded={expanded}
          setExpanded={setExpanded}
          handleSubmit={setValues}
          data={{documents: formik.values?.documents}}

        />
      ),
      disabled: disabled,
      setDisabled: setDisabled,
    },
  ];

  console.log('formikk',formik.values);

  return (
    createMode? <Container cname= "">
      {/* container_test_plan */}
      <div className="flex flex-col md:flex-row justify-between mb-10">
        <div className=" flex flex-col">
          <div className="font-semibold text-[24px]"> {planNo ? createMode ? "Edit" : "View" : "Create"} Test Plan</div>
          <div className="text-grey mt-1">
            Enter the details for your next test plan.
          </div>
        </div>

        <div className="flex flex-row text-darkGrey gap-5 px-2 text-[16px] font-semibold">
          <div className=" flex flex-row items-center gap-2">
            <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
            <div className="">In Progress</div>
          </div>
          <div className=" flex flex-row items-center gap-2">
            <div className="w-[20px] h-[20px] rounded-full bg-main"></div>
            <div>Pending </div>
          </div>
          <div className=" flex flex-row items-center gap-2">
            <div className="w-[20px] h-[20px] rounded-full bg-green-400 bg-opacity-100"></div>
            <div>Completed </div>
          </div>
        </div>
      </div>
      <FormikProvider value={formik}>
        {formik.values && <Form style={{ height: "max-content" }}>
          <div className="table shadow-sm">
            {sections.map((section, index) => {
              return (
                <Collapsable
                  key={index}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  statuses={statuses}
                  setStatuses={setStatuses}
                  section={section}
                  formik={formik}
                  index={index}
                  handleNextClick={handleNextClick}
                  handleChangeWindow={handleChangeWindow}
                  totalSteps={totalSteps}
                />
              );
            })}
          </div>
          {expanded !== totalSteps && formik.values.status!== "ASKED FOR MODIFICATION" ? 
            <div className= "w-36 float-right mt-6 ">
            <Button
                type="button"
                disabled={disabled}
                className="bg-main text-white text-center items-center justify-center h-10 w-full hoverDiv"
                onClick={(e) => {
                  e.preventDefault();
                  saveAsDraft()
                }}  
              >
                {!formik.values.status ? "Save as Draft": "Save"}
              </Button>
              <div className="hide text-main">Save all completed steps</div>
            </div>
           :
            expanded === 6 && <div className="flex justify-between mt-6 text-md ">
              <div className="flex flex-row gap-3  w-64 ">
                {
                  <div className= "flex flex-col"><Button
                  type="button"
                  disabled={disabled}
                  className="bg-main text-white text-center items-center justify-center h-10 w-36 max-w-[8rem] hoverDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    saveAsDraft()
                  }}
                >
                  {!formik.values.status ? "Save as Draft": "Save"}
                </Button>
                <div className="hide text-main">Save all completed steps</div>
                </div>}
                <Button
                  type="button"
                  className="bg-main text-white text-center items-center justify-center h-10 w-full max-w-[8rem]"
                  onClick={() => {
                    // console.log({data})
                    setExpanded(totalSteps-1)
                  }}
                >
                  Back
                </Button>
              </div>
              <Button
                type="button"
                // disabled={resetDisabled}
                className="bg-main text-white text-center items-center justify-center h-10 px-6"
                onClick={async () => {
                  setDisabled(true);
                  const req = await saveAsDraft()
                  // await saveAsDraft({ status: "PENDING_APPROVAL", ...initialValues.status === "DRAFT" && { submissionDate: new Date() } })
                  console.log("requestNo", req?.requestNo)
                  props.setViewMode(req?.planNo, "REVIEW")
                  
                }}
              >
                Review
              </Button>
            </div>
          }
        </Form>
      }
      </FormikProvider>
    </Container>: <></>
  );
}

export default CreateTestPlan;
