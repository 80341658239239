import React, { useState, useEffect } from 'react';

const Timer = ({ endTime, ...props}) => {
  const [timeRemaining, setTimeRemaining] = useState(endTime - Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeRemaining = endTime - Date.now();
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    // return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    return minutes > 0 || seconds > 0 ? `${props.showDays ? days + " days, ": ""} ${props.showDays ? hours + " hours, ": ""}${minutes} : ${seconds} `: '--:--';
  };

  return (
    <span className= "">
       {formatTime(timeRemaining)}
    </span>
  );
};

export default Timer;
