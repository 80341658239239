import React from 'react'
import { useSelector } from 'react-redux';
import Container from '../common/Container';
import { getAllData } from '../apis/customApiHandler';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { userActionType as action } from '../redux/user/user.action.type';
import { addNotifications } from '../redux/user/user.action';

function Notification(props) {
    // const notifications = useSelector((state) => state.user?.notifications);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"))
    const [firstLoad, setFirstLoad] = useState(true)

    const updateNotifs = async()=>{
      console.log("Setting NOtifs")
      const notificationRes = await getAllData("/auth/updateNotifications")
      console.log(notificationRes)
    }

    const getNotifs = async()=>{
      console.log("GETTING  NOtifs")
      const notificationRes = await getAllData("/auth/getNotifications")
      console.log(notificationRes)
      dispatch(addNotifications(notificationRes?.data))
    }
    // useEffect(()=>{
    //   console.log({firstLoad})
    //   if(user && firstLoad) getNotifs();
    //   setFirstLoad(false)
    // }, [firstLoad])
  
    useEffect(()=>{
      console.log("in mount");
      console.log({firstLoad})
      if(user && firstLoad) getNotifs();
      setFirstLoad(false)
      return ()=>{
        setTimeout(()=>{
          if(user) updateNotifs();
        }, 5000)
      }
    }, [firstLoad])


  return (
    <Container>
        <h1 className= "text-lg inter font-bold text-darkGrey">Notifications</h1>
    <div className= "h-[95%] overflow-scroll mb-2 flex-row mx-auto justiyy-center">
    {
        props.notifications?.map((notif)=> {
            return <div className= {`relative w-[80%] rounded-lg my-2 p-4 py-4 break-all  flex justify-between shadow-md border  border-darkGrey ${notif.status !== "UnRead" ? "bg-[#f2f2f2] bg-opacity-50" : "bg-primarySubtle"} my-4`} >
              <span className= "text-md w-[80%]">{ notif?.message } 
              {/* {notif.status} */}
               </span>
              <span className= "absolute bottom-1 right-4 text-sm text-grey font-medium"> {notif.date}</span>
              </div>
        })
    }
    </div>
    </Container>

  )
}
const mapStateToProps = (state) => {
  console.log({state})
  return {
    // golbal user profile
    user: state.user?.currentUser,
    notifications: state.user?.notifications
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (user) => {
      console.log({user})
      dispatch({ type: action.SET_CURRENT_USER, payload: user });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
