import React from 'react'

function Container(props) {
  return (
    <div className= {`container  ${props.cname} p-6`} onClick= {props.onClick}>
        {props.children}
    </div>
  )
}

export default Container