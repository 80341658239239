import React, { useEffect, useState, useRef } from "react";
import Container from "../../common/Container";
import Collapsable from "../../common/Collapsable";
import { Form, FormikProvider, useFormik } from "formik";
import TyreDetails from "./view/TyreDetails/TyreDetails";
import UsageDetails from "./view/UsageDetails/UsageDetails";
import TyreAvailabilityDetails from "./view/TyreAvailabilityDetails.jsx";
import CallbackRequirements from "./view/CallbackRequirements/CallbackRequirement";
import DocumentUpload from "./view/DocumentUpload";
import TestRequirement from "./view/TestRequirement";
import Button from "../../common/Button";
import { deleteDocumentById, getAllData, updateDocumentByID } from "../../apis/customApiHandler";

import styles from './Download.module.css';
import jk_logo from '../../img/jk-logo.png';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from 'react-to-print';
import { convertToIndianTimezone } from "../../utils/helpers";

function DownloadTestRequest({ data }) {

    console.log("download", data);
    const componentPDF = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
    });

    const exportFile = async (fileName) => {
        const element = componentPDF.current;
        const canvas = await html2canvas(element, {
            scale: 1,
        });
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF("portrait", "px", [1080, 1080]);

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(data, "PNG", 0, 0, 1080, 1080);
        pdf.save(fileName + ".pdf");
    };


    return (
        <Container cname="container_test_request">
            <div className= "flex flex-row gap-2 w-24 float-right mb-5 h-10">
            <button
                className={styles.download_button}
                onClick={()=>exportFile("testRequest")}
            >
                <img src="./icons/download.svg"/>
            </button>
            <button
                className={styles.download_button}
                onClick={generatePDF}
            >
                <img src="./icons/print.svg"/>
            </button>
            </div>

            <div className="w-full flex flex-col md:flex-row justify-between mb-10">
                <div className=" flex flex-col">

                </div>


                <div className={styles.container}
                    ref={componentPDF}
                >
                    {/* HEADERS */}
                    <>
                        <div className={styles.header}>
                            <div className={styles.logo}>
                                <img src={jk_logo} alt="" />
                            </div>
                            <div className={styles.main_heading}>
                                <h1>Product Development Center</h1>
                                {/* <h3>Mysuru (Karnataka)</h3> */}
                            </div>
                            <div className={styles.strict_confidentially}>
                                <p style={{ color: "red" }}>Strictly Confidential</p>
                                <p>Only For Internal Circulation</p>
                            </div>
                        </div>

                        <div className={styles.heading}>
                            TEST REQUEST
                        </div>
                    </>

                    {/* TEST REQUETS AND DATE */}
                    <>
                        <div className={styles.test_request_no_and_date}>
                            <div className={styles.test_request_no}>
                                {data.requestNo}
                            </div>
                            <div className={styles.date}>
                                DATE: {convertToIndianTimezone(data.submissionDate).substring(0,10)}
                            </div>
                        </div>
                    </>

                    {/* TEST TYPE */}
                    <>
                        <div className={styles.tyre_dual_table}
                            style={{ backgroundColor: '' }}
                        >
                            <div className={styles.main_row_table}>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Test Category
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.testRequirement?.testCategory}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Test Objective
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.testRequirement?.testObjective}</p>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.main_row_table}>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Test Requirement
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.testRequirement?.testRequirement}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Test Methodology
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.testRequirement?.methodology}</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className={styles.test_type}
                            style={{ background: '' }}
                        >
                            <div className={styles.test_type_row}>
                                <div className={styles.test_type_left}>Test Type</div>
                                <div className={styles.test_type_right}>
                                    <p> {data?.testRequirement?.testType}</p>
                                </div>

                            </div>
                            <div className={styles.test_type_row}>
                                <div className={styles.test_type_left}>Modifications</div>
                                <div className={styles.test_type_right}>
                                    <p> {data?.testRequirement?.modification}</p>
                                </div>
                            </div>
                        </div>
                    </>


                    {/*TABLE TYRE DETAILS */}
                    <>
                        <b>Tyre Details</b>
                        <div className={styles.table}
                            style={{ backgroundColor: "" }}
                        >
                            <table className= {styles.table}>
                                <thead>
                                    <tr>
                                        <th>Material</th>
                                        <th>Group Desc</th>
                                        <th>Plant</th>
                                        <th>No. Tyres</th>
                                        <th>NSD (mm)</th>
                                        <th>Stencil From #</th>
                                        <th>Stencil to #</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data?.tyreDetails && data?.tyreDetails.length > 0 ?
                                        data?.tyreDetails.map((d, idx) => (
                                            <>
                                                <tr>
                                                    <td>{d.tyre?.material}</td>
                                                    <td>{d.tyre?.groupDescription}</td>
                                                    <td>{d.tyre?.plant} </td>
                                                    <td>{d.tyre?.noOfTyres}</td>
                                                    <td>{d.tyre?.NSD}</td>
                                                    <td>{d.tyre?.stencilFrom}</td>
                                                    <td>{d.tyre?.stencilTo} </td>
                                                </tr>
                                            </>
                                        ))
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>


                    {/* USAGE TYRE AVAILABILITY DETAILS */}
                    <>
                        <b>Usage / Tyre Availability Details :  </b>
                        <div className={styles.tyre_dual_table}
                            style={{ background: "" }}
                        >
                            <div className={styles.main_row_table}>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Load Segment
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.usageDetails?.loadSegment}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Recommended PSI
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.usageDetails?.psiRec}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Expected Production
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.tyreAvailabilityDetails?.expectedTestTyreProduction}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Expected Dispatch
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.tyreAvailabilityDetails?.expectedDispatch}</p>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.main_row_table}>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Fitment Rim
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.usageDetails?.fitmentRim}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Project Status
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.tyreAvailabilityDetails?.projectStatus}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Expected Availability Date @Plant
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.tyreAvailabilityDetails?.expectedAvailabilityPlant}</p>
                                    </div>
                                </div>
                                <div className={styles.dual_table_left}>
                                    <div className={styles.dual_table_left1}>
                                        Expected Availability Date @Depot
                                    </div>
                                    <div className={styles.dual_table_left2}>
                                        <p> {data?.tyreAvailabilityDetails?.expectedAvailability}</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </>


                    {/*TABLE VEHICLE MAKE */}
                    <div className={styles.table}>
                        <table className= {styles.table}>
                            <thead>
                                <tr>
                                    <th>Vehicle Make</th>
                                    <th>Vehicle Model</th>
                                    <th>Config</th>
                                    <th>Testing Axle</th>
                                    <th>Application</th>
                                    <th>Fitment Axle</th>
                                    <th>Dual Spacing (mm)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.usageDetails?.vehicleDetails && data?.usageDetails?.vehicleDetails.length > 0 ?
                                    data?.usageDetails?.vehicleDetails.map((d, idx) => (
                                        <>
                                            <tr>
                                                <td>{d.make}</td>
                                                <td>{d.model}</td>
                                                <td>{d.configuration} </td>
                                                <td>{d.testingAxle} </td>
                                                <td>{d.application} </td>
                                                <td>{d.fitmentAxle} </td>
                                                <td>{d.dualSpacing} </td>
                                            </tr>
                                        </>
                                    ))
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>


                    {/* SPECIAL COMMENTS */}
                    <div className={styles.row_table}>
                        <div className={styles.row_left}>
                            Special Comments
                        </div>
                        <div className={styles.row_right}> </div>
                    </div>


                    {/*TABLE REASON FOR CHANGE */}
                    {/* <div className={styles.table}>
                        <table className= {styles.table}>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Reason for Change</th>
                                    <th>Originator</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>2023-10-10</td>
                                    <td>Updated content</td>
                                    <td>John Doe</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>2023-10-12</td>
                                    <td>Fixed errors</td>
                                    <td>Jane Smith</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}


                    {/* ORIGINATOR  */}
                    <>
                        <div className={styles.originator}>
                            ORIGINATOR : {data.createdBy?.username}
                        </div>
                        <div className={styles.originator}>
                            ACCEPTED BY : {data.acceptedBy?.username}
                        </div>
                        <div className={styles.originator}>
                            APPROVED BY : {data.approvedBy?.username}
                        </div>
                    </>
                </div>



            </div>

        </Container>
    );
}

export default DownloadTestRequest;