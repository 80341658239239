import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import SelectField from "../formik/SelectField";
import InputField from "../formik/InputField";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  createDocument,
  updateDocumentByID,
} from "../../apis/customApiHandler";
import { getFinalInputs } from "../../utils/helpers"
import Button from "../../common/Button";
import AsyncSelect from "../formik/AsyncSelect";

function AddPage({
  data,
  setData,
  url,
  queryKey,
  handleNextClick,
  handleSubmit,
  yupValidationSchema = {},
  onCancel,
  inputs,
  pageTitle,
  action,
  updateId,
  parentFormik,
  expanded,
  disabled,
  setDisabled,
  setExpanded,
  ...props
}) {
  const { mutateAsync } = useMutation((data) =>
    action === "update"
      ? updateDocumentByID(url, updateId, data)
      : createDocument(url, data)
  );
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: data ?? {},
    onSubmit: async (values) => {
      const finalInputs = getFinalInputs(inputs, values);
      console.log("in formik submit");
      if (url)
        await mutateAsync(finalInputs, {
          onSuccess: (data) => {
            queryClient.invalidateQueries(queryKey);
            if (data && data.message) {
              toast.success(data.message);
            }
          },
          onError: (err) => {
            toast.error(
              err.response.data.error ||
                err.response.data.message ||
                err.message ||
                "Something went wrong"
            );
          },
        });
      else {
        handleSubmit(finalInputs);
        handleNextClick();
      }
    },
    validationSchema: yupValidationSchema || undefined,
    validate: (values) => {
      let errors = {};
      inputs?.forEach((field) => {
        if (field.required && !values[field.name]) {
          errors[field.name] = "Required";
        }
      });
      // setDisabled(
      //   expanded===props.totalSteps || 
      //   // !Object.keys(formik.touched)?.length ||
      //     (errors && Object.keys(errors)?.length)
      // );
      // setResetDisabled(!formik.values.length && !Object.keys(formik.touched)?.length);
      // console.log({errors})
      return errors;
    },
  });

  // console.log(formik.errors)

  const [resetDisabled, setResetDisabled] = useState(true);

  useMemo(() => {
    if(data && Object.keys(data).length) formik.setValues({...data});
  }, [data]);

  useEffect(()=>{
    setDisabled(expanded===props.totalSteps || 
        !Object.keys(formik.touched)?.length ||
          (formik.errors && Object.keys(formik.errors)?.length));
  },[formik.errors])

  useEffect(()=>{
    if(setData){
      console.log("SET DATA CALLED", formik.values)
      setData(formik.values)
    }
  }, [formik.values])


  useEffect(()=>{
    if(formik.touched && Object.keys(formik.touched)?.length){
      setDisabled((formik.errors && Object.keys(formik.errors)?.length))
      if(resetDisabled){
        setResetDisabled(false)
      }
    }
  }, [formik.touched])

  console.log(formik.errors)


  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="grid grid-cols-2 sm:w-[100%] md:w-[90%] relative">
          {inputs?.map((formField,index) => {
            
            return (
              <div className="col-span-1 mb-2 sm:w-[100%] md:w-[80%]" key={index}>
                {formField.type === "async-select" ? (
                  <AsyncSelect 
                  {...props}
                  {...formField} 
                  formik={formik}
                  disabled={formField.disabled}
                  />
                ) : formField.type === "select" ? (
                  <SelectField
                    {...formField}
                    disabled={
                      formField.disabled
                    }
                    className={`text-sm ${formField.className}`}
                    formik={formik}
                    key={formField.name}
                    name={formField.name}
                    label={formField.label}
                    options={formField.options}
                    required={formField.required}
                    multiple={formField.multiple}
                    halfCol={formField.halfCol}
                  />
                ) : (
                  <InputField
                    name={formField.name}
                    {...formField}
                    formik={formik}
                    placeholder
                    setData={setData}
                    disabled={
                      formField.disabled
                      // || expanded===props.totalSteps
                    }
                    className={` ${formField.className}`}
                  />
                )}
              </div>
            );
          })}
        </div>
        {expanded===props.totalSteps ? <></>:
        <div className="flex flex-row gap-3 mt-6 text-md w-48">
          <Button
            type="button"
            disabled={disabled}
            className="bg-main text-white text-center items-center justify-center h-10 w-full"
            onClick={(e) => {
              e.preventDefault();
              console.log("formik", formik.values)
              handleSubmit(getFinalInputs(inputs, formik.values), props.index);
              // handleNextClick(props.index);
            }}
          >
            Next
          </Button>
          <Button
            type="button"
            disabled={resetDisabled}
            className="bg-main text-white text-center items-center justify-center h-10 w-full"
            onClick={() => {
              formik.setValues({ ...data });
              setDisabled(true);
              setResetDisabled(true)
              formik.setTouched({})
          }}
          >
            Clear
          </Button>
        </div>}
      </Form>
    </FormikProvider>
  );
}

export default AddPage;
