import Sidebar from './Sidebar';
import AppBar from './AppBar';
import { useSelector } from 'react-redux';
import { Fragment, useState } from 'react';

const WrapperComponent = ({ children }) => {
  // const sideBarState = useSelector((state) => state.sidebar.sideBarState);
  const [sideBarState, setSideBarState] = useState(true)
  const currentUser = JSON.parse(localStorage.getItem("user"))
  // console.log({currentUser})
  window
  .matchMedia("(max-width: 468px)")
  .addEventListener("change", (e) => {
    setSideBarState(false)
  });
  return (
    <Fragment>
      {true && (
        <div className="font-inter">
          { sideBarState && <Sidebar /> }
          <div className={`${sideBarState ? 'ml-[14rem]' : 'ml-0'}`}>
            <AppBar currentUser= {currentUser}/>
            {children}
          </div>
        </div>
      )}
      {/* {!currentUser && <div className="">{children}</div>} */}
    </Fragment>
  );
};

export default WrapperComponent;
