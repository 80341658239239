import React, { useCallback, useEffect, useState } from "react";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from 'yup';
import { getAllData } from "../../../apis/customApiHandler";
import { convertToIndianTimezoneAndGetDate } from "../../../utils/helpers";
import { useSearchParams } from "react-router-dom";

const TestRequirement = ({testRequest, setTestRequest, ...props}) => {
  // get test requests
  // AUTO POPULATE (Product Category, Material, Modification, Test Request Date, Test Objective, Request Created By) are auto-populated based on the selected Test Request No.
  // TEXT FIELD rotation plan

  const [testRequests, setTestRequests] = useState([])
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [searchParams, setSearchParams] = useSearchParams();


  // const getTestRequest = async () => {
  //   const data = await getAllData("/test_request", {
  //     createdBy:
  //       currentUser?.role.name === "PDC User" ? currentUser?._id : "",
  //     status: "ACCEPTED",
  //   });
  //   setTestRequests(data?.data)
  // }

  // useEffect(() => {
  //   getTestRequest();
  // }, [])

  useEffect(()=>{
    console.log({testRequests})
  },[testRequests])

  // const loadOptions = useCallback(async (searchQuery, loadedOptions, { page }) => {
  //   console.log("here");
  //   const response = await getAllData("/test_request", {
  //     // createdBy:
  //     // currentUser?.role.name === "PDC User" ? currentUser?._id : "",
  //     blocked: false,
  //     status: "ACCEPTED",
  //     page: page,
  //     limit: 10,
  //     search: searchQuery.toUpperCase(),
  //   });
  //   setTestRequests([...loadedOptions, ...response.data])
  //   return {
  //     options: response.data,
  //     hasMore: response.data?.length
  //       ? response.paginate.currentPage !== response.paginate.totalPage
  //       : false,
  //       additional: {
  //         page: page + 1,
  //       },
  //   };
  // }, []);

  const getTestRequests = useCallback(async () => {
    const response = await getAllData("/test_request", {
      // createdBy:
      // currentUser?.role.name === "PDC User" ? currentUser?._id : "",
      blocked: false,
      status: "ACCEPTED",
      // page: page,
      // limit: 200,
      // search: searchQuery.toUpperCase(),
    });
    if(response?.data) setTestRequests([...response.data])
  }, []);


  useEffect(()=>{
    if(searchParams.get("mode") === "EDIT" || searchParams.get("mode") === "CREATE")getTestRequests();
  }, [searchParams])

  console.log({data: props.data, formik: props.formik.values.testRequirement})

  useEffect(()=>{
    if(Object.keys(testRequest)?.length){
      console.log("Here1")
      props?.formik.setFieldValue("requestNo", testRequest?.testRequirement?.requestNo)
    }
  }, [testRequest])
  const inputsStep1 = [
    {
      label: "Test Request Number",
      name: "requestNo",
      type: !props.status ? "select" : "text",
      required: true,
      options: testRequests?.map(item => { return {
        label: item.requestNo,
        value: item.requestNo,
        ...item,
      }}),
      // loadOptions: loadOptions,
      optionField: "requestNo",
      onChange: (formik, value)=>{
        const testRequest = testRequests.find(item=>{ return item.requestNo === value.requestNo});
        console.log({value, testRequest})
        formik.setFieldValue("requestNo", value.requestNo)
        formik.setFieldValue("modification", testRequest?.testRequirement?.modification)
        formik.setFieldValue("material", testRequest?.tyreDetails[0]?.tyre?.material + (testRequest?.tyreDetails[1]?.tyre ? (", " + testRequest?.tyreDetails[1]?.tyre?.material) :"" ))
        formik.setFieldValue("productType", testRequest?.testRequirement?.productType)
        formik.setFieldValue("productCategory", testRequest?.testRequirement?.productCategory)
        formik.setFieldValue("tyreSize", testRequest?.testRequirement?.tyreSize)
        formik.setFieldValue("testType", testRequest?.testRequirement?.testType)
        formik.setFieldValue("testCategory", testRequest?.testRequirement?.testCategory)
        formik.setFieldValue("testObjective", testRequest?.testRequirement?.testObjective)
        formik.setFieldValue("testRequestDate", convertToIndianTimezoneAndGetDate(testRequest?.submissionDate))
        formik.setFieldValue("requestBy", testRequest?.createdBy?.username)
        setTestRequest(value)
      },
      // disabled: props.data && Object.keys(props.data)?.length
      disabled: props.status ? true: false,

    },
    {
      label: "Product Category",
      name: "productCategory",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Modification",
      name: "modification",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Material",
      name: "material",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Test Request Date",
      name: "testRequestDate",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Test Objective",
      name: "testObjective",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Test Request By",
      name: "requestBy",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Rotation Plan",
      name: "rotationPlan",
      type: "textarea",
      required: true,
    },
    {
      label: "Fitment Plan",
      name: "fitmentPlan",
      type: "textarea",
      required: true,
    },
    {
      label: "Special Requirements",
      name: "requirements",
      type: "textarea",
      required: false,
    },
  ];
  const formSchema = Yup.object().shape({
    fitmentPlan: Yup.string()
      .min(2, 'Too Short!')
      .required('Required'),
  });

  // console.log({ formSchema })
  // console.log(props.formik.values["testRequirement"])
  const [disabled, setDisabled]= useState(!props.formik.values?.testRequirement || !Object.keys(props.formik.values?.testRequirement)?.length)
  // console.log("testRequirements",disabled)
  return (
    <AddPage
      data={props.formik.values?.testRequirement}
      index={0}
      handleNextClick={props.handleNextClick}
      inputs={inputsStep1}
      parentFormik={props.formik}
      disabled={disabled}
      setDisabled={setDisabled}
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      handleSubmit={(values) => {
        props.handleSubmit("testRequirement", { testRequirement: { ...values, completed: true } }, props.index)
      }
      }
      yupValidationSchema={formSchema}
      totalSteps={props.totalSteps}
    />
  );
};

export default TestRequirement;