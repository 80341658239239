import { userActionType } from './user.action.type';

const INITIAL_STATE = {
  currentUser: null,
  notifications: []
};

const userReducer = (state = INITIAL_STATE, action) => {
  // console.log(action.payload)
  switch (action.type) {
    case userActionType.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case userActionType.ADD_NOTIFICATION:
      console.log("Received 1 Notificationnnn", action.payload)
      return {
        ...state,
        notifications: [action.payload, ...state.notifications ],
      };

    case userActionType.ADD_NOTIFICATIONS:
      console.log("Received Notificationnnnss", action.payload)
      return {
        ...state,
        notifications: [...(action.payload?? [])],
      };
    default:
      return state;
  }
};

export default userReducer;
