import React, { useEffect, useMemo, useState } from 'react'
import CustomAddPage from '../../../custom-pages/CustomAddModal';
import { getAllData } from '../../../../apis/customApiHandler';
import CustomViewPage from '../../../custom-pages/CustomViewPage';

const AddUsageDetail = ({ data, handleClick, parentFormik, handleSubmit, totalSteps }) => {
    
    const [initialValues, setInitialValues] = React.useState({
      ...data,
    });

    const rows = [
      {
        name: "make",
        label: "Vehicle Make",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "model",
        label: "Vehicle Model",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "configuration",
        label: "Vehicle Configuration",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "application",
        label: "Application",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "fitmentAxle",
        label: "Fitment Axle",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "dualSpacing",
        label: "Dual Spacing",
        type: "Number",
        className: "w-[89%] col-span-3",
      },
      {
        name: "testingAxle",
        label: "Testing Axle",
        type: "select",
        className: "w-[90%] col-span-3",
      },
    ]
    return (
      <CustomViewPage
        pageTitle={"View Vehicle Detail"}
        action={!data ? "create" : "update"}
        updateId={data?._id}
        queryKey="permission"
        rows={rows}
        data={initialValues}
        setData={setInitialValues}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        totalSteps={totalSteps}
      />
    );
  };

export default AddUsageDetail