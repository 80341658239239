import React, { useEffect, useMemo, useState } from 'react'
import CustomAddPage from '../../../custom-pages/CustomAddModal';
import { getAllData } from '../../../../apis/customApiHandler';
import CustomViewPage from '../../../custom-pages/CustomViewPage';

const ViewCallbackRequirement = ({ data, handleClick, parentFormik, handleSubmit, totalSteps }) => {
    
    const [initialValues, setInitialValues] = React.useState({
      ...data,
    });

    console.log(data)

  
    const rows = [
      {
        name: "identifiedLocation",
        label: "Identified Location",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "analysisReason",
        label: "Analysis Reason",
        type: "text",
        className: "w-[90%] col-span-3",
      },
      {
        name: "Stage",
        label: "stage",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "material",
        label: "Material",
        type: "select",
        className: "w-[90%] col-span-3",
      },
      {
        name: "numberOfTyres",
        label: "No of Tyres",
        type: "number",
        className: "w-[90%] col-span-3",
      },
      {
        name: "analysisDestination",
        label: "Analysis Destination",
        type: "text",
        className: "w-[90%] col-span-3",
      },
      // {
      //   name: "percentageWear",
      //   label: "Percentage Wear",
      //   type: "Number",
      //   className: "w-[89%] col-span-3",
      // },
    ]
    return (
      <CustomViewPage
        pageTitle={"View Call-Back Requirement"}
        action={!data ? "create" : "update"}
        updateId={data?._id}
        queryKey="permission"
        rows={rows}
        data={data}
        setData={setInitialValues}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        totalSteps={totalSteps}
      />
    );
  };

export default ViewCallbackRequirement