import React, { useEffect, useState } from "react";
import Container from "../common/Container";
import DropDown from "../common/DropDown";
import Action from "../common/Actions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputWithIcon from "../common/InputWithIcon";
import PaginationDiv from "../common/PaginationDiv";
import { TH, TD, TR, Thead, Tbody } from "../styled/table";
import Add from "@mui/icons-material/Add";
import Button from "../common/Button";
import { Pagination } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import Modal from "../common/Modal";
import Create from "../components/roles/Create";
import { deleteDocumentById, getAllData } from "../apis/customApiHandler";
import { convertToIndianTimezoneAndGetDate, handleDownClick, handleUpClick } from "../utils/helpers";
import checkPermission from "../utils/checkPermission";

function Roles() {
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [limit, setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [inputFields, setInputFields] = useState()
  const [role, setRole] = useState()
  const [roles, setRoles] = useState([])
  const [totalData, setTotalData]= useState(0);
  const [totalPage, setTotalPage]= useState(0);

  const getRoles  = async()=>{
    const data = await getAllData("/auth/getRoles", {page, limit, search});
    setRoles(data?.data)
    setTotalData(data?.totalData)
    setTotalPage(data?.totalPage)
  }
  useEffect(()=>{
    getRoles();
  }, [search, limit, page])
  
  const list = [
    {
      name: "Edit",
      onClick: (role) => {
        let permissions = {};
        role.allowed_permissions.map(role=>{
          permissions[role.permission?._id] = role.subPermissions;
        })
        setRole(role);
        
        setShowEditModal(true);
      },
      condition: checkPermission([{ permission: "roles", subPermission: ["UPDATE"] }],currentUser),
      className: "text-main",
    },
    {
      name: "Delete",
      onClick: async (role) => {
        await deleteDocumentById("/auth/deleteRole", role?._id);
        await getRoles()
      },
      condition: checkPermission([{ permission: "roles", subPermission: ["UPDATE"] }],currentUser),
      className: "text-danger",
    },
  ];
  return (
    <Container>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="grid grid-col-1 col-span-2 md:col-span-1 flex flex-col">
          <div className="font-semibold text-[24px]"> Roles</div>
          {/* <div className="text-grey mt-1">
          An Overview of all your test request over the year.
        </div> */}
        </div>

        <div className="flex flex-row text-secondary h-12 gap-3 text-xl">
          <div className="">
            <Button
              text="Add new role"
              disabled={!checkPermission([{ permission: "roles", subPermission: ["CREATE"] }],currentUser)}
              onClick = {()=>setShowAddModal(true)}
              className="w-40 flex flex-row border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-medium text-white bg-dark-blue gap-2"
              Icon={<Add color="inherit" />}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <InputWithIcon
          placeholder="Search for permission..."
          variant="outlined"
          setData={setSearch}
          Icon={<SearchIcon className="text-dark-grey" />}
          className="w-80 border border-gray-300 items-center px-2 rounded-md h-12 text-[16px] shadow-sm"
          inputClass="text-[16px]"
        />
      </div>
      <table className="w-full table">
          <Thead className="flex flex-row justify-between w-full">
            <tr className=" flex flex-row justify-between w-full">
            {/* <TH className="" type="checkbox"> <input type="checkbox"></input></TH> */}
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setRoles} field={"name"}> Name</TH>
            <TH> Permissions</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setRoles} field={"createdAt"}> Created On</TH>
            <TH> Actions</TH>
            </tr>
          </Thead>
          <Tbody className=  "flex flex-col justify-between w-full">
          {roles?.length ? roles.map((entry, index) => {
            
          if (true)
            return (
              <TR key={entry?._id} className="flex flex-row justify-between">
                {/* <TD className="ml-2 text-center w-10 " type= "checkbox">
                  {" "}
                  <input type="checkbox"></input>{" "}
                </TD> */}
                <TD> {entry.name} </TD>
                <TD className="">
                  {" "}
                  {entry.allowed_permissions.filter(entity=>entity.permission?.name).map((entity) => {
                    return (
                      <div>
                        <div className="font-semibold text-dark">{entity.permission?.name} {": "}</div>
                        <ul className= "break-words">
                        {entity.subPermissions?.join(", ")}
                        </ul>
                      </div>
                    );
                  })}{" "}
                </TD>
                <TD> {convertToIndianTimezoneAndGetDate(entry.createdAt)} </TD>
                <TD className="text-center">
                  {" "}
                  <Action data = {entry} list={list} />{" "}
                </TD>
              </TR>
            );
        }): 
        <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Data Found </div>}
          </Tbody>
        </table>
      <PaginationDiv
       page={page}
       setPage={setPage}
       length={totalData}
       limit={limit}
       setLimit={setLimit}
      />
    {showAddModal && <Create handleClick={async()=>{setShowAddModal(false); await getRoles()}}/>}
    {showEditModal && <Create data = {role} handleClick={()=>{setShowEditModal(false); getRoles()}}/>}

    </Container>
  );
}

export default Roles;

