import React, { useEffect, useMemo, useState } from 'react'
import CustomAddPage from '../../../custom-pages/CustomAddModal';
import { getAllData } from '../../../../apis/customApiHandler';

const AddCallbackRequirement = ({ data, handleClick, parentFormik, handleSubmit, totalSteps, tyreSize, materials }) => {
    
    const [initialValues, setInitialValues] = React.useState({
      ...data,
    });

    const [location, setLocation] = useState([])
    const [stage, setStage] = useState([])
    const [material, setMaterial] = useState([])
    const [analysisLocations, setAnalysisLocations] = useState([])
  
    const getTestLocation = async () => {
      const data = await getAllData("/master/test/getTestLocation", {});
      setLocation(data?.data)
    }
    const getAnalysisLocations = async () => {
      const data = await getAllData("/master/other/getAnalysisLocation", {});
      setAnalysisLocations(data?.data)
    }
    const getStage = async () => {
      const data = await getAllData("/master/other/getReCallStage", {});
      setStage(data?.data)
    }
    const getMaterial = async () => {
      const data = await getAllData("/master/product/getMaterial", {
        tyreSize: tyreSize,
      });
      setMaterial(data?.data)
    }
  
    useEffect(()=>{
      getMaterial();
      getTestLocation();
      getStage();
      getAnalysisLocations();

    },[])

    useMemo(()=>{
      setInitialValues({...data})
    },[data])
  
    const inputFields = [
      {
        name: "identifiedLocation",
        label: "Identified Location",
        type: "select",
        className: "w-full col-span-3",
        options: location?.map(option => { return { label: option.name, value: option.name } }),
      },
      {
        name: "analysisReason",
        label: "Analysis Reason",
        type: "text",
        className: "w-[90%] col-span-3",
      },
      {
        name: "stage",
        label: "Stage",
        type: "select",
        className: "w-full col-span-3",
        options: stage?.map(option => { return { label: option.name, value: option.name } }),
      },
      {
        name: "material",
        label: "Material",
        type: "select",
        className: "w-full col-span-3",
        options: materials,
      },
      {
        name: "numberOfTyres",
        label: "No of Tyres",
        type: "number",
        className: "w-[90%] col-span-3",
      },
      {
        name: "analysisDestination",
        label: "Analysis Destination",
        type: "select",
        options: analysisLocations?.map(option => { return { label: option.name, value: option.name } }),
        className: "w-full col-span-3",
      },
      // {
      //   name: "percentageWear",
      //   label: "Percentage Wear",
      //   type: "Number",
      //   className: "w-[89%] col-span-3",
      // },
    ]
    return (
      <CustomAddPage
        pageTitle={data ? "Update Call-Back Requirement" : "Add Call-Back Requirement"}
        action={!data ? "create" : "update"}
        updateId={data?._id}
        queryKey="permission"
        inputFields={inputFields}
        data={initialValues}
        setData={setInitialValues}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        totalSteps={totalSteps}
      />
    );
  };

export default AddCallbackRequirement