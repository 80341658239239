import React, { useEffect, useState } from "react";
import Container from "../common/Container";
import Action from "../common/Actions"
import Create from "../components/users/Create"
import "../index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputWithIcon from "../common/InputWithIcon";
import { TH, TD, TR, Thead, Tbody } from "../styled/table";
import Add from "@mui/icons-material/Add";
import Button from "../common/Button";
import PaginationDiv from "../common/PaginationDiv";
import CustomAddPage from "../components/custom-pages/CustomAddModal"
import { createDocument, deleteDocumentById, getAllData } from "../apis/customApiHandler";
import { convertToIndianTimezoneAndGetDate, handleDownClick, handleUpClick } from "../utils/helpers";
import { LinearProgress } from "@mui/material";
import checkPermission from "../utils/checkPermission";

function Users() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("")
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({})
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [totalData, setTotalData]= useState(0);
  const [totalPage, setTotalPage]= useState(0);
  const [loading, setLoading] = useState(false)
  const currentUser = JSON.parse(localStorage.getItem("user"))

  const getUser = async () => {
    setLoading(true)
    const data = await getAllData("/auth/getUsers", {page, limit, search});
    setUsers(data?.data)
    setTotalData(data?.totalData)
    setTotalPage(data?.totalPage)
    setLoading(false)
  }

  const getRoles = 
  useEffect(() => {
    getUser();
  }, [search, limit, page ])

  const list = [
    {
      name: "Edit",
      condition: checkPermission([
        {
            permission: "users",
            subPermission: ["UPDATE"]
        },
    ], currentUser),
      onClick: (user) => {
        setUser(user);
        setShowEditModal(true);
      },
      className: "text-grey",
    },
    {
      name: "Deactivate",
      condition: checkPermission([
        {
            permission: "users",
            subPermission: ["DELETE"]
        },
    ], currentUser),
      renderCondition: (data)=> data.deactivated === false,
      onClick: async (User) => {
        setUser(User);
        await createDocument("/auth/updateUser?_id=" +  User?._id, {deactivated: true});
        await getUser()
      },
      className: "text-error",
    },
  ];
  return (
    <Container>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="grid grid-col-1 col-span-2 md:col-span-1 flex flex-col">
          <div className="font-semibold text-[24px]"> Users</div>
        </div>

        <div className="flex flex-row text-secondary h-12 gap-3 text-xl">
          <div className="">
            <Button
              text="Add New User"
              disabled={!checkPermission([{ permission: "roles", subPermission: ["CREATE"] }],currentUser)}
              className="w-full flex flex-row border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-medium text-white bg-dark-blue gap-3"
              Icon={<Add color="inherit" />}
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <InputWithIcon
          placeholder="Search for user..."
          variant="outlined"
          setData={setSearch}
          Icon={<SearchIcon className="text-dark-grey" />}
          className="w-80 border border-gray-300 items-center px-2 rounded-md h-12 text-[16px] shadow-sm"
          inputClass="text-[16px]"
        />
      </div>
      <table className="w-full table">
        <Thead className="flex flex-row justify-between w-full">
          <tr className=" flex flex-row justify-between w-full">
            {/* <TH className="" type="checkbox"> <input type="checkbox"></input></TH> */}

            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"username"}> Name</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"email"}> Email</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"phone"}> Phone</TH>
            <TH> Role</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"location"}> Location</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"department"}> Department</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"designation"}> Designation</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"team"}> Team</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"createdAt"}> Created On</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setUsers} field={"deactivated"}> Status</TH>
            <TH> Actions</TH>
          </tr>
        </Thead>
        {loading ? <LinearProgress color="secondary" /> :
          <Tbody className="flex flex-col justify-between w-full">
            {users?.length ? users?.map((entry, index) => {
              if (true)
                return (
                  <TR key={entry?._id} className="">
                    {/* <TD className="ml-2 text-center justify-center " type="checkbox"> <input type="checkbox"></input> </TD> */}
                    <TD> {entry.username} </TD>
                    <TD> {entry.email ?? "---"} </TD>
                    <TD> {entry.phone ?? "---"} </TD>
                    <TD> {entry.role?.name} </TD>
                    <TD> {entry.location?.join(", ")?? "---"} </TD>
                    <TD> {entry.department ?? "---"} </TD>
                    <TD> {entry.designation ?? "---"} </TD>
                    <TD> {entry.team ?? "---"} </TD>
                    <TD> {convertToIndianTimezoneAndGetDate(entry.createdAt)} </TD>
                    <TD> {entry.deactivated ? "Deactivated" : "Active"} </TD>
                    <TD className="text-center"> <Action list={list} data={entry} /> </TD>
                  </TR>
                );
            }): <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Data Found </div>}
          </Tbody>
        }
      </table>
      <PaginationDiv
        page={page}
        setPage={setPage}
        length={totalData}
        limit={limit}
        setLimit={setLimit}
        
      />
      {showAddModal && <Create handleClick={async () => { setShowAddModal(false); await getUser() }} />}
      {showEditModal && <Create data={user} handleClick={async () => { setShowEditModal(false); await getUser() }} />}
    </Container>
  )
}
export default Users