import Routing from "./routing";
import axios from "./apis/axios";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import React, {useState, useEffect} from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import { Toaster } from "react-hot-toast";
import io from 'socket.io-client';
import { addNotification } from "./redux/user/user.action";

function App() {

  const queryClient = new QueryClient();
  const theme = createTheme({
    palette: {
      primary: { main: "#FDBC00" },
      primaryDark: { main: "#FFC827" },
      primaryLight: { main: "#FFD660" },
      primarySubtle: { main: "#FFE495" },
      secondary: { main: "#123064" },
      main: { main: "#123064" },
      mainLighter: { main: "#75A0EC" },
      mainSubtle: { main: "#CBDEFF" },
      success: { main: "#8DC153" },
      error: { main: "#FD4344" },
      dark1: { main: "#8DC153" },
      darkGrey: { main: "#6B7588" },
      grey: { main: "#8F90A6" },
      lightGrey: { main: "#C7C9D9" },
      light2: { main: "#EBEBF0" },
      light3: { main: "#F2F2F5" },
      light4: { main: "#FAFAFC" },
    },
  });

  const user = JSON.parse(localStorage.getItem("user"))


  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <BrowserRouter>
              <Routing />
            </BrowserRouter>
            <Toaster />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
