import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../common/Container";
import DateInput from "../common/DateInput";
import DropDown from "../common/DropDown";
import "../index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputWithIcon from "../common/InputWithIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TH, TD, TR, Thead, Tbody } from "../styled/table";
import Add from "@mui/icons-material/Add";
import Button from "../common/Button";
import { LinearProgress, Pagination } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import PaginationDiv from "../common/PaginationDiv";
import Collapsable from "../common/Collapsable";
import CreateTestRequest from "../components/testRequest/CreateTestRequest";
import { getAllData, updateDocumentByID } from "../apis/customApiHandler";
import ViewTestRequest from "../components/testRequest/ViewTestRequest";
import Action from "../common/Actions";
import toast from "react-hot-toast";
import { convertToIndianTimezone, convertToIndianTimezoneAndGetDate, handleDownClick, handleUpClick, titleCase } from "../utils/helpers";
import { convertValueToMeridiem } from "@mui/x-date-pickers/internals/utils/time-utils";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import checkPermission from "../utils/checkPermission"
import Timer from "../common/Timer"

function TestRequests() {
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([])
  const [createMode, setCreateMode] = useState(true);
  const [viewMode, setView] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const [date, setDate] = useState(null)
  const [expanded, setExpanded] = useState(null)
  const [allowEdit, setAllowEdit] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [list, setList] = useState([])
  
  useEffect(()=>{
    setCreateMode(searchParams.get("mode") === "EDIT" || searchParams.get("mode") === "CREATE")
    setView(["REVIEW", "VIEW", "APPROVED", "ACCEPTED", "REJECTED", "ASK_FOR_MODIFICATION"].includes(searchParams.get("mode")))
  }, [searchParams])

  const statusList = [
    {
      name: "All",
      onClick: async () => {
        setStatus("")
      },
      condition: true,
    },
    {
      name: "Sent for Approval",
      onClick: async () => {
        setStatus("SENT FOR APPROVAL")
      },
      condition: checkPermission([{ permission: "test_requests", subPermission: ["CREATE","APPROVE","EDIT","DELETE"] }],currentUser),
    },
    {
      name: "Pending Approval",
      onClick: async () => {
        setStatus("PENDING_APPROVAL")
      },
      condition: checkPermission([{ permission: "test_requests", subPermission: ["CREATE","APPROVE","EDIT","DELETE"] }],currentUser),
    },
    {
      name: "Pending Acceptance",
      onClick: async () => {
        setStatus("PENDING_ACCEPTANCE")
      },
      condition: checkPermission([{ permission: "test_requests", subPermission: ["CREATE","ACCEPT","EDIT","DELETE"] }],currentUser),
    },
    {
      name: "ASKED FOR MODIFICATION",
      onClick: async () => {
        setStatus("ASKED FOR MODIFICATION")
      },
      condition: checkPermission([{ permission: "test_requests", subPermission: ["CREATE","APPROVE","EDIT","DELETE","ACCEPT","ASK_FOR_MODIFICATION"] }],currentUser),
    },
    {
      name: "APPROVED BY PDC HEAD",
      onClick: async () => {
        setStatus("APPROVED")
      },
      condition: true,
    },
    {
      name: "ACCEPTED BY PTG HEAD",
      onClick: async () => {
        setStatus("ACCEPTED")
      },
      condition: true,
    },
    {
      name: "Rejected",
      onClick: async () => {
        setStatus("REJECTED")
      },
      condition: true,
    },
  ];
  
  const setViewMode = (requestNo, mode="VIEW")=>{
    console.log({requestNo: requestNo})
    const searchParams = createSearchParams({mode: mode, requestNo: requestNo}).toString();
    navigate("/test-requests" + "?" + searchParams);        
    setCreateMode(false);
    setView(true)
  }

  
  const getList = (requestNo, status)=>{
    let listTemp = [
      {
        name: "View",
        className: "text-main w-36",
        permissions: [{ permission: "test_requests", subPermission: ["READ"] }],
        condition: true,
        onClick: (data) => {
          setViewMode(requestNo)
        },
      },
      {
        name: "Edit",
        className: "text-mainLighter w-36",
        permissions: [{ permission: "test_requests", subPermission: ["UPDATE"] }],
        condition: status !== "ACCEPTED",
        onClick: (data) => {
          setViewMode(requestNo, "EDIT")
        },
      },
      {
        name: "Delete",
        className: "text-danger w-36",
        permissions: [{ permission: "test_requests", subPermission: ["DELETE"] }],
        condition: status !== "ACCEPTED" && status !== "APPROVED",
        onClick: async (data) => {
            await deleteTestRequest(data)
            getTestRequest();
        },
      },
      {
        name: "Approve",
        className: "text-successFull w-36",
        permissions: [
          { permission: "test_requests", subPermission: ["APPROVE"] },
        ],
        onClick: (data) => {
          setViewMode(requestNo, "APPROVED")
        },
        condition: status!== "DRAFT" && (status === "PENDING_APPROVAL" || status === "SENT FOR APPROVAL"),
      },
      {
        name: "Reject",
        className: "text-danger w-36",
        permissions: [{ permission: "test_requests", subPermission: ["REJECT"] }],
        condition: status!== "DRAFT" && (status !== "ACCEPTED" && status !== "ASKED FOR MODIFICATION"),
        onClick: (data) => {
          setViewMode(requestNo, "REJECTED")
        },
      },
      {
        name: "Accept",
        className: "text-successFull w-36",
        permissions: [{ permission: "test_requests", subPermission: ["ACCEPT"] }],
        condition: status!== "DRAFT" && (status === "APPROVED"  || status === "PENDING_ACCEPTANCE"),
        onClick: (data) => {
          setViewMode(requestNo, "ACCEPTED")
        },
      },
      {
        name: "Ask for Modification",
        className: "text-warning w-36",
        permissions: [
          {
            permission: "test_requests",
            subPermission: ["ASK_FOR_MODIFICATION"],
          },
        ],
        onClick: (data) => {
          setViewMode(requestNo, "ASK_FOR_MODIFICATION")
        },
        condition: status!== "ASKED FOR MODIFICATION" && status!== "DRAFT" && (status !== "ACCEPTED" && status !== "REJECTED"),
      },
    ];
    let lst = listTemp.filter(listItem => {
      console.log(listItem.name, checkPermission(listItem.permissions, currentUser))
      return (listItem.condition) && checkPermission(listItem.permissions, currentUser)})
    setList(lst)
  }

  const getTestRequest = useCallback(async () => {
    console.log({createMode})
    if (!createMode) {
      setLoading(true);
      console.log("FETCHING");
      try {
        const response = await getAllData("/test_request", {
          createdBy:
            checkPermission([{ permission: "test_requests", subPermission: ["CREATE"] }], currentUser) ? currentUser?._id : "",
          ...status != "" && { status: status },
          page,
          limit, 
          search: search?.toString(),
          date,
        });
        setData(response.data);
        setTotalPage(response.paginate.totalPage)
        setTotalData(response.paginate.totalData)
        
      } catch (e) {}
      setLoading(false);
    }
  }, [page, limit, search, status, date, createMode]);

  const deleteTestRequest = async (data) => {
    setLoading(true);
    try{
      const response = await updateDocumentByID("/test_request", data?._id, {deleted: true})
      setData(response.data)
    }catch(e){
    }
    setLoading(false)
  }

  useEffect(()=>{
    getTestRequest()
  }, [getTestRequest])

  const getDiv = (status, logs, id, showModal) => {
    return (
      <div
        className={`${
          status?.toUpperCase() === "REJECTED"
            ? "text-red-500 bg-red-200"
            : status?.toUpperCase() === "ACCEPTED"
            ? "text-success bg-success"
            : status?.toUpperCase() === "APPROVED"
            ? "text-green-700 bg-emerald-100"
            : status?.toUpperCase() === "SENT FOR APPROVAL"
            ? "text-slate-700 bg-gray-200"
            : status?.toUpperCase() === "PENDING_ACCEPTANCE"
            ? "text-cyan-900 bg-[#007BA730]"
            : status?.toUpperCase() === "ASKED FOR MODIFICATION"
            ? "text-warning bg-warning"
            : "text-secondary bg-secondary bg-opacity-50"
        }  px-2 py-3 text-md text-center font-bold rounded-md`}
      >
        <button
          disabled={!showModal}
          onClick={() => {
            if (expanded === null) setExpanded(id);
            else setExpanded(null);
          }}
        >
          {status === "SENT FOR APPROVAL"
            ? checkPermission(
                [
                  {
                    permission: "test_requests",
                    subPermission: ["ACCEPT", "APPROVE"],
                  },
                ],
                currentUser
              )
              ? "RECEIVED FOR APPROVAL"
              : "SENT FOR APPROVAL"
              : status === "APPROVED" ? "APPROVED BY PDC HEAD" : status === "ACCEPTED" ? "ACCEPTED BY PTG HEAD" : status.split("_").join(" ").toUpperCase()}{" "}
              </button>
        {expanded === id && (
          <div className="tooltip">
            <div
              class="relative tooltiptext  text-sm text-grey mt-1"
              style={{ height: "200px", overflowY: "auto" }}
            >
              <img
                className="absolute right-1  top-1 h-[14px] w-[14px] opacity-60 cursor-pointer"
                src="/icons/circleCross.svg"
                onClick={() => {
                  setExpanded(null);
                }}
              />
              <ul className="font-normal mt-1">
                {logs?.length ? (
                  logs?.map((log) => {
                    return (
                      <li className="flex flex-row justify-between gap-2 mb-1">
                        <div className="flex flex-col text-left">
                          <div className="text-darkGrey text-[14px]">
                            {log.actionBy?.username}
                          </div>
                          <div className="text-[12px]">
                            {convertToIndianTimezone(log.createdAt).substring(
                              0,
                              10
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col text-right">
                          <div
                            className={`text-primary font-semibold text-[14px] ${
                              log.action?.toUpperCase() === "REJECTED"
                                ? "text-red-500"
                                : log.action?.toUpperCase() === "APPROVED" ||
                                  log.action?.toUpperCase() === "ACCEPTED"
                                ? "text-successFull "
                                : log.action?.toUpperCase() ===
                                  "ASKED FOR MODIFICATION"
                                ? "text-primary"
                                : "text-secondary"
                            }`}
                          >
                            {titleCase(log.action === "APPROVED" ? "APPROVED BY PDC HEAD" : log.action === "ACCEPTED" ? "ACCEPTED BY PTG HEAD" : log.action?.split("_")?.join(" "))}
                          </div>
                          <div className="text-grey text-[12px]">
                            {convertToIndianTimezone(log.createdAt).substring(
                              12,
                              24
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className="w-full text-center justify-center text-semibold mx-auto">
                    {" "}
                    No Logs
                  </div>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };
  return createMode ? (
    <>
      <CreateTestRequest
        testRequest={selectedEntry}
        allowEdit={allowEdit}
        createMode={createMode}
        setViewMode={setViewMode}
      />
    </>
  ) : viewMode ? (
    <>
      <ViewTestRequest data={selectedEntry} />
    </>
  ) : (
    <Container>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="grid grid-col-1 col-span-2 md:col-span-1 flex flex-col">
          <div className="font-semibold text-[24px]"> Test Request</div>
          <div className="text-grey mt-1">
            An Overview of all your test request over the year.
          </div>
        </div>

        <div className="flex flex-row text-secondary h-12 gap-3 text-xl text-secondary">
          <div className=" w-22 flex flex-row justify-between border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-semibold">
            {date ? date : "Date"}
            <InputWithIcon
              type="date"
              placeholder="Date"
              variant="outlined"
              className="w-9"
              inputClass="text-[16px]"
              setData={setDate}
            />
          </div>
          <div className=" w-56 flex flex-row justify-between border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-semibold">
            {status != "" ? (
              <div className="text-sm">{titleCase(status).split("_").join(" ")}</div>
            ) : (
              "All Status"
            )}
            <InputWithIcon
              placeholder="All Status"
              variant="outlined"
              className="w-9"
              Icon={<DropDown list={statusList} />}
              inputClass="hidden"
            />
          </div>

          <div className="">
            <Button
              disabled={
                !checkPermission(
                  [{ permission: "test_requests", subPermission: ["CREATE"] }],
                  currentUser
                )
              }
              text="Add new test request"
              className="w-60 flex flex-row border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-medium text-white bg-dark-blue gap-4"
              Icon={<Add color="inherit" />}
              onClick={() => {
                const searchParams = createSearchParams({
                  mode: "CREATE",
                }).toString();
                navigate("/test-requests" + "?" + searchParams);
                setCreateMode(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <InputWithIcon
          placeholder="Search for request..."
          variant="outlined"
          setData={setSearch}
          Icon={<SearchIcon className="text-dark-grey" />}
          className="w-80 border border-gray-300 items-center px-2 rounded-md h-12 text-[16px] shadow-sm"
          inputClass="text-[16px]"
        />
      </div>
      <table className="w-full table relative">
        <Thead className="flex flex-row justify-between">
          <tr className=" flex flex-row justify-between w-full">
            {/* <TH
              className="bg-opacity-0 border-0 items-center w-10"
              type="checkbox"
            >
              {" "}
              <input type="checkbox"></input>
            </TH> */}
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setData} field={"requestNo"}> Test Request No</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setData} field={"createdAt"}> Created On</TH>
            {checkPermission(
              [{ permission: "test_requests", subPermission: ["ACCEPT", "REJECT", "ASK_FOR_MODIFICATION", "APPROVE"] }],
              currentUser
            ) && <TH> Created By </TH>}
            <TH > Status</TH>
            <TH> Actions</TH>
          </tr>
        </Thead>
        <Tbody>
          {loading === true ? (
            <LinearProgress color="secondary" />
          ) : data?.length ? (
            data.map((entry, index) => {
              if (true)
                return (
                  <TR key={entry?._id} className="">
                    {/* <TD className="ml-2 text-center w-10" type="checkbox">
                      {" "}
                      <input type="checkbox"></input>{" "}
                    </TD> */}
                  <TD> {entry.requestNo} </TD>
                    <TD>
                      {" "}
                      {convertToIndianTimezoneAndGetDate(entry.createdAt)}{" "}
                    </TD>
                    {checkPermission(
                    [{ permission: "test_requests", subPermission: ["ACCEPT", "REJECT", "ASK_FOR_MODIFICATION", "APPROVE"] }],
                    currentUser
                  ) &&  <TD> {entry.createdBy?.username} </TD>}
                          <TD>
                      {" "}
                      {getDiv(entry.status, entry.logs, entry._id, true)}{" "}
                      {entry.status === "DRAFT" ? 
                      <div className= "text-red-600"> Draft will expire in <Timer endTime = {new Date(entry.createdAt).getTime() + 10*24*60*60*1000} showDays={true}/></div>
                      : <></>}
                    </TD>
                    <TD className="text-center">
                      {" "}
                      <Action
                        onClick={() => getList(entry.requestNo, entry.status)}
                        data={entry}
                        list={list}
                      />{" "}
                    </TD>
                  </TR>
                );
            })
          ) : (
            <div className="text-center font-semibold my-5 mx-auto justify-center text-lightGrey">
              No Data Found
            </div>
          )}
        </Tbody>
      </table>
      <PaginationDiv
        table
        page={page}
        setPage={setPage}
        length={totalData}
        limit={limit}
        setLimit={setLimit}
      />
    </Container>
  );
}

export default TestRequests;