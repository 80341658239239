import React, { useEffect, useMemo, useState } from "react";
import CustomAddPage from "../../custom-pages/CustomAddModal";
import {
  createDocument,
  getAllData,
  updateDocumentByID,
} from "../../../apis/customApiHandler";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from "yup";
import Button from "../../../common/Button";
import { TD, TH, TR, Tbody, Thead } from "../../../styled/table";
import InputField from "../../formik/InputField";
import { getFinalInputs } from "../../../utils/helpers";
import { Form, FormikProvider, useFormik } from "formik";
import SelectField from "../../formik/SelectField";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Add, Delete } from "@mui/icons-material";
import Input from "../../../styled/input";

const DiscountDetails = ({
  data,
  handleSubmit,
  formik,
  handleNextClick,
  url,
  queryKey,
  requestNo,
  yupValidationSchema = {},
  updateId,
  ...props
}) => {
  const [resetDisabled, setResetDisabled] = useState([]);
  const [location, setLocation] = useState([]);
  const [configuration, setVehicleConfiguration] = useState([]);
  const [application, setApplication] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadSegment, setLoadSegment] = useState([]);
  const [testRequest, setTestRequest] = useState(null);

  // console.log({data})

  const getVehicleConfiguration = async () => {
    const data = await getAllData(
      "/master/vehicle/getVehicleConfiguration",
      {}
    );
    setVehicleConfiguration(data?.data);
  };
  const getApplication = async () => {
    const data = await getAllData("/master/other/getApplication", {});
    setApplication(data?.data);
  };
  const getTestLocation = async () => {
    const data = await getAllData("/master/test/getTestLocation", {});
    setLocation(
      data?.data?.map((option) => {
        return { label: option.name, value: option.name };
      })
    );
  };
  const getLoadSegment = async () => {
    const data = await getAllData("/master/other/getLoadSegment", {});
    setLoadSegment(data?.data);
  };

  const getOptions = async () => {
    setLoading(true);
    await getTestLocation();
    await getVehicleConfiguration();
    await getApplication();
    await getLoadSegment();
    setLoading(false);
  };

  useEffect(() => {
    console.log("getting values'");
    getOptions();
  }, []);

  const [stage, setStage] = useState([]);
  const [material, setMaterial] = useState([]);

  const queryClient = useQueryClient();

  const formSchema = Yup.object().shape({
    fitmentPlan: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const { mutateAsync } = useMutation((data) =>
    props.action === "update"
      ? updateDocumentByID(url, updateId, data)
      : createDocument(url, data)
  );
  const inputRow = [];

  const [disabled, setDisabled] = useState(props.disabled);

  useEffect(()=>{
    if(data?.length) discountFormik.setFieldValue("discountDetails", data)
  }, [data])

  // console.log("DISCOUNT DATA", data)

  const discountFormik = useFormik({
    initialValues: {
      discountDetails: [...formik.values.discountDetails?.map(item => { return {...item}}) ?? []],
    },
    onSubmit: async (values) => {
      const finalInputs = getFinalInputs(inputRow, values);
      console.log("in formik submit");
      if (url)
        await mutateAsync(finalInputs, {
          onSuccess: (data) => {
            queryClient.invalidateQueries(queryKey);
            if (data && data.message) {
              toast.success(data.message);
            }
          },
          onError: (err) => {
            toast.error(
              err.response.data.error ||
                err.response.data.message ||
                err.message ||
                "Something went wrong"
            );
          },
        });
      else {
        handleSubmit(finalInputs);
        handleNextClick();
      }
    },
    validationSchema: formSchema || undefined,
    validate: (values) => {
      let errors = {
        discountDetails: values.discountDetails?.map((entry) => {
          return {};
        }),
      };
      let hasErrors = false;
      values.discountDetails?.map((detail, index) => {
        if (detail.discount === '') {
          errors["discountDetails"][index].discount = "Required!";
          hasErrors = true;
        }
      });
      setDisabled(props.expanded === props.totalSteps || hasErrors);
      setResetDisabled(
        !discountFormik.values.length &&
          !Object.keys(discountFormik.touched)?.length
      );
      return errors;
    },
  });

  console.log(discountFormik.values);

  const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change,(on normal onWheel (return false) it works onFocus also)
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}

  // console.log("tr", discountFormik.values);
  return (
    <div className="w-full flex flex-col relative">
      <FormikProvider value={discountFormik}>
        <Form>
          {/* <div className="heading text-darkGrey mt-5 ">Tyre Details</div> */}
          {
            discountFormik.values.discountDetails?.length ? (discountFormik.values.discountDetails).map((discountDetail, ind1) => {
              return <div className="overflow-x-scroll w-full">
                <p className= "heading m-2 text-darkGrey mt-5 text-semibold">
                  {discountDetail.location}
                </p>
              <table className="w-full">
                <Thead className="flex flex-row justify-between">
                  <TH> Group</TH>
                  <TH> Group Description</TH>
                  <TH> Material</TH>
                  <TH> Discount % (FT)</TH>
                  <TH> Discount % (PT)</TH>
                  <TH> Remarks</TH>
                </Thead>
                <Tbody>
                  {discountDetail.values ? (
                    discountDetail.values?.map((entry, index) => {
                      // console.log({entry})
                        return (
                          <div>
                            <p className= "text-gary-500 font-semibold text-md m-2">{entry.location}</p>
                            <TR className="">
                            <TD> {entry.group} </TD>
                            <TD>{entry.groupDescription}</TD>
                            <TD> {entry.tyreSizedescription} </TD>
                            <TD> <Input
                                    min={0} max={100}
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    name={`discountDetails[${ind1}].values[${index}].ftDiscount`}
                                    onChange={(e)=>{
                                      if (e.target.value > 100)
                                        discountFormik.setFieldValue(
                                          e.target.name,
                                          100
                                        );
                                      else if (e.target.value < 0) {
                                        discountFormik.setFieldValue(
                                          e.target.name,
                                          0
                                        );
                                      } else
                                        discountFormik.setFieldValue(
                                          e.target.name,
                                          e.target.value
                                        );
                                    }}
                                    value={
                                      discountFormik.values.discountDetails[ind1]?.values[index]?.ftDiscount
                                    }
                                    formik={discountFormik}
                                    className="px-2 w-[10rem] justify-start items-start"
                                  /> </TD>
                            <TD> <input
                                    min={0} max={100}
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    disabled = {true}
                                    name={`discountDetails[${ind1}].values[${index}].ptDiscount`}
                                    onChange={(e)=>{if(e.target.value > 100 || e.target.value < 0) return;
                                      discountFormik.setFieldValue(e.target.name, e.target.value)}}
                                    value={
                                      discountFormik.values.discountDetails[ind1]?.values[index]?.ptDiscount
                                    }
                                    formik={discountFormik}
                                    className={`bg-[#C7C9D920] flex flex-row bg-white rounded-md py-2 px-3 text-gray-500 text-gray-500 border border-[#D6D6D6] w-full  mt-[6px] justify-start mx-0 text-grey px-2 w-[10rem] justify-start items-start`}                      
                                  /> </TD>
                            <TD> 
                            <Input
                                    type="text"
                                    name={`discountDetails[${ind1}].values[${index}].remarks`}
                                    onChange={(e)=>{discountFormik.setFieldValue(e.target.name, e.target.value)}}
                                    value={
                                      discountFormik.values.discountDetails[ind1]?.values[index]?.remarks
                                    }
                                    formik={discountFormik}
                                    className="px-2 w-[10rem] justify-start items-start"
                                  />  </TD>
                          </TR>
                          </div>
                          
                        );
                    })
                  ) : (
                    <div className="w-full text-semibold text-center text-lightGrey p-3">
                      {" "}
                      No Tyre Details Added{" "}
                    </div>
                  )}
                </Tbody>
              </table>
            </div>
            })
            : <></>
          }
          
          {props.expanded === props.totalSteps ? (
            <></>
          ) : (
            <div className="flex flex-col gap-3 mt-6 text-md w-24">
              <div className="text-red-400 text-sm">
                {discountFormik.errors?.prompt}
              </div>
              <Button
                type="button"
                disabled={disabled}
                className="bg-main text-white text-center items-center justify-center h-10 w-full"
                onClick={() => {
                  handleNextClick(props.index);
                  // console.log("IN DISCOUNT DETAILS", discountFormik.values.discountDetails)
                  formik.setFieldValue("discountDetails", discountFormik.values.discountDetails)
                }}
              >
                Next
              </Button>
            </div>
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default DiscountDetails;
