import { useDispatch } from "react-redux";
import { loginUser, setCurrentUser } from "../redux/user/user.action";
import { useFormik, Form, Field, FormikProvider } from "formik";
// import { Button, Input } from 'styled';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import InputWithIcon from "../common/InputWithIcon";
import Button from "../common/Button";
// import store from './redux/store';
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const formik = useFormik({
    initialValues: {
      email: "",
      pin: "",
      fromDashboard: true,
    },
    onSubmit: async (values) => {
      // console.log({values})
      dispatch(loginUser(values, navigate));
      setIsLoggedIn(true);
    },

    validate: (values) => {
      let errors = {};
      if (!values.email) errors.email = "Required";
      if (!values.password) errors.password = "Required";
      if( !values.email?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        errors.email = "Enter a valid Email"
      }
      setDisabled(Object.keys(errors)?.length > 0)
      return errors;
    },
  });

  // console.log("errors", formik.touched, formik.errors)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    if (user && Object.keys(user).length !== 0) {
      dispatch(setCurrentUser({ ...user, token: token }, navigate));
      navigate("/dashboard")
    }
  }, [isLoggedIn]);
  return (
    <div className="grid grid-cols-2 justify-center items-center h-screen bg-white">
      <div className="hidden md:flex col-span-1 p-2 h-full">
        <div className="justify-center items-center p-auto bg-[#FFEA01] mt-auto align-middle w-full h-screen rounded-[10px]">
          <img src="https://mir-s3-cdn-cf.behance.net/projects/404/6c9667186577615.Y3JvcCwzMzgyLDI2NDYsMTYsMA.jpg" className="mx-auto mt-[5%] h-2/3 items-center" />
          <div className="text-[48px] font-bold flex w-full justify-center mx-auto mt-[00px] text-black italic">
            Field Testing
          </div>
        </div>
      </div>
      <FormikProvider value={formik}className="col-span-2 lg:col-span-1">
        <Form className="w-1/2 relative rounded-md flex flex-col justify-center mx-auto items-center">
          {formik.touched.email ||
            (formik.touched.email && formik.errors && (
              <p className="bg-yellow-400 p-3 absolute w-full rounded-md text-center top-0 text-white">
                please enter the user id and pin
              </p>
            ))}
          <div className="p-2 flex flex-col w-full space-y-3 justify-center text-gray-800 h-full gap-y-4">
            <div>
              <h1 className="text-darkGrey text-2xl font-semibold text-left">
                Account Login
              </h1>
              <h4 className="text-md text-grey mt-1 w-2/3">
                Login with your official email address and password
              </h4>
            </div>

            <div>
            <InputWithIcon
            key="email"
            type="email"
              formik ={formik}
              label="Email"
              name= "email"
              placeholder="Enter your email address"
              variant="standard"
              setData={(e) => {
                formik.setFieldValue("email", e);
              }}
              Icon={<img src="./icons/mail.svg" />}
              className="w-80  border-b-2 border-main items-left text-[16px] w-full bg-white flex flex-col text-left"
              inputClass="text-[16px] rounded-[0px] h-12"
            />
            {formik.errors?.email ? (
        <span className="text-red-500 font-normal mt-0">*{formik.errors?.email}</span>
      ) : null}
      </div>
      
            <div className= "flex flex-col justify-between text-xs items-center p-1 w-full h-full gap-y-1">
            <InputWithIcon
              formik ={formik}
              key="password"
              type="password"  
              label="Password"
              name= "password"
              placeholder="Enter your password"
              variant="standard"
              setData={(e) => {
                formik.setFieldValue("password", e);
              }}
              Icon={<img src="./icons/lock.svg" />}
              className="w-80  border-b-2 border-main items-left text-[16px] w-full bg-white flex flex-col text-left"
              inputClass="text-[16px] rounded-[0px] h-12"
            />
            <div className="flex flex-row w-full justify-between mt-2 ">
              <div className="py-1">
              {/* <input type = "checkbox" name= "remember me" label= "remember me" className= "py-1"/> Remember Me */}
              </div>
              <a href="/login/forgot-password" className= "text-main underline font-medium">Forgot password?</a>
            </div>
             
            </div>
            {/* <Button
              type="submit"
              onClick= {formik.onSubmit}
              className="bg-main text-white w-full items-center px-[40%] text-center rounded-[8px] mt-6"
            >Login</Button> */}
            <div className="h-[40px]">
            <button type= "submit" className= {`${disabled ? "bg-lightGrey" : "bg-main"} w-full text-white h-[40px] rounded-[8px]`} disabled={disabled}> Login</button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Login;
