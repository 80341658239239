import React, { useState, useMemo } from "react";
import Button from "../../../../common/Button";
import AddPage from "../../../custom-pages/AddPage";
import ViewUsageDetail from "./ViewUsageDetail"
import { Add, MoreVert } from "@mui/icons-material";
import { TD, TH, TR, Tbody, Thead } from "../../../../styled/table";

import * as Yup from 'yup';
import SelectField from "../../../formik/SelectField";
import InputField from "../../../formik/InputField";
import InputWithIcon from "../../../../common/InputWithIcon"
import { Field } from "formik";
import Input from "../../../../styled/input";
import { getAllData } from "../../../../apis/customApiHandler";


const UsageDetails = ({formik, handleSubmit, data, ...props}) => {
  
  const [loadSegment, setLoadSegment] = useState([])
  const currentRemarksLength = props.remarksTimeline?.length ? props.remarksTimeline : 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))

  
  const rows = [
    {
      label: "Load Segment",
      name: "loadSegment",
      type: "select",
      required: true,
    },
    {
      label: "Fitment Rim(Rec)",
      name: "fitmentRim",
      type: "number",
      required: true,
    },
    {
      label: "PSI Recommendation",
      name: "psiRec",
      type: "number",
      required: true,
    },
    {
      label: "Fitment Rim(Alt)",
      name: "fitmentRimAlt",
      type: "number",
    },
    
  ]

  const [disabled, setDisabled] = useState(!(props.data && Object.keys(props.data)?.length))
  const [showModal, setShowModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useMemo(() => {
  setDisabled(!formik.values.usageDetails?.vehicleDetails?.length)
}, [formik.values.usageDetails])

// console.log(formik.values["usageDetails"])

  return (
    <div>
      <div className="heading text-darkGrey">Usage Details</div>
      <div className="grid grid-cols-2 sm:w-[100%] md:w-[90%]">
        {rows.map((row) => {
          return (
            <div className="col-span-1 my-3">
              <div className="flex flex-col">
                <div className="text-[#757575] inter text-[12px] font-semibold">
                  {row.label}
                  {row.required && (
                    <span className="text-red-500 required-dot">*</span>
                  )}
                </div>
                <div className="text-main inter font-semibold text-[16px]">
                  {data[row.name]}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="heading text-darkGrey">Vehicle Details</div>

      <div className="flex flex-col relative w-full col-span-2">
        <div className="overflow-x-scroll">
          <table className="w-full">
            <Thead className="flex flex-row justify-between">
              <TH> Vehicle Make</TH>
              <TH> Vehicle Model</TH>
              <TH> Vehicle Configuration</TH>
              <TH> Testing Axle</TH>
              <TH> Application</TH>
              <TH> Action</TH>
            </Thead>
            <Tbody>
              {data && data["vehicleDetails"]?.length ? (
                data["vehicleDetails"]?.map((entry, index) => {
                  return (
                    <TR className="">
                      <TD> {entry.make} </TD>
                      <TD> {entry.model} </TD>
                      <TD> {entry.configuration} </TD>
                      <TD> {entry.testingAxle} </TD>
                      <TD> {entry.application} </TD>
                      <TD className="text-center">
                        {" "}
                        <Button
                          onClick={() => {
                            setShowModal(!showModal);
                            setSelectedEntry(entry);
                            setSelectedIndex(index);
                          }}
                          className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                          text="View All (7 Fields)"
                        ></Button>
                      </TD>
                    </TR>
                  );
                })
            ) : (
                <div className="w-full text-semibold text-center text-lightGrey p-3">
                  {" "}
                  No Vehicle Detail Added{" "}
                </div>
              )}
            </Tbody>
          </table>
        </div>
       <div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks1"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}
        {showModal && (
          <ViewUsageDetail
            title="View Vehicle Detail"
            parentFormik={formik}
            data={selectedEntry}
            handleClick={() => {
              setShowModal(!showModal);
              setSelectedEntry(null);
              setSelectedIndex(null);
            }}
            totalSteps={props.totalSteps}
          />
        )}
      </div>
    </div>
  );
};

export default UsageDetails;
