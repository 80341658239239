import React, { useEffect, useState } from "react";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from 'yup';
import { getAllData } from "../../../apis/customApiHandler";
import InputWithIcon from "../../../common/InputWithIcon";
import Input from "../../../styled/input";

const TestRequirement = ({data, formik, handleSubmit, ...props}) => {

  const rows = [
    {
      label: "Test Request Number",
      name: "requestNo",
      type: "async-select",
      required: true,
   },
    {
      label: "Product Category",
      name: "productCategory",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Modification",
      name: "modification",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Material",
      name: "material",
      type: "text",
      required: true,
    },
    {
      label: "Test Request Date",
      name: "testRequestDate",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Test Objective",
      name: "testObjective",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Test Request By",
      name: "requestBy",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      label: "Rotation Plan",
      name: "rotationPlan",
      type: "textarea",
      required: true,
    },
    {
      label: "Fitment Plan",
      name: "fitmentPlan",
      type: "textarea",
      required: true,
    },
    {
      label: "Special Requirements",
      name: "requirements",
      type: "textarea",
      required: true,
    },
  ];

  const [disabled, setDisabled]= useState(!formik.values?.testRequirement || !Object.keys(formik.values?.testRequirement)?.length)
  const currentRemarksLength = props.remarksTimeline ? props.remarksTimeline?.length: 0;
  const currentUser = JSON.parse(localStorage.getItem("user"))

  return (
    <div className="grid grid-cols-2 mb-2">
      {rows.map((row) => {
        return (
          <div className="col-span-1 my-3">
            <div className="flex flex-col">
              <div className="text-[#757575] inter text-[12px] font-semibold">
                {row.label}{" "}
                {row.required && (
                  <span className="text-red-500 required-dot p-0 m-0">*</span>
                )}{" "}
              </div>
              <div className="text-main inter font-semibold text-[16px]">
                {data[row.name]}
              </div>
            </div>
          </div>
        );
      })}
           <div className= "flex flex-col w-full col-span-2 mt-4">
      <label className="text-[#757575] inter text-[12px] font-semibold">
        Remarks
        </label>
        {props.remarksTimeline?.length ? props.remarksTimeline?.map(item => {
          return <div className= "w-full bg-white rounded-md py-2 px-3 text-gray-600 border border-[#D6D6D6] mt-[6px] justify-start">
            {item.remark}
            </div>
        }): <div className="w-full bg-white justify-center mx-auto text-neutral-400 font-medium mt-[6px] text-[13px]"> No Remarks Added</div>}
      </div>
      {props.allowEdit && <div className="flex flex-col w-full col-span-2 mt-4">
        <label className="text-[#757575] inter text-[12px] font-semibold">
          Add a Remark
          
          <Input
            name={"remarks"}
            onChange={(e) => {
              let remarks = formik?.values.remarksTimeline?.length ? formik.values.remarksTimeline : [];
              if(remarks?.length === currentRemarksLength){
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }else{
                remarks.pop()
                remarks.push({remark: e.target.value, addedBy: currentUser._id, addedAt: new Date()})
              }
              handleSubmit(remarks)
            }}
            // value={formik?.values[props.name]?.remarks}
            formik={formik}
            className={` w-full`}
          />
        </label>
      </div>}
    </div>
  );
};

export default TestRequirement;