import React, { useEffect, useState } from "react";
import Container from "../../common/Container";
import Action from "../../common/Actions"
import "../../index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputWithIcon from "../../common/InputWithIcon";
import { TH, TD, TR, Thead, Tbody } from "../../styled/table";
import Add from "@mui/icons-material/Add";
import Button from "../../common/Button";
import PaginationDiv from "../../common/PaginationDiv";
import CustomAddPage from "../../components/custom-pages/CustomAddModal"
import { createDocument, deleteDocumentById, getAllData } from "../../apis/customApiHandler";
import { convertToIndianTimezoneAndGetDate, handleDownClick, handleUpClick } from "../../utils/helpers";
import { LinearProgress } from "@mui/material";
import checkPermission from "../../utils/checkPermission";

function TestMethod() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("")
  const [TestCategories, setTestCategories] = useState([])
  const [TestMethod, setTestMethod] = useState({})
    const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const currentUser = JSON.parse(localStorage.getItem("user"))

  const getTestMethod = async () => {
    setLoading(true)
    const data = await getAllData("/master/test/getTestMethod", {page, limit, search});
    setTestCategories(data?.data)
    setTotalData(data?.totalData)
    setLoading(false)
  }
  useEffect(() => {
    getTestMethod();
  }, [search, limit, page ])

  const list = [
    {
      name: "Edit",
     condition: checkPermission([
    {
        permission: "masters",
        subPermission: ["UPDATE"]
    },
], currentUser),
      onClick: (TestMethod) => {
        setTestMethod(TestMethod);
        setShowEditModal(true);
      },
      className: "text-grey",
    },
    {
      name: "Delete",
     condition: checkPermission([
    {
        permission: "masters",
        subPermission: ["DELETE"]
    },
], currentUser),
      onClick: async (TestMethod) => {
        setTestMethod(TestMethod);
        await createDocument("/master/Test/updateTestMethod?_id=" +  TestMethod?._id, {deleted: true});
        await getTestMethod()
      },
      className: "text-error",
    },
  ];
  return (
    <Container>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="grid grid-col-1 col-span-2 md:col-span-1 flex flex-col">
          <div className="font-semibold text-[24px]"> Test Method</div>
        </div>

        <div className="flex flex-row text-secondary h-12 gap-3 text-xl">
          <div className="">
            <Button
              text="Add New Test Method"
              disabled = {!checkPermission([
                {
                    permission: "masters",
                    subPermission: ["CREATE"]
               ,},
            ], currentUser)}
              className="w-full flex flex-row border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-medium text-white bg-dark-blue gap-3"
              Icon={<Add color="inherit" />}
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <InputWithIcon
          placeholder="Search for Test Method..."
          variant="outlined"
          setData={setSearch}
          Icon={<SearchIcon className="text-dark-grey" />}
          className="w-80 border border-gray-300 items-center px-2 rounded-md h-12 text-[16px] shadow-sm"
          inputClass="text-[16px]"
        />
      </div>
      <table className="w-full table">
        <Thead className="flex flex-row justify-between w-full">
          <tr className=" flex flex-row justify-between w-full">
            {/* <TH className="" type="checkbox"> <input type="checkbox"></input></TH> */}
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setTestCategories} field={"name"} > Name</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setTestCategories} field={"description"} > Description</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setTestCategories} field={"createdAt"} > Created On</TH>
            <TH> Actions</TH>
          </tr>
        </Thead>
        {loading ? <LinearProgress color="secondary" /> :
          <Tbody className="flex flex-col justify-between w-full">
            {TestCategories?.length ? TestCategories?.map((entry, index) => {
              if (true)
                return (
                  <TR className="">
                    {/* <TD className="ml-2 text-center justify-center " type="checkbox"> <input type="checkbox"></input> </TD> */}
                    <TD> {entry.name} </TD>
                    <TD> {entry.description} </TD>
                    <TD> {convertToIndianTimezoneAndGetDate(entry.createdAt)} </TD>
                    <TD className="text-center"> <Action list={list} data={entry} /> </TD>
                  </TR>
                );
            }): <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Data Found </div>}
          </Tbody>
        }
      </table>
      <PaginationDiv
        page={page}
        setPage={setPage}
        length={totalData}
        limit={limit}
        setLimit={setLimit}
      />
      {showAddModal && <Create handleClick={async () => { setShowAddModal(false); await getTestMethod() }} />}
      {showEditModal && <Create data={TestMethod} handleClick={async () => { setShowEditModal(false); await getTestMethod() }} />}
    </Container>
  )
}
function Create({ data, handleClick }) {
  const [initialValues, setInitialValues] = React.useState({
    name: data?.name || "",
    description: data?.description || "",
  });

  const inputFields = [
    {
      name: "name",
      label: "Test Method Name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      required: true,
    },
  ];
  return (
    <CustomAddPage
      pageTitle={data ? "Update Test Method" : "Add Test Method"}
      url={data ? "/master/test/updateTestMethod" : "/master/test/addTestMethod"}
      action={!data ? "create" : "post"}
      updateId={data?._id}
      queryKey="TestMethod"
      inputFields={inputFields}
      data={initialValues}
      setData={setInitialValues}
      handleClick={handleClick}
    />
  );
}
export default TestMethod