import React, { useEffect, useState } from "react";
import Container from "../common/Container";
import Action from "../common/Actions"
import "../index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputWithIcon from "../common/InputWithIcon";
import { TH, TD, TR, Thead, Tbody } from "../styled/table";
import Add from "@mui/icons-material/Add";
import Button from "../common/Button";
import { LinearProgress, Pagination } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import PaginationDiv from "../common/PaginationDiv";
import CustomAddPage from "../components/custom-pages/CustomAddModal"
import Create from "../components/permissions/Create";
import Modal from "../common/Modal";
import { deleteDocumentById, getAllData } from "../apis/customApiHandler";
import { convertToIndianTimezone, convertToIndianTimezoneAndGetDate, handleDownClick, handleUpClick } from "../utils/helpers";
import checkPermission from "../utils/checkPermission";

function Permissions() {
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const [limit, setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("")
  const [permissions, setPermissions] = useState([])
  const [permission, setPermission] = useState({})
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [totalData, setTotalData]= useState(0);
  const [totalPage, setTotalPage]= useState(0);
  
  const getPermissions  = async()=>{
    setLoading(true)
    const data = await getAllData("/auth/getPermissions", {page, limit, search});
    setPermissions(data?.data)
    setTotalData(data?.totalData)
    setTotalPage(data?.totalPage)
    setLoading(false)
  }
  useEffect(()=>{
    getPermissions();
  }, [search, limit, page])
  
  const list = [
    {
      name: "Edit",
     condition: checkPermission([
    {
        permission: "permissions",
        subPermission: ["UPDATE"]
    },
], currentUser),
      onClick: (permission) => {
        setPermission(permission);
        setShowEditModal(true);
      },
      condition: checkPermission([{ permission: "permissions", subPermission: ["UPDATE"] }],currentUser),
      className: "text-grey",
    },
    {
      name: "Delete",
      condition: checkPermission([
        {
            permission: "permissions",
            subPermission: ["DELETE"]
        },
    ], currentUser),
      onClick: async (permission) => {
        await deleteDocumentById("/auth/deletePermission", permission?._id);
        await getPermissions()
      },
      condition: checkPermission([{ permission: "permissions", subPermission: ["DELETE"] }],currentUser),
      className: "text-error",
    },
  ];
  return (
    <Container>
    <div className="flex flex-col md:flex-row justify-between">
      <div className="grid grid-col-1 col-span-2 md:col-span-1 flex flex-col">
        <div className="font-semibold text-[24px]"> Permissions</div>
      </div>

      <div className="flex flex-row text-secondary h-12 gap-3 text-xl">
        <div className="">
          <Button
            disabled={!checkPermission([{ permission: "permissions", subPermission: ["CREATE"] }],currentUser)}
            text="Add new permission"
            className="w-60 flex flex-row border border-1 border-black items-center px-3 rounded-md h-12 text-[16px] font-medium text-white bg-dark-blue gap-4"
            Icon={<Add color="inherit" />}
            onClick= {()=> setShowAddModal(true)}
          />
        </div>
      </div>
    </div>
    <div className="mt-5">
      <InputWithIcon
        placeholder="Search for permission..."
        variant="outlined"
        setData = {setSearch}
        Icon={<SearchIcon className="text-dark-grey" />}
        className="w-80 border border-gray-300 items-center px-2 rounded-md h-12 text-[16px] shadow-sm"
        inputClass="text-[16px]"
      />
    </div>
    <table className="w-full table">
        <Thead className="flex flex-row justify-between w-full">
          <tr className=" flex flex-row justify-between w-full">
            {/* <TH className="" type="checkbox"> <input type="checkbox"></input></TH> */}
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setPermissions} field={"name"}> Name</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setPermissions} field={"code"}> Code Name</TH>
            <TH handleUpClick= {handleUpClick} handleDownClick={handleDownClick} setData={setPermissions} field={"createdAt"}> Created On</TH>
            <TH> Actions</TH>
          </tr>
        </Thead>
        {loading ? <LinearProgress color="secondary" /> :
          <Tbody className="flex flex-col justify-between w-full">
            {permissions?.length ? permissions?.map((entry, index) => {
              if (true)
                return (
                  <TR className="">
                    {/* <TD className="ml-2 text-center justify-center " type="checkbox"> <input type="checkbox"></input> </TD> */}
                    <TD> {entry.name} </TD>
                    <TD> {entry.code} </TD>
                    <TD> {convertToIndianTimezoneAndGetDate(entry.createdAt)} </TD>
                    <TD className="text-center"> <Action list={list} data={entry} /> </TD>
                  </TR>
                );
            }): <div className= "w-full text-semibold text-center text-lightGrey p-3"> No Data Found </div>}
          </Tbody>
        }
      </table>
    <PaginationDiv
      page={page}
      setPage={setPage}
      length={totalData}
      limit={limit}
      setLimit={setLimit}
    />
    {showAddModal && <Create handleClick={async()=>{setShowAddModal(false); await getPermissions()}}/>}
    {showEditModal && <Create data = {permission} handleClick={async()=>{setShowEditModal(false); await getPermissions()}}/>}
  </Container>
  )
}

export default Permissions