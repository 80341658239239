import DropDown from "../common/DropDown";
import "../index.css";
import React from "react";

export const TH = (props) => {
  return (
    <td
      className={`text-left text-[15px] items-center align-middle font-bold text-gray-500  col-span-1 block ${props.type!== "checkbox" ? "w-48 ml-2" : "w-10 px-3 ml-2 "} ${props.className} block`}
    >
      <div className= {`flex flex-row justify-between items-center`}>
      <div className={` ${props.type!== "checkbox" ? "bg-light3 w-40 ml-2 px-3 py-3" : "w-10 text-left py-3"}  rounded-md  flex flex-row justify-between items-center align-middle `}>
        {props.children}
      </div>
      <div className="flex flex-col text-center">
        {props.handleUpClick && <img
          src="./icons/navigationUp.svg"
          alt="nu"
          className="hover:bg-lightGrey rounded-sm px-1 pt-1"
          onClick={()=>{
            props.handleUpClick(props.field, props.setData)}}
        />}
        {props.handleDownClick && <img
          src="./icons/navigationUp.svg"
          alt="nd"
          className="hover:bg-lightGrey rounded-sm px-1 pb-1"
          style={{ transform: "rotate(180deg)" }}
          onClick={()=>{
            props.handleDownClick(props.field, props.setData)}}
        />}
      </div>
      </div>
    </td>
  );
};

export const TD = (props) => {
  return (
    <td
      className={` ${props.type!== "checkbox" ? "w-48 ml-2" : "w-10"} px-3 py-3  text-left text-[15px] text-gray-500 items-center align-middle  ${props.className} break-words `}
    >
      {props.children}
    </td>
  );
};

export const Thead = (props) => {
  return (
    <thead className={` bg-light4 py-3 border rounded-t-[4px] border-b-0 w-full px-2 ${props.className}`} style={{
      width: "max-content",
      minWidth: "100%"
    }}>{props.children}</thead>
  );
};

export const Tbody = (props) => {
  return <tbody className={`border w-full block ${props.className}`}
  style={{
    width: "max-content",
    minWidth: "100%"
  }}>
{props.children}
  </tbody>
}

export const TR = (props) => {
  return <tr className={`w-full py-2 border border-gray flex flex-row justify-between px-2 w-full ${props.className}`}>{props.children}</tr>;
};
