import React, { useEffect, useState } from "react";
import AddPage from "../../custom-pages/AddPage";
import * as Yup from 'yup';
import { getAllData } from "../../../apis/customApiHandler";

const TestRequirement = ({data, formik:parentFormik,onProductCategoryChange, ...props}) => {
  // get markets
  // get test requriements
  // get product category
  // get test objective
  // get tyre size
  // get methodology 
  // get test type
  // get test category
  // get modification
  // get product type
  // get plant
  console.log("STATUS", props.status)
  const [markets, setMarkets] = useState([]);
  const [testRequirements, setTestRequirements] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [testObjective, setTestObjective] = useState([]);
  const [tyreSize, setTyreSize] = useState([]);
  const [methodology, setMethodology] = useState([]);
  const [testType, setTestType] = useState([]);
  const [testCategory, setTestCategory] = useState([]);
  const [modification, setModification] = useState([]);
  const [productType, setProductType] = useState([]);
  const [plant, setPlant] = useState([]);
  const [category, setCategory] = useState(data?.productCategory)

  const getMarket = async () => {
    const data = await getAllData("/master/other/getMarket", {});
    setMarkets(data?.data)
  }
  const getTestRequirement = async () => {
    const data = await getAllData("/master/test/getTestRequirement", {});
    setTestRequirements(data?.data)
  }
  const getProductCategory = async () => {
    const data = await getAllData("/master/product/getProductCategory", {});
    setProductCategory(data?.data)
  }
  const getTestObjective = async () => {
    const data = await getAllData("/master/test/getTestObjective", {});
    setTestObjective(data?.data)
  }
  const getTyreSize = async () => {
    const data = await getAllData("/master/product/getTyreSize", {productCategory: category});
    setTyreSize(data?.data?.map(val=> {return {name: val.description}}))
  }
  const getMethodology = async () => {
    const data = await getAllData("/master/test/getTestMethodology", {});
    setMethodology(data?.data)
  }
  const getTestType = async () => {
    const data = await getAllData("/master/test/getTestType", {});
    setTestType(data?.data)
  }
  const getTestCategory = async () => {
    const data = await getAllData("/master/test/getTestCategory", {});
    setTestCategory(data?.data)
  }
  const getModification = async () => {
    const data = await getAllData("/master/other/getModification", {});
    setModification(data?.data)
  }
  const getProductType = async () => {
    const data = await getAllData("/master/product/getProductType", {});
    setProductType(data?.data)
  }
  const getPlant = async () => {
    const data = await getAllData("/master/other/getPlant", {});
    setPlant(data?.data)
  }


  useEffect(() => {
    getMarket();
    getTestRequirement();
    getTestObjective();
    getProductCategory();
    getMethodology();
    getTestType();
    getTestCategory();
    getModification();
    getProductType();
    getPlant();
  }, [])

  useEffect(()=>{
     if(data?.productCategory && productCategory){
      const category = productCategory.find(item =>item.Ydesc == data.productCategory);
      setCategory(category?.CatCode)
     }
  }, [productCategory, data?.productCategory])

  useEffect(()=>{
    if(category) getTyreSize();
  }, [category])

  console.log("Status", props.status)




  const inputsStep1 = [
    {
      label: "Market",
      name: "market",
      type: "select",
      options: markets?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
      isMulti: false,
    },
    {
      label: "Test Requirement",
      name: "testRequirement",
      type: "text",
      required: true,
      type: "select",
      options: testRequirements?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Product Category",
      name: "productCategory",
      type: "text",
      required: true,
      type: "select",
      options: productCategory?.map(option => { return { label: option.Ydesc, value: option.Ydesc, option: option } }),
      required: true,
      disabled: props.status && props.status !== "DRAFT" ? true: false,
      onChange: (formik, value)=>{
        console.log({value})
        formik.setFieldValue("productCategory", value.option.Ydesc);
        formik.setFieldValue("tyreSize", null)
        setCategory(value.option.CatCode)
        onProductCategoryChange(value.option.Ydesc);
      }
    },
    {
      label: "Test Objective",
      name: "testObjective",
      type: "text",
      required: true,
      type: "select",
      options: testObjective?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Tyre Size",
      name: "tyreSize",
      required: true,
      // type: data?.productCategory ? "text" : "select",
      type: "select",
      options: tyreSize?.map(option => { return { label: option.name, value: option.name } }),
      disabled: props.status && props.status !== "DRAFT" ? true: false,
      // disable: false,
      onChange: (formik, value)=>{
        console.log("in tyre size", value)
        const newDetails = parentFormik.values.tyreDetails?.map((detail)=>{
          console.log(detail)
          return {
            ...detail, 
            tyre: {
              ...detail.tyre,
              material: null, 
              NSD: "",
              G1: "",
              G2: "",
              G3: "",
              G4: "",
              G5: "",
              G6: "",
            }
          }
        })
        console.log(newDetails)
        parentFormik.setFieldValue("tyreDetails", newDetails );
        formik.setFieldValue("tyreSize", value.value)
      }
    },
    {
      label: "Test Methodology",
      name: "methodology",
      type: "text",
      required: true,
      type: "select",
      options: methodology?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Test Category",
      name: "testCategory",
      type: "text",
      required: true,
      type: "select",
      options: testCategory?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Test Type",
      name: "testType",
      type: "text",
      required: true,
      type: "select",
      options: testType?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Modification",
      name: "modification",
      // type: "textarea",
      // className: "h-[150px] mb-10",
      type: "text",
      required: true,
      type: "select",
      options: modification?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
  {
      label: "Product Type",
      name: "productType",
      type: "text",
      type: "select",
      options: productType?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
    {
      label: "Plant",
      name: "plant",
      required: true,
      type: "select",
      options: plant?.map(option => { return { label: option.name, value: option.name } }),
      required: true,
    },
  ];
  const formSchema = Yup.object().shape({
    plant: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  // console.log({ formSchema })
  // console.log(data["testRequirement"])
  const [disabled, setDisabled]= useState(!data?.testRequirement || !Object.keys(data?.testRequirement)?.length)
  // console.log("testRequirements",disabled)
  return (
    <AddPage
      data={parentFormik?.values?.testRequirement}
      index={0}
      handleNextClick={props.handleNextClick}
      inputs={inputsStep1}
      parentFormik={parentFormik}
      disabled={disabled}
      setDisabled={setDisabled}
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      handleSubmit={(values) => {
        props.handleSubmit("testRequirement", { testRequirement: { ...values, completed: true } }, props.index)
      }
      }
      yupValidationSchema={formSchema}
      totalSteps={props.totalSteps}
    />
  );
};

export default TestRequirement;