import React from 'react'
import Container from '../common/Container'
function Dashboard() {
  return (
    <Container>
      {/* Dashboard */}
    </Container>
  )
}

export default Dashboard