import React, { useState } from "react";
import AddPage from "../../../custom-pages/AddPage";
import AddCallbackRequirement from "./AddCallbackRequirement"
import * as Yup from "yup";
import { TD, TH, TR, Thead } from "../../../../styled/table";
import { Add } from "@mui/icons-material";
import Button from "../../../../common/Button";

function CallbackRequirements({
  expanded,
  setExpanded,
  handleNextClick,
  formik,
  data,
  materials,
  ...props
}) {

  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(formik.values?.callBackRequirement?.length == 0);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div className="flex flex-col relative w-full col-span-2">
    <div className="overflow-x-scroll">
      <div className="overflow-x-scroll">
        <table className="w-full relative">
          <Thead className="flex flex-row justify-between">
          <tr className=" flex flex-row justify-between w-full">
            <TH> Identified Location</TH>
            <TH> Material</TH>
            <TH> No. of Tyres</TH>
            <TH> Percentage Wear</TH>
            <TH> Analysis Destination</TH>
            <TH> Stage</TH>
            <TH> Analysis Reason</TH>
            </tr>
          </Thead>
          {data?.length ? data?.map((entry, index) => {
            return (
              <TR className="">
                <TD> {entry?.identifiedLocation} </TD>
                <TD> {entry?.material} </TD>
                <TD> {entry?.numberOfTyres} </TD>
                <TD> {entry?.analysisDestination} </TD>
                <TD> {entry?.stage} </TD>
                <TD> {entry?.analysisReason} </TD>
                <TD className="text-center">
                  {" "}
                  <Button
                    onClick={() => {
                      setShowModal(!showModal);
                      setSelectedEntry(entry);
                      setSelectedIndex(index);
                    }}
                    className="bg-main text-white w-40 text-center justify-center gap-1 float-right"
                    text="View All (7 Fields)"
                  ></Button>
                </TD>
              </TR>
            );
          }): <></>}
        </table>
      </div>
    </div>
    {expanded===props.totalSteps ? <></>:<div className="w-full items-right mt-5 ">
      <Button
        className="bg-main text-white w-36 text-center justify-center gap-1 float-right"
        Icon={<Add />}
        text="Add New"
        onClick={() => {
          setShowModal(true);
        }}
      ></Button>
    </div>}
    {expanded===props.totalSteps ? <></>:<div className="flex flex-row gap-3 mt-6 text-md w-48">
      <Button
        type="button"
        disabled={false}
        className="bg-main text-white text-center items-center justify-center h-10 w-full"
        onClick={() => {
          // console.log("tyre details", formik.values.callBackRequirement)
          props.handleSubmit("callBackRequirement",  {callBackRequirement: formik.values.callBackRequirement}, props.index)
        //  handleNextClick(props.index);
        }}
      >
        Next
    </Button>
      <Button
        type="button"
        disabled={!Object.keys(formik.touched)?.length}
        className="bg-main text-white text-center items-center justify-center h-10 w-full"
        onClick={() => {
          console.log(props.initialValues)
          formik.setFieldValue("callBackRequirement", props.initialValues );
        }}
      >
        Clear
      </Button>
    </div>}
    {showModal && (
      <AddCallbackRequirement
        title="Add CALL-BACK Requirement"
        parentFormik={formik}
        data={selectedEntry}
        tyreSize= {props.tyreSize}
        materials= {materials}
        handleSubmit={(values) => {
          setDisabled(false);
          let currentVals = formik.values["callBackRequirement"]
            ? [...formik.values["callBackRequirement"]]
            : [];
          if (selectedIndex !== null) {
            currentVals[selectedIndex] = {...values};
          }
          if (selectedIndex == null)
            formik.setFieldValue("callBackRequirement", [...currentVals, {...values}]);
          else formik.setFieldValue("callBackRequirement", [...currentVals]);
          
          setSelectedEntry(null);
          setSelectedIndex(null);
  
        }}
        handleClick={() => {
          setShowModal(!showModal);
          setSelectedEntry(null);
          setSelectedIndex(null);
        }}
        totalSteps={props.totalSteps}
      />
    )}
  </div>
  );
}

export default CallbackRequirements;
