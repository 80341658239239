import { useEffect, useState } from "react";
import Input from "../styled/input";
import InputField from "../components/formik/InputField";
const InputWithIcon = ({
  label,
  name,
  placeholder,
  type,
  value,
  setData,
  errors,
  disabled,
  className,
  inputClass,
  minDate,
  Icon,
  onFocus,
  ...props
}) => {
  // const [errors, setErrors] = useState({});
  useEffect(() => {}, []);
  return (
    <div className={`text-start  ${className} flex flex-row items-left gap-x-2 h-full align-middle `}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm text-grey "
        >
          {label}
        </label>
      )}
      <div className= "flex flex-row align-middle w-full items-center">
      {Icon}
      <input
        className={`rounded-md w-[100%] p-2 input ${inputClass}`}
        id={name}
        name={name}
        placeholder={placeholder}
        aria-label={name}
        aria-describedby="basic-addon1"
        type={type}
        required
        // onFocus = {onFocus}
        // value={value}
        onChange={(e) => {
          setData(e.target.value);
        }}
        min = {minDate}
        {...props}
      />
            </div>
      <p className="text-red-900 text-sm">{errors}</p>
    </div>
  );
};

export default InputWithIcon;