import React from "react";
import { fetchFile } from "../../utils/helpers";

function ViewFileArea(props) {
  return (
    <div className={`${props.className}`}>
      <div class={`flex items-center justify-center  w-full p-2`}>
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer hover:bg-light3"
        >
          
          <div className="flex flex-row w-full px-5 justify-start gap-4 mt-2 flex-wrap">
            {props.files.map((key, index) => {
              // console.log("val", props.name, props.files)
              const id= `file_${index}`
              // console.log(id)
              // console.log("type", typeof props.files[index])
              if(  typeof props.files[index]!== "object"){
              const isImage = props.files[index]?.split(".")?.length && typeof props.files[index]!== "object" &&  ["jpeg","png", "svg", "jpg"].includes(props.files[index]?.split(".")[props.files[index]?.split(".").length - 1])
              return (
                <div className= "h-[130px] w-[130px] relative  bg-light3">
                  <div className= "relative h-[130px] w-[130px]">
                    <img
                      src={isImage ? props.files[index] : "./icons/file.svg"}
                      className="bg-light3 justify-center items-center h-[130px] w-[130px]  object-cover px-auto rounded-md shadow-xs"
                      // onClick={(e)=>{setEntry(props.files.index)}}
                    ></img>
                    <div className="absolute mx-4 mt-[-80px] font-medium text-center justify-center max-w-[100px] break-words">
                      {props.files && props.files[index]?.split("/")?.slice(-1)[0]}
                  </div>
                  <div className= "absolute mt-[-120px] flex text-center rounded-full bg-gray-200 border border-2 w-10 h-10 justify-center mx-[40px]">
                    <button
                      onClick={(e)=> {
                        e.preventDefault();
                        fetchFile(props.files[index])
                      }}
                    >
                  <img src= "/icons/download_arrow.svg" alt="Check free icon" title="Check free icon" className= "text-center justify-center mx-auto"></img>
                  </button></div>
                  </div>
                  
                </div>
              );}
            })}
          </div>

        </label>
      </div>
    </div>
  );
}

export default ViewFileArea;
