import React from 'react'

function Button(props) {
  return (
    <button className= {`border border-1  ${props.disabled ? "bg-lightGrey text-white text-center items-center justify-center h-full w-full text-[16px] font-medium": props.className} flex flex-row rounded-md p-2 hover:shadow-lg`} width= {props.width} onClick= {props.onClick} type= {props.type} disabled= {props.disabled} >
        {props.Icon}
        {props.text}
        {props.children}
    </button>
  )
}

export default Button