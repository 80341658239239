import { useDispatch } from "react-redux";
import { loginUser, setCurrentUser } from "../redux/user/user.action";
import { useFormik, Form, Field, FormikProvider } from "formik";
// import { Button, Input } from 'styled';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import InputWithIcon from "../common/InputWithIcon";
import Button from "../common/Button";
import { createDocument } from "../apis/customApiHandler";
import OTPForm from "./OTP";
import UpdatePassword from "./UpdatePassword";
// import store from './redux/store';
const SendEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState(null)
  const formik = useFormik({
    initialValues: {
      email: "",
      fromDashboard: true,
    },
    onSubmit: async (values) => {
      // console.log({values})
      setDisabled(true)
      try{
       const response =  await createDocument("/auth/forgotPassword", {email: values.email})
       setStep(2);
       console.log(response)
       setResponse({...response, email: values.email});
        
      }catch(err){
        console.log(err)
      }finally{
        setDisabled(false)
      }
    },

    validate: (values) => {
      let errors = {};
      if (!values.email) errors.email = "Required";
      if( !values.email?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        errors.email = "Enter a valid Email"
      }
      setDisabled(Object.keys(errors)?.length > 0)
      return errors;
    },
  });

  // console.log("errors", formik.touched, formik.errors)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    if (user && Object.keys(user).length !== 0) {
      dispatch(setCurrentUser({ ...user, token: token }, navigate));
      navigate("/dashboard")
    }
  }, [isLoggedIn]);
  return (
    <div className="grid grid-cols-2 justify-center items-center h-screen bg-white">
      <div className="hidden md:flex col-span-1 p-2 h-full">
        <div className="justify-center items-center p-auto bg-[#FFEA01] mt-auto align-middle w-full h-screen rounded-[10px]">
          <img src="https://mir-s3-cdn-cf.behance.net/projects/404/6c9667186577615.Y3JvcCwzMzgyLDI2NDYsMTYsMA.jpg" className="mx-auto mt-[5%] h-2/3 items-center" />
          <div className="text-[48px] font-bold flex w-full justify-center mx-auto mt-[00px] text-black italic">
            Field Testing
          </div>
        </div>
      </div>
      {step == 1 ? <FormikProvider value={formik} className="col-span-2 lg:col-span-1">
        <Form className="w-1/2 relative rounded-md flex flex-col justify-center mx-auto items-center">
          <div className="p-2 flex flex-col w-full space-y-3 justify-center text-gray-800 h-full gap-y-4">
            <div>
              <h1 className="text-darkGrey text-2xl font-semibold text-left">
                Forgot Password
              </h1>
              <h4 className="text-md text-grey mt-1 w-2/3">
                Enter your official email to recveive an OTP for password reset 
              </h4>
            </div>
            <div>
            <InputWithIcon
            key="email"
            type="email"
              formik ={formik}
              label="Email"
              name= "email"
              placeholder="Enter your email address"
              variant="standard"
              setData={(e) => {
                formik.setFieldValue("email", e);
              }}
              // Icon={<img src="./icons/mail.svg" />}
              className="w-80  border-b-2 border-main items-left text-[16px] w-full bg-white flex flex-col text-left"
              inputClass="text-[16px] rounded-[0px] h-12"
            />
            {formik.errors?.email ? (
                <span className="text-red-500 font-normal mt-0">*{formik.errors?.email}</span>
            ) : null}
        </div>
            <div className="h-[40px]">
            <button type= "submit" className= {`${disabled ? "bg-lightGrey" : "bg-main"} w-full text-white h-[40px] rounded-[8px]`} disabled={disabled}> Send OTP</button>
            </div>
          </div>
        </Form>
      </FormikProvider>:
      step == 2 ?
      <OTPForm response = {response} email={response?.email} setStep = {setStep}/>
      : step === 3 ?
      <UpdatePassword response = {response} email={response?.email} setStep = {setStep}/>
      : step === 4 ? 
      <div className= "flex flex-row justify-center mx-auto text-md font-semibold"> Password updated successfully, Go back to {" "} <a href= "/" class= "text-blue underline px-2">Login</a></div>
      : <> Invalid Step</>
}
    </div>
  );
};

export default SendEmail;
