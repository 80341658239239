import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../common/Button";
import InputField from "../formik/InputField";
import { createDocument, updateDocumentByID } from "../../apis/customApiHandler";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import SelectField from "../formik/SelectField";
import Modal from "../../common/Modal";

const CustomViewPage = ({  
  data,
  setData,
  url,
  queryKey,
  handleClick,
  yupValidationSchema,
  onCancel,
  handleSubmit,
  rows,
  pageTitle,
  action,
  updateId,
}) => {
  const { mutateAsync } = useMutation((data) =>
    url?
    action === "update"
      ? updateDocumentByID(url, updateId, data)
      : action === "post"
      ? createDocument(url + "?_id=" + updateId, data)
      : createDocument(url, data):
    async()=>{
      console.log("Creating")
    }
  );
  const queryClient = useQueryClient();
  const [disabled, setDisabled] = useState(true);
  const [resetDisabled, setResetDisabled] = useState(true)

  return (
    <Modal
      handleClick={handleClick}
      title={pageTitle}
      className="w-full min-h-fit p-3 px-4"
    >
          <div className="text-gray-700 font-medium text-base grid  lg:gap-x-4  gap-y-5 grid-flow-row grid-cols-4 lg:grid-cols-6 w-full pb-5">
          {rows?.map((field) => {
            switch (field.type) {
              case "checkbox":
                return (
                  <div className= {`${field.halfCol ? "col-span-1" : "col-span-2"} w-full`}>
                    <div className= "flex flex-col">
                    <div className= "text-[#757575] inter text-[12px] font-semibold">{field.label} 
                    {field.required && (
            <span className="text-red-500 required-dot">*</span>
          )}</div>
                    <div type="checkbox" className= "text-main inter font-semibold text-[14px] w-full justify-start" >
                      <input type="checkbox" className= "text-main inter font-semibold text-[12px] w-5 mr-1" disabled={true} checked={data[field.name]}></input>
                      {data[field.name] ? "Checked" : "Not Checked"}
                      </div>
                    </div>
                    </div>
                )
            default:
                return (
                    <div className= {`${field.halfCol ? "col-span-1" : "col-span-2"}`}>
                    <div className= "flex flex-col">
                    <div className= "text-[#757575] inter text-[12px] font-semibold">{field.label} {field.required && (
            <span className="text-red-500 required-dot">*</span>
          )}</div>
                    <div className= "text-main inter font-semibold text-[16px]">{data[field.name]}</div>
                    </div>
                    </div>
                );
            }
          })}
          </div>
          {rows?.length % 2 === 0 ? <div /> : null}
    </Modal>
  );
};

export default CustomViewPage;
