import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Input from "../styled/input";
import InputField from "../components/formik/InputField";
import Button from "./Button";
import SelectField from "../components/formik/SelectField";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  fontFamily: "inter",
  marginBottom: "10px",
  "&:before": {
    display: "none",
  },
  "&:after": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, 1)" : "white",
  height: "56px",
  boxShadow: "0px 4px 20px 0px #0000000D",

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(90deg)",
    border: 0,
  },

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
    border: 0,
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function Collapsable({expanded, setExpanded, handleNextClick, handleChangeWindow, statuses, setStatuses, totalSteps,...props}) {

  React.useMemo(()=>{}, [statuses])
  return (
    <div>
      <Accordion
        expanded={expanded === props.index || expanded==totalSteps}
        onChange={handleChangeWindow(props.index)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="flex flex-row gap-2 items-center inter">
            <span
              className={`h-9 w-9 items-center justify-center text-center align-middle py-2 rounded-full shadow-sm text-white font-semibold  shadow-sm text-sm ${
                statuses[props.index] === 0 ? "bg-main" : statuses[props.index] === 1 ? "bg-primary" : statuses[props.index] === 2 ? "bg-successFull": ""
              }`}
            >
              {" "}
              {props.index + 1}{" "}
            </span>
            <Typography sx={{ fontWeight: "bold", color: "#555555" }}>
              {props.section.title}{" "}
            </Typography>
          </div>
        </AccordionSummary>
        {(expanded === props.index || expanded==totalSteps) ?<AccordionDetails>
          <Typography>
            <div className= "mt-1 mb-4">
              {props.section.content}
            </div>
          </Typography>
        </AccordionDetails>: <AccordionDetails></AccordionDetails>}
      </Accordion>
    </div>
  );
}
